import PopularCourses from "../components/Home/PopularCourses"
import "./first.css"
import { useSelector } from "react-redux";

import { useState } from "react";

function Buyanother({ handleNavigationClick }) {
    const languageState = useSelector((state) => state.language);
    const [large, setLarge] = useState(3)

    const [medium, setMedium] = useState(2)
    return (
        <>
            <div className=" maincontainerforstudent">
                <PopularCourses id="targetSection" language={languageState.language.value} handleNavigationClick={handleNavigationClick} large={large} medium={medium} showCancelButton={false} />
            </div>

        </>
    )
}
export default Buyanother