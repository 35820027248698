// Homepage.js
import React from "react";
import StudentNav from "./StudentNav";


function Homepage() {

  
  return (
    <div>
<StudentNav/>      
    </div>
  );
}

export default Homepage;
