import { useEffect, useState } from "react";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import review4 from "./Reviews/Review4.svg"
import review5 from "./Reviews/Review5.svg"
import review6 from "./Reviews/Review6.svg"
import review7 from "./Reviews/Review7.svg"
import review8 from "./Reviews/Review8.svg"
import review1 from "./Images/image 12.png"
import review2 from "./Images/2nd review.svg"
import review3 from "./Images/Thirdreview.svg"
import review0 from "./Images/FourthReview.svg"
import SliderPage from "../components/Home/Sliderpage";
import google from "./Images/Google__G__logo 6.png"
function ReviewSliderforhome (){
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    // Clean up the event listener
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 6000,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        }
      },
      {
        breakpoint: 988,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      }
    ],
    appendDots: (dots) => (
      <div>
        <ul style={{ margin: "0px" }}> {dots} </ul>
      </div>
    ),
    customPaging: (i) => (
      <button className="custom-dot"></button>
    )
  };
    return(
        <>
        {
            windowWidth <= 786 ?(
            
                <div className="coverofreviewslider newcouselcover">
             <SliderPage/>
                </div>
    ):(
      <div className="coveroflargerscreen">
          <Slider {...settings}>
          <div className='Slider_cover '>

         
        <div className="cover_each_carousel">
                <div className="name_section">
                     <div className="nameofperson">
                     Alfredo Garcia  <div className="startsreview">
            <svg xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 11 10" fill="none">
              <g clip-path="url(#clip0_3693_14062)">
                <path d="M5.1942 8.69694L7.28498 9.81603L7.33347 9.83737C7.6884 9.97119 8.02393 9.94404 8.278 9.71712C8.51656 9.50377 8.60384 9.19733 8.55341 8.84628L8.2043 6.43355L9.78305 4.77333C10.0585 4.49599 10.1651 4.15463 10.072 3.79777C9.96925 3.40211 9.63565 3.17325 9.15272 3.09761L7.05418 2.78147L5.95642 0.504498C5.78575 0.182542 5.5181 -0.00752932 5.1845 0.000228676C4.85673 0.00992617 4.59683 0.209695 4.39319 0.568502L3.33034 2.79505L0.97773 3.12282C0.603407 3.18682 0.327998 3.38271 0.217447 3.70661C0.106895 4.03051 0.211629 4.35828 0.494795 4.69188L2.20349 6.43548L1.81947 8.84628C1.75935 9.26327 1.83111 9.59299 2.1104 9.79082C2.36059 9.96731 2.67479 9.96731 3.03748 9.84318L3.0976 9.81797L5.1942 8.69694Z" fill="#FBB723" />
              </g>
              <defs>
                <clipPath id="clip0_3693_14062">
                  <rect width="9.93023" height="9.93023" fill="white" transform="matrix(-1 0 0 1 10.105 0)" />
                </clipPath>
              </defs>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 11 10" fill="none">
              <g clip-path="url(#clip0_3693_14062)">
                <path d="M5.1942 8.69694L7.28498 9.81603L7.33347 9.83737C7.6884 9.97119 8.02393 9.94404 8.278 9.71712C8.51656 9.50377 8.60384 9.19733 8.55341 8.84628L8.2043 6.43355L9.78305 4.77333C10.0585 4.49599 10.1651 4.15463 10.072 3.79777C9.96925 3.40211 9.63565 3.17325 9.15272 3.09761L7.05418 2.78147L5.95642 0.504498C5.78575 0.182542 5.5181 -0.00752932 5.1845 0.000228676C4.85673 0.00992617 4.59683 0.209695 4.39319 0.568502L3.33034 2.79505L0.97773 3.12282C0.603407 3.18682 0.327998 3.38271 0.217447 3.70661C0.106895 4.03051 0.211629 4.35828 0.494795 4.69188L2.20349 6.43548L1.81947 8.84628C1.75935 9.26327 1.83111 9.59299 2.1104 9.79082C2.36059 9.96731 2.67479 9.96731 3.03748 9.84318L3.0976 9.81797L5.1942 8.69694Z" fill="#FBB723" />
              </g>
              <defs>
                <clipPath id="clip0_3693_14062">
                  <rect width="9.93023" height="9.93023" fill="white" transform="matrix(-1 0 0 1 10.105 0)" />
                </clipPath>
              </defs>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 11 10" fill="none">
              <g clip-path="url(#clip0_3693_14062)">
                <path d="M5.1942 8.69694L7.28498 9.81603L7.33347 9.83737C7.6884 9.97119 8.02393 9.94404 8.278 9.71712C8.51656 9.50377 8.60384 9.19733 8.55341 8.84628L8.2043 6.43355L9.78305 4.77333C10.0585 4.49599 10.1651 4.15463 10.072 3.79777C9.96925 3.40211 9.63565 3.17325 9.15272 3.09761L7.05418 2.78147L5.95642 0.504498C5.78575 0.182542 5.5181 -0.00752932 5.1845 0.000228676C4.85673 0.00992617 4.59683 0.209695 4.39319 0.568502L3.33034 2.79505L0.97773 3.12282C0.603407 3.18682 0.327998 3.38271 0.217447 3.70661C0.106895 4.03051 0.211629 4.35828 0.494795 4.69188L2.20349 6.43548L1.81947 8.84628C1.75935 9.26327 1.83111 9.59299 2.1104 9.79082C2.36059 9.96731 2.67479 9.96731 3.03748 9.84318L3.0976 9.81797L5.1942 8.69694Z" fill="#FBB723" />
              </g>
              <defs>
                <clipPath id="clip0_3693_14062">
                  <rect width="9.93023" height="9.93023" fill="white" transform="matrix(-1 0 0 1 10.105 0)" />
                </clipPath>
              </defs>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 11 10" fill="none">
              <g clip-path="url(#clip0_3693_14062)">
                <path d="M5.1942 8.69694L7.28498 9.81603L7.33347 9.83737C7.6884 9.97119 8.02393 9.94404 8.278 9.71712C8.51656 9.50377 8.60384 9.19733 8.55341 8.84628L8.2043 6.43355L9.78305 4.77333C10.0585 4.49599 10.1651 4.15463 10.072 3.79777C9.96925 3.40211 9.63565 3.17325 9.15272 3.09761L7.05418 2.78147L5.95642 0.504498C5.78575 0.182542 5.5181 -0.00752932 5.1845 0.000228676C4.85673 0.00992617 4.59683 0.209695 4.39319 0.568502L3.33034 2.79505L0.97773 3.12282C0.603407 3.18682 0.327998 3.38271 0.217447 3.70661C0.106895 4.03051 0.211629 4.35828 0.494795 4.69188L2.20349 6.43548L1.81947 8.84628C1.75935 9.26327 1.83111 9.59299 2.1104 9.79082C2.36059 9.96731 2.67479 9.96731 3.03748 9.84318L3.0976 9.81797L5.1942 8.69694Z" fill="#FBB723" />
              </g>
              <defs>
                <clipPath id="clip0_3693_14062">
                  <rect width="9.93023" height="9.93023" fill="white" transform="matrix(-1 0 0 1 10.105 0)" />
                </clipPath>
              </defs>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 11 10" fill="none">
              <g clip-path="url(#clip0_3693_14062)">
                <path d="M5.1942 8.69694L7.28498 9.81603L7.33347 9.83737C7.6884 9.97119 8.02393 9.94404 8.278 9.71712C8.51656 9.50377 8.60384 9.19733 8.55341 8.84628L8.2043 6.43355L9.78305 4.77333C10.0585 4.49599 10.1651 4.15463 10.072 3.79777C9.96925 3.40211 9.63565 3.17325 9.15272 3.09761L7.05418 2.78147L5.95642 0.504498C5.78575 0.182542 5.5181 -0.00752932 5.1845 0.000228676C4.85673 0.00992617 4.59683 0.209695 4.39319 0.568502L3.33034 2.79505L0.97773 3.12282C0.603407 3.18682 0.327998 3.38271 0.217447 3.70661C0.106895 4.03051 0.211629 4.35828 0.494795 4.69188L2.20349 6.43548L1.81947 8.84628C1.75935 9.26327 1.83111 9.59299 2.1104 9.79082C2.36059 9.96731 2.67479 9.96731 3.03748 9.84318L3.0976 9.81797L5.1942 8.69694Z" fill="#FBB723" />
              </g>
              <defs>
                <clipPath id="clip0_3693_14062">
                  <rect width="9.93023" height="9.93023" fill="white" transform="matrix(-1 0 0 1 10.105 0)" />
                </clipPath>
              </defs>
            </svg>
          </div>
                </div>
              
          <div className="date_of_review ">
          03/13/2024
          </div>
                </div>
            <div className="paraofreview" style={{marginTop:"8px"}}>"Logré tomar todas mis clases al 100% en español con UnitedELDT. En la escuela donde estaba, solo ofrecían las clases ”
</div>
             <img  src={review1} alt="reviewer"/>
             <div className="posted">
              Posted on<img src={google} alt="google"></img>
             </div>
            </div> </div>
            <div className='Slider_cover '>
              <div className="cover_each_carousel">
                <div className="name_section">
                     <div className="nameofperson">
                     Mario Salustino<div className="startsreview">
            <svg xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 11 10" fill="none">
              <g clip-path="url(#clip0_3693_14062)">
                <path d="M5.1942 8.69694L7.28498 9.81603L7.33347 9.83737C7.6884 9.97119 8.02393 9.94404 8.278 9.71712C8.51656 9.50377 8.60384 9.19733 8.55341 8.84628L8.2043 6.43355L9.78305 4.77333C10.0585 4.49599 10.1651 4.15463 10.072 3.79777C9.96925 3.40211 9.63565 3.17325 9.15272 3.09761L7.05418 2.78147L5.95642 0.504498C5.78575 0.182542 5.5181 -0.00752932 5.1845 0.000228676C4.85673 0.00992617 4.59683 0.209695 4.39319 0.568502L3.33034 2.79505L0.97773 3.12282C0.603407 3.18682 0.327998 3.38271 0.217447 3.70661C0.106895 4.03051 0.211629 4.35828 0.494795 4.69188L2.20349 6.43548L1.81947 8.84628C1.75935 9.26327 1.83111 9.59299 2.1104 9.79082C2.36059 9.96731 2.67479 9.96731 3.03748 9.84318L3.0976 9.81797L5.1942 8.69694Z" fill="#FBB723" />
              </g>
              <defs>
                <clipPath id="clip0_3693_14062">
                  <rect width="9.93023" height="9.93023" fill="white" transform="matrix(-1 0 0 1 10.105 0)" />
                </clipPath>
              </defs>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 11 10" fill="none">
              <g clip-path="url(#clip0_3693_14062)">
                <path d="M5.1942 8.69694L7.28498 9.81603L7.33347 9.83737C7.6884 9.97119 8.02393 9.94404 8.278 9.71712C8.51656 9.50377 8.60384 9.19733 8.55341 8.84628L8.2043 6.43355L9.78305 4.77333C10.0585 4.49599 10.1651 4.15463 10.072 3.79777C9.96925 3.40211 9.63565 3.17325 9.15272 3.09761L7.05418 2.78147L5.95642 0.504498C5.78575 0.182542 5.5181 -0.00752932 5.1845 0.000228676C4.85673 0.00992617 4.59683 0.209695 4.39319 0.568502L3.33034 2.79505L0.97773 3.12282C0.603407 3.18682 0.327998 3.38271 0.217447 3.70661C0.106895 4.03051 0.211629 4.35828 0.494795 4.69188L2.20349 6.43548L1.81947 8.84628C1.75935 9.26327 1.83111 9.59299 2.1104 9.79082C2.36059 9.96731 2.67479 9.96731 3.03748 9.84318L3.0976 9.81797L5.1942 8.69694Z" fill="#FBB723" />
              </g>
              <defs>
                <clipPath id="clip0_3693_14062">
                  <rect width="9.93023" height="9.93023" fill="white" transform="matrix(-1 0 0 1 10.105 0)" />
                </clipPath>
              </defs>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 11 10" fill="none">
              <g clip-path="url(#clip0_3693_14062)">
                <path d="M5.1942 8.69694L7.28498 9.81603L7.33347 9.83737C7.6884 9.97119 8.02393 9.94404 8.278 9.71712C8.51656 9.50377 8.60384 9.19733 8.55341 8.84628L8.2043 6.43355L9.78305 4.77333C10.0585 4.49599 10.1651 4.15463 10.072 3.79777C9.96925 3.40211 9.63565 3.17325 9.15272 3.09761L7.05418 2.78147L5.95642 0.504498C5.78575 0.182542 5.5181 -0.00752932 5.1845 0.000228676C4.85673 0.00992617 4.59683 0.209695 4.39319 0.568502L3.33034 2.79505L0.97773 3.12282C0.603407 3.18682 0.327998 3.38271 0.217447 3.70661C0.106895 4.03051 0.211629 4.35828 0.494795 4.69188L2.20349 6.43548L1.81947 8.84628C1.75935 9.26327 1.83111 9.59299 2.1104 9.79082C2.36059 9.96731 2.67479 9.96731 3.03748 9.84318L3.0976 9.81797L5.1942 8.69694Z" fill="#FBB723" />
              </g>
              <defs>
                <clipPath id="clip0_3693_14062">
                  <rect width="9.93023" height="9.93023" fill="white" transform="matrix(-1 0 0 1 10.105 0)" />
                </clipPath>
              </defs>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 11 10" fill="none">
              <g clip-path="url(#clip0_3693_14062)">
                <path d="M5.1942 8.69694L7.28498 9.81603L7.33347 9.83737C7.6884 9.97119 8.02393 9.94404 8.278 9.71712C8.51656 9.50377 8.60384 9.19733 8.55341 8.84628L8.2043 6.43355L9.78305 4.77333C10.0585 4.49599 10.1651 4.15463 10.072 3.79777C9.96925 3.40211 9.63565 3.17325 9.15272 3.09761L7.05418 2.78147L5.95642 0.504498C5.78575 0.182542 5.5181 -0.00752932 5.1845 0.000228676C4.85673 0.00992617 4.59683 0.209695 4.39319 0.568502L3.33034 2.79505L0.97773 3.12282C0.603407 3.18682 0.327998 3.38271 0.217447 3.70661C0.106895 4.03051 0.211629 4.35828 0.494795 4.69188L2.20349 6.43548L1.81947 8.84628C1.75935 9.26327 1.83111 9.59299 2.1104 9.79082C2.36059 9.96731 2.67479 9.96731 3.03748 9.84318L3.0976 9.81797L5.1942 8.69694Z" fill="#FBB723" />
              </g>
              <defs>
                <clipPath id="clip0_3693_14062">
                  <rect width="9.93023" height="9.93023" fill="white" transform="matrix(-1 0 0 1 10.105 0)" />
                </clipPath>
              </defs>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 11 10" fill="none">
              <g clip-path="url(#clip0_3693_14062)">
                <path d="M5.1942 8.69694L7.28498 9.81603L7.33347 9.83737C7.6884 9.97119 8.02393 9.94404 8.278 9.71712C8.51656 9.50377 8.60384 9.19733 8.55341 8.84628L8.2043 6.43355L9.78305 4.77333C10.0585 4.49599 10.1651 4.15463 10.072 3.79777C9.96925 3.40211 9.63565 3.17325 9.15272 3.09761L7.05418 2.78147L5.95642 0.504498C5.78575 0.182542 5.5181 -0.00752932 5.1845 0.000228676C4.85673 0.00992617 4.59683 0.209695 4.39319 0.568502L3.33034 2.79505L0.97773 3.12282C0.603407 3.18682 0.327998 3.38271 0.217447 3.70661C0.106895 4.03051 0.211629 4.35828 0.494795 4.69188L2.20349 6.43548L1.81947 8.84628C1.75935 9.26327 1.83111 9.59299 2.1104 9.79082C2.36059 9.96731 2.67479 9.96731 3.03748 9.84318L3.0976 9.81797L5.1942 8.69694Z" fill="#FBB723" />
              </g>
              <defs>
                <clipPath id="clip0_3693_14062">
                  <rect width="9.93023" height="9.93023" fill="white" transform="matrix(-1 0 0 1 10.105 0)" />
                </clipPath>
              </defs>
            </svg>
          </div>
                </div>
                
          <div className="date_of_review ">
          03/13/2024
          </div>
                </div>
            <div className="paraofreview" style={{marginTop:"8px"}}>"Único site com ELDT 100% em português. SUPER RECOMENDO para brasileiros que não falam inglês. Obrigado, United ELDT!"</div>
             <img  src={review2} alt="reviewer"/>
             <div className="posted">
              Posted on<img src={google} alt="google"></img>
             </div>
            </div>
            </div>
            
            <div className='Slider_cover '>
            <div className="cover_each_carousel">
                <div className="name_section">
                     <div className="nameofperson">
                     Adane Zegeye<div className="startsreview">
            <svg xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 11 10" fill="none">
              <g clip-path="url(#clip0_3693_14062)">
                <path d="M5.1942 8.69694L7.28498 9.81603L7.33347 9.83737C7.6884 9.97119 8.02393 9.94404 8.278 9.71712C8.51656 9.50377 8.60384 9.19733 8.55341 8.84628L8.2043 6.43355L9.78305 4.77333C10.0585 4.49599 10.1651 4.15463 10.072 3.79777C9.96925 3.40211 9.63565 3.17325 9.15272 3.09761L7.05418 2.78147L5.95642 0.504498C5.78575 0.182542 5.5181 -0.00752932 5.1845 0.000228676C4.85673 0.00992617 4.59683 0.209695 4.39319 0.568502L3.33034 2.79505L0.97773 3.12282C0.603407 3.18682 0.327998 3.38271 0.217447 3.70661C0.106895 4.03051 0.211629 4.35828 0.494795 4.69188L2.20349 6.43548L1.81947 8.84628C1.75935 9.26327 1.83111 9.59299 2.1104 9.79082C2.36059 9.96731 2.67479 9.96731 3.03748 9.84318L3.0976 9.81797L5.1942 8.69694Z" fill="#FBB723" />
              </g>
              <defs>
                <clipPath id="clip0_3693_14062">
                  <rect width="9.93023" height="9.93023" fill="white" transform="matrix(-1 0 0 1 10.105 0)" />
                </clipPath>
              </defs>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 11 10" fill="none">
              <g clip-path="url(#clip0_3693_14062)">
                <path d="M5.1942 8.69694L7.28498 9.81603L7.33347 9.83737C7.6884 9.97119 8.02393 9.94404 8.278 9.71712C8.51656 9.50377 8.60384 9.19733 8.55341 8.84628L8.2043 6.43355L9.78305 4.77333C10.0585 4.49599 10.1651 4.15463 10.072 3.79777C9.96925 3.40211 9.63565 3.17325 9.15272 3.09761L7.05418 2.78147L5.95642 0.504498C5.78575 0.182542 5.5181 -0.00752932 5.1845 0.000228676C4.85673 0.00992617 4.59683 0.209695 4.39319 0.568502L3.33034 2.79505L0.97773 3.12282C0.603407 3.18682 0.327998 3.38271 0.217447 3.70661C0.106895 4.03051 0.211629 4.35828 0.494795 4.69188L2.20349 6.43548L1.81947 8.84628C1.75935 9.26327 1.83111 9.59299 2.1104 9.79082C2.36059 9.96731 2.67479 9.96731 3.03748 9.84318L3.0976 9.81797L5.1942 8.69694Z" fill="#FBB723" />
              </g>
              <defs>
                <clipPath id="clip0_3693_14062">
                  <rect width="9.93023" height="9.93023" fill="white" transform="matrix(-1 0 0 1 10.105 0)" />
                </clipPath>
              </defs>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 11 10" fill="none">
              <g clip-path="url(#clip0_3693_14062)">
                <path d="M5.1942 8.69694L7.28498 9.81603L7.33347 9.83737C7.6884 9.97119 8.02393 9.94404 8.278 9.71712C8.51656 9.50377 8.60384 9.19733 8.55341 8.84628L8.2043 6.43355L9.78305 4.77333C10.0585 4.49599 10.1651 4.15463 10.072 3.79777C9.96925 3.40211 9.63565 3.17325 9.15272 3.09761L7.05418 2.78147L5.95642 0.504498C5.78575 0.182542 5.5181 -0.00752932 5.1845 0.000228676C4.85673 0.00992617 4.59683 0.209695 4.39319 0.568502L3.33034 2.79505L0.97773 3.12282C0.603407 3.18682 0.327998 3.38271 0.217447 3.70661C0.106895 4.03051 0.211629 4.35828 0.494795 4.69188L2.20349 6.43548L1.81947 8.84628C1.75935 9.26327 1.83111 9.59299 2.1104 9.79082C2.36059 9.96731 2.67479 9.96731 3.03748 9.84318L3.0976 9.81797L5.1942 8.69694Z" fill="#FBB723" />
              </g>
              <defs>
                <clipPath id="clip0_3693_14062">
                  <rect width="9.93023" height="9.93023" fill="white" transform="matrix(-1 0 0 1 10.105 0)" />
                </clipPath>
              </defs>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 11 10" fill="none">
              <g clip-path="url(#clip0_3693_14062)">
                <path d="M5.1942 8.69694L7.28498 9.81603L7.33347 9.83737C7.6884 9.97119 8.02393 9.94404 8.278 9.71712C8.51656 9.50377 8.60384 9.19733 8.55341 8.84628L8.2043 6.43355L9.78305 4.77333C10.0585 4.49599 10.1651 4.15463 10.072 3.79777C9.96925 3.40211 9.63565 3.17325 9.15272 3.09761L7.05418 2.78147L5.95642 0.504498C5.78575 0.182542 5.5181 -0.00752932 5.1845 0.000228676C4.85673 0.00992617 4.59683 0.209695 4.39319 0.568502L3.33034 2.79505L0.97773 3.12282C0.603407 3.18682 0.327998 3.38271 0.217447 3.70661C0.106895 4.03051 0.211629 4.35828 0.494795 4.69188L2.20349 6.43548L1.81947 8.84628C1.75935 9.26327 1.83111 9.59299 2.1104 9.79082C2.36059 9.96731 2.67479 9.96731 3.03748 9.84318L3.0976 9.81797L5.1942 8.69694Z" fill="#FBB723" />
              </g>
              <defs>
                <clipPath id="clip0_3693_14062">
                  <rect width="9.93023" height="9.93023" fill="white" transform="matrix(-1 0 0 1 10.105 0)" />
                </clipPath>
              </defs>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 11 10" fill="none">
              <g clip-path="url(#clip0_3693_14062)">
                <path d="M5.1942 8.69694L7.28498 9.81603L7.33347 9.83737C7.6884 9.97119 8.02393 9.94404 8.278 9.71712C8.51656 9.50377 8.60384 9.19733 8.55341 8.84628L8.2043 6.43355L9.78305 4.77333C10.0585 4.49599 10.1651 4.15463 10.072 3.79777C9.96925 3.40211 9.63565 3.17325 9.15272 3.09761L7.05418 2.78147L5.95642 0.504498C5.78575 0.182542 5.5181 -0.00752932 5.1845 0.000228676C4.85673 0.00992617 4.59683 0.209695 4.39319 0.568502L3.33034 2.79505L0.97773 3.12282C0.603407 3.18682 0.327998 3.38271 0.217447 3.70661C0.106895 4.03051 0.211629 4.35828 0.494795 4.69188L2.20349 6.43548L1.81947 8.84628C1.75935 9.26327 1.83111 9.59299 2.1104 9.79082C2.36059 9.96731 2.67479 9.96731 3.03748 9.84318L3.0976 9.81797L5.1942 8.69694Z" fill="#FBB723" />
              </g>
              <defs>
                <clipPath id="clip0_3693_14062">
                  <rect width="9.93023" height="9.93023" fill="white" transform="matrix(-1 0 0 1 10.105 0)" />
                </clipPath>
              </defs>
            </svg>
          </div>
                </div>
                
          <div className="date_of_review ">
          03/12/2024
          </div>
                </div>
            <div className="paraofreview" style={{marginTop:"8px"}}>I was able to go to my CDL driving school with my permit and theory course completed. in less than 2 weeks”
</div>
             <img  src={review3} alt="reviewer"/>
             <div className="posted">
              Posted on<img src={google} alt="google"></img>
             </div>
            </div></div>
            <div className='Slider_cover '>
            <div className="cover_each_carousel ">
                <div className="name_section">
                     <div className="nameofperson">
                     Jefferson  <div className="startsreview">
            <svg xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 11 10" fill="none">
              <g clip-path="url(#clip0_3693_14062)">
                <path d="M5.1942 8.69694L7.28498 9.81603L7.33347 9.83737C7.6884 9.97119 8.02393 9.94404 8.278 9.71712C8.51656 9.50377 8.60384 9.19733 8.55341 8.84628L8.2043 6.43355L9.78305 4.77333C10.0585 4.49599 10.1651 4.15463 10.072 3.79777C9.96925 3.40211 9.63565 3.17325 9.15272 3.09761L7.05418 2.78147L5.95642 0.504498C5.78575 0.182542 5.5181 -0.00752932 5.1845 0.000228676C4.85673 0.00992617 4.59683 0.209695 4.39319 0.568502L3.33034 2.79505L0.97773 3.12282C0.603407 3.18682 0.327998 3.38271 0.217447 3.70661C0.106895 4.03051 0.211629 4.35828 0.494795 4.69188L2.20349 6.43548L1.81947 8.84628C1.75935 9.26327 1.83111 9.59299 2.1104 9.79082C2.36059 9.96731 2.67479 9.96731 3.03748 9.84318L3.0976 9.81797L5.1942 8.69694Z" fill="#FBB723" />
              </g>
              <defs>
                <clipPath id="clip0_3693_14062">
                  <rect width="9.93023" height="9.93023" fill="white" transform="matrix(-1 0 0 1 10.105 0)" />
                </clipPath>
              </defs>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 11 10" fill="none">
              <g clip-path="url(#clip0_3693_14062)">
                <path d="M5.1942 8.69694L7.28498 9.81603L7.33347 9.83737C7.6884 9.97119 8.02393 9.94404 8.278 9.71712C8.51656 9.50377 8.60384 9.19733 8.55341 8.84628L8.2043 6.43355L9.78305 4.77333C10.0585 4.49599 10.1651 4.15463 10.072 3.79777C9.96925 3.40211 9.63565 3.17325 9.15272 3.09761L7.05418 2.78147L5.95642 0.504498C5.78575 0.182542 5.5181 -0.00752932 5.1845 0.000228676C4.85673 0.00992617 4.59683 0.209695 4.39319 0.568502L3.33034 2.79505L0.97773 3.12282C0.603407 3.18682 0.327998 3.38271 0.217447 3.70661C0.106895 4.03051 0.211629 4.35828 0.494795 4.69188L2.20349 6.43548L1.81947 8.84628C1.75935 9.26327 1.83111 9.59299 2.1104 9.79082C2.36059 9.96731 2.67479 9.96731 3.03748 9.84318L3.0976 9.81797L5.1942 8.69694Z" fill="#FBB723" />
              </g>
              <defs>
                <clipPath id="clip0_3693_14062">
                  <rect width="9.93023" height="9.93023" fill="white" transform="matrix(-1 0 0 1 10.105 0)" />
                </clipPath>
              </defs>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 11 10" fill="none">
              <g clip-path="url(#clip0_3693_14062)">
                <path d="M5.1942 8.69694L7.28498 9.81603L7.33347 9.83737C7.6884 9.97119 8.02393 9.94404 8.278 9.71712C8.51656 9.50377 8.60384 9.19733 8.55341 8.84628L8.2043 6.43355L9.78305 4.77333C10.0585 4.49599 10.1651 4.15463 10.072 3.79777C9.96925 3.40211 9.63565 3.17325 9.15272 3.09761L7.05418 2.78147L5.95642 0.504498C5.78575 0.182542 5.5181 -0.00752932 5.1845 0.000228676C4.85673 0.00992617 4.59683 0.209695 4.39319 0.568502L3.33034 2.79505L0.97773 3.12282C0.603407 3.18682 0.327998 3.38271 0.217447 3.70661C0.106895 4.03051 0.211629 4.35828 0.494795 4.69188L2.20349 6.43548L1.81947 8.84628C1.75935 9.26327 1.83111 9.59299 2.1104 9.79082C2.36059 9.96731 2.67479 9.96731 3.03748 9.84318L3.0976 9.81797L5.1942 8.69694Z" fill="#FBB723" />
              </g>
              <defs>
                <clipPath id="clip0_3693_14062">
                  <rect width="9.93023" height="9.93023" fill="white" transform="matrix(-1 0 0 1 10.105 0)" />
                </clipPath>
              </defs>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 11 10" fill="none">
              <g clip-path="url(#clip0_3693_14062)">
                <path d="M5.1942 8.69694L7.28498 9.81603L7.33347 9.83737C7.6884 9.97119 8.02393 9.94404 8.278 9.71712C8.51656 9.50377 8.60384 9.19733 8.55341 8.84628L8.2043 6.43355L9.78305 4.77333C10.0585 4.49599 10.1651 4.15463 10.072 3.79777C9.96925 3.40211 9.63565 3.17325 9.15272 3.09761L7.05418 2.78147L5.95642 0.504498C5.78575 0.182542 5.5181 -0.00752932 5.1845 0.000228676C4.85673 0.00992617 4.59683 0.209695 4.39319 0.568502L3.33034 2.79505L0.97773 3.12282C0.603407 3.18682 0.327998 3.38271 0.217447 3.70661C0.106895 4.03051 0.211629 4.35828 0.494795 4.69188L2.20349 6.43548L1.81947 8.84628C1.75935 9.26327 1.83111 9.59299 2.1104 9.79082C2.36059 9.96731 2.67479 9.96731 3.03748 9.84318L3.0976 9.81797L5.1942 8.69694Z" fill="#FBB723" />
              </g>
              <defs>
                <clipPath id="clip0_3693_14062">
                  <rect width="9.93023" height="9.93023" fill="white" transform="matrix(-1 0 0 1 10.105 0)" />
                </clipPath>
              </defs>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 11 10" fill="none">
              <g clip-path="url(#clip0_3693_14062)">
                <path d="M5.1942 8.69694L7.28498 9.81603L7.33347 9.83737C7.6884 9.97119 8.02393 9.94404 8.278 9.71712C8.51656 9.50377 8.60384 9.19733 8.55341 8.84628L8.2043 6.43355L9.78305 4.77333C10.0585 4.49599 10.1651 4.15463 10.072 3.79777C9.96925 3.40211 9.63565 3.17325 9.15272 3.09761L7.05418 2.78147L5.95642 0.504498C5.78575 0.182542 5.5181 -0.00752932 5.1845 0.000228676C4.85673 0.00992617 4.59683 0.209695 4.39319 0.568502L3.33034 2.79505L0.97773 3.12282C0.603407 3.18682 0.327998 3.38271 0.217447 3.70661C0.106895 4.03051 0.211629 4.35828 0.494795 4.69188L2.20349 6.43548L1.81947 8.84628C1.75935 9.26327 1.83111 9.59299 2.1104 9.79082C2.36059 9.96731 2.67479 9.96731 3.03748 9.84318L3.0976 9.81797L5.1942 8.69694Z" fill="#FBB723" />
              </g>
              <defs>
                <clipPath id="clip0_3693_14062">
                  <rect width="9.93023" height="9.93023" fill="white" transform="matrix(-1 0 0 1 10.105 0)" />
                </clipPath>
              </defs>
            </svg>
          </div>
                </div>
               
          <div className="date_of_review ">
          03/12/2024
          </div>
                </div>
            <div className="paraofreview" style={{marginTop:"8px"}}>"Este curso fue el mejor que encontré. Pagué 279 dólares en un sitio, lo peor, todo en inglés.” Thank YOU United ELDT
</div>
             <img  src={review0} alt="reviewer"/>
             <div className="posted">
              Posted on<img src={google} alt="google"></img>
             </div>
            </div>    </div>
     
            <div className='Slider_cover '>
        <div className="cover_each_carousel">
                <div className="name_section">
                     <div className="nameofperson">
                     Edwin 
                </div>
                <div className="startsreview">
            <svg xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 11 10" fill="none">
              <g clip-path="url(#clip0_3693_14062)">
                <path d="M5.1942 8.69694L7.28498 9.81603L7.33347 9.83737C7.6884 9.97119 8.02393 9.94404 8.278 9.71712C8.51656 9.50377 8.60384 9.19733 8.55341 8.84628L8.2043 6.43355L9.78305 4.77333C10.0585 4.49599 10.1651 4.15463 10.072 3.79777C9.96925 3.40211 9.63565 3.17325 9.15272 3.09761L7.05418 2.78147L5.95642 0.504498C5.78575 0.182542 5.5181 -0.00752932 5.1845 0.000228676C4.85673 0.00992617 4.59683 0.209695 4.39319 0.568502L3.33034 2.79505L0.97773 3.12282C0.603407 3.18682 0.327998 3.38271 0.217447 3.70661C0.106895 4.03051 0.211629 4.35828 0.494795 4.69188L2.20349 6.43548L1.81947 8.84628C1.75935 9.26327 1.83111 9.59299 2.1104 9.79082C2.36059 9.96731 2.67479 9.96731 3.03748 9.84318L3.0976 9.81797L5.1942 8.69694Z" fill="#FBB723" />
              </g>
              <defs>
                <clipPath id="clip0_3693_14062">
                  <rect width="9.93023" height="9.93023" fill="white" transform="matrix(-1 0 0 1 10.105 0)" />
                </clipPath>
              </defs>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 11 10" fill="none">
              <g clip-path="url(#clip0_3693_14062)">
                <path d="M5.1942 8.69694L7.28498 9.81603L7.33347 9.83737C7.6884 9.97119 8.02393 9.94404 8.278 9.71712C8.51656 9.50377 8.60384 9.19733 8.55341 8.84628L8.2043 6.43355L9.78305 4.77333C10.0585 4.49599 10.1651 4.15463 10.072 3.79777C9.96925 3.40211 9.63565 3.17325 9.15272 3.09761L7.05418 2.78147L5.95642 0.504498C5.78575 0.182542 5.5181 -0.00752932 5.1845 0.000228676C4.85673 0.00992617 4.59683 0.209695 4.39319 0.568502L3.33034 2.79505L0.97773 3.12282C0.603407 3.18682 0.327998 3.38271 0.217447 3.70661C0.106895 4.03051 0.211629 4.35828 0.494795 4.69188L2.20349 6.43548L1.81947 8.84628C1.75935 9.26327 1.83111 9.59299 2.1104 9.79082C2.36059 9.96731 2.67479 9.96731 3.03748 9.84318L3.0976 9.81797L5.1942 8.69694Z" fill="#FBB723" />
              </g>
              <defs>
                <clipPath id="clip0_3693_14062">
                  <rect width="9.93023" height="9.93023" fill="white" transform="matrix(-1 0 0 1 10.105 0)" />
                </clipPath>
              </defs>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 11 10" fill="none">
              <g clip-path="url(#clip0_3693_14062)">
                <path d="M5.1942 8.69694L7.28498 9.81603L7.33347 9.83737C7.6884 9.97119 8.02393 9.94404 8.278 9.71712C8.51656 9.50377 8.60384 9.19733 8.55341 8.84628L8.2043 6.43355L9.78305 4.77333C10.0585 4.49599 10.1651 4.15463 10.072 3.79777C9.96925 3.40211 9.63565 3.17325 9.15272 3.09761L7.05418 2.78147L5.95642 0.504498C5.78575 0.182542 5.5181 -0.00752932 5.1845 0.000228676C4.85673 0.00992617 4.59683 0.209695 4.39319 0.568502L3.33034 2.79505L0.97773 3.12282C0.603407 3.18682 0.327998 3.38271 0.217447 3.70661C0.106895 4.03051 0.211629 4.35828 0.494795 4.69188L2.20349 6.43548L1.81947 8.84628C1.75935 9.26327 1.83111 9.59299 2.1104 9.79082C2.36059 9.96731 2.67479 9.96731 3.03748 9.84318L3.0976 9.81797L5.1942 8.69694Z" fill="#FBB723" />
              </g>
              <defs>
                <clipPath id="clip0_3693_14062">
                  <rect width="9.93023" height="9.93023" fill="white" transform="matrix(-1 0 0 1 10.105 0)" />
                </clipPath>
              </defs>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 11 10" fill="none">
              <g clip-path="url(#clip0_3693_14062)">
                <path d="M5.1942 8.69694L7.28498 9.81603L7.33347 9.83737C7.6884 9.97119 8.02393 9.94404 8.278 9.71712C8.51656 9.50377 8.60384 9.19733 8.55341 8.84628L8.2043 6.43355L9.78305 4.77333C10.0585 4.49599 10.1651 4.15463 10.072 3.79777C9.96925 3.40211 9.63565 3.17325 9.15272 3.09761L7.05418 2.78147L5.95642 0.504498C5.78575 0.182542 5.5181 -0.00752932 5.1845 0.000228676C4.85673 0.00992617 4.59683 0.209695 4.39319 0.568502L3.33034 2.79505L0.97773 3.12282C0.603407 3.18682 0.327998 3.38271 0.217447 3.70661C0.106895 4.03051 0.211629 4.35828 0.494795 4.69188L2.20349 6.43548L1.81947 8.84628C1.75935 9.26327 1.83111 9.59299 2.1104 9.79082C2.36059 9.96731 2.67479 9.96731 3.03748 9.84318L3.0976 9.81797L5.1942 8.69694Z" fill="#FBB723" />
              </g>
              <defs>
                <clipPath id="clip0_3693_14062">
                  <rect width="9.93023" height="9.93023" fill="white" transform="matrix(-1 0 0 1 10.105 0)" />
                </clipPath>
              </defs>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 11 10" fill="none">
              <g clip-path="url(#clip0_3693_14062)">
                <path d="M5.1942 8.69694L7.28498 9.81603L7.33347 9.83737C7.6884 9.97119 8.02393 9.94404 8.278 9.71712C8.51656 9.50377 8.60384 9.19733 8.55341 8.84628L8.2043 6.43355L9.78305 4.77333C10.0585 4.49599 10.1651 4.15463 10.072 3.79777C9.96925 3.40211 9.63565 3.17325 9.15272 3.09761L7.05418 2.78147L5.95642 0.504498C5.78575 0.182542 5.5181 -0.00752932 5.1845 0.000228676C4.85673 0.00992617 4.59683 0.209695 4.39319 0.568502L3.33034 2.79505L0.97773 3.12282C0.603407 3.18682 0.327998 3.38271 0.217447 3.70661C0.106895 4.03051 0.211629 4.35828 0.494795 4.69188L2.20349 6.43548L1.81947 8.84628C1.75935 9.26327 1.83111 9.59299 2.1104 9.79082C2.36059 9.96731 2.67479 9.96731 3.03748 9.84318L3.0976 9.81797L5.1942 8.69694Z" fill="#FBB723" />
              </g>
              <defs>
                <clipPath id="clip0_3693_14062">
                  <rect width="9.93023" height="9.93023" fill="white" transform="matrix(-1 0 0 1 10.105 0)" />
                </clipPath>
              </defs>
            </svg>
          </div>
          <div className="date_of_review ">
          03/08/2024
          </div>
                </div>
            <div className="paraofreview" style={{marginTop:"8px"}}>"Pagar $50 dólares por este curso en español fue la mejor decisión que tomé. Encontré cursos que cobraban $280 y llevaban"</div>
             <img  src={review4} alt="reviewer"/>
             <div className="posted">
              Posted on<img src={google} alt="google"></img>
             </div>
            </div></div>
            <div className='Slider_cover '>

            <div className="cover_each_carousel">
                <div className="name_section">
                     <div className="nameofperson">
                     Austin   <div className="startsreview">
            <svg xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 11 10" fill="none">
              <g clip-path="url(#clip0_3693_14062)">
                <path d="M5.1942 8.69694L7.28498 9.81603L7.33347 9.83737C7.6884 9.97119 8.02393 9.94404 8.278 9.71712C8.51656 9.50377 8.60384 9.19733 8.55341 8.84628L8.2043 6.43355L9.78305 4.77333C10.0585 4.49599 10.1651 4.15463 10.072 3.79777C9.96925 3.40211 9.63565 3.17325 9.15272 3.09761L7.05418 2.78147L5.95642 0.504498C5.78575 0.182542 5.5181 -0.00752932 5.1845 0.000228676C4.85673 0.00992617 4.59683 0.209695 4.39319 0.568502L3.33034 2.79505L0.97773 3.12282C0.603407 3.18682 0.327998 3.38271 0.217447 3.70661C0.106895 4.03051 0.211629 4.35828 0.494795 4.69188L2.20349 6.43548L1.81947 8.84628C1.75935 9.26327 1.83111 9.59299 2.1104 9.79082C2.36059 9.96731 2.67479 9.96731 3.03748 9.84318L3.0976 9.81797L5.1942 8.69694Z" fill="#FBB723" />
              </g>
              <defs>
                <clipPath id="clip0_3693_14062">
                  <rect width="9.93023" height="9.93023" fill="white" transform="matrix(-1 0 0 1 10.105 0)" />
                </clipPath>
              </defs>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 11 10" fill="none">
              <g clip-path="url(#clip0_3693_14062)">
                <path d="M5.1942 8.69694L7.28498 9.81603L7.33347 9.83737C7.6884 9.97119 8.02393 9.94404 8.278 9.71712C8.51656 9.50377 8.60384 9.19733 8.55341 8.84628L8.2043 6.43355L9.78305 4.77333C10.0585 4.49599 10.1651 4.15463 10.072 3.79777C9.96925 3.40211 9.63565 3.17325 9.15272 3.09761L7.05418 2.78147L5.95642 0.504498C5.78575 0.182542 5.5181 -0.00752932 5.1845 0.000228676C4.85673 0.00992617 4.59683 0.209695 4.39319 0.568502L3.33034 2.79505L0.97773 3.12282C0.603407 3.18682 0.327998 3.38271 0.217447 3.70661C0.106895 4.03051 0.211629 4.35828 0.494795 4.69188L2.20349 6.43548L1.81947 8.84628C1.75935 9.26327 1.83111 9.59299 2.1104 9.79082C2.36059 9.96731 2.67479 9.96731 3.03748 9.84318L3.0976 9.81797L5.1942 8.69694Z" fill="#FBB723" />
              </g>
              <defs>
                <clipPath id="clip0_3693_14062">
                  <rect width="9.93023" height="9.93023" fill="white" transform="matrix(-1 0 0 1 10.105 0)" />
                </clipPath>
              </defs>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 11 10" fill="none">
              <g clip-path="url(#clip0_3693_14062)">
                <path d="M5.1942 8.69694L7.28498 9.81603L7.33347 9.83737C7.6884 9.97119 8.02393 9.94404 8.278 9.71712C8.51656 9.50377 8.60384 9.19733 8.55341 8.84628L8.2043 6.43355L9.78305 4.77333C10.0585 4.49599 10.1651 4.15463 10.072 3.79777C9.96925 3.40211 9.63565 3.17325 9.15272 3.09761L7.05418 2.78147L5.95642 0.504498C5.78575 0.182542 5.5181 -0.00752932 5.1845 0.000228676C4.85673 0.00992617 4.59683 0.209695 4.39319 0.568502L3.33034 2.79505L0.97773 3.12282C0.603407 3.18682 0.327998 3.38271 0.217447 3.70661C0.106895 4.03051 0.211629 4.35828 0.494795 4.69188L2.20349 6.43548L1.81947 8.84628C1.75935 9.26327 1.83111 9.59299 2.1104 9.79082C2.36059 9.96731 2.67479 9.96731 3.03748 9.84318L3.0976 9.81797L5.1942 8.69694Z" fill="#FBB723" />
              </g>
              <defs>
                <clipPath id="clip0_3693_14062">
                  <rect width="9.93023" height="9.93023" fill="white" transform="matrix(-1 0 0 1 10.105 0)" />
                </clipPath>
              </defs>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 11 10" fill="none">
              <g clip-path="url(#clip0_3693_14062)">
                <path d="M5.1942 8.69694L7.28498 9.81603L7.33347 9.83737C7.6884 9.97119 8.02393 9.94404 8.278 9.71712C8.51656 9.50377 8.60384 9.19733 8.55341 8.84628L8.2043 6.43355L9.78305 4.77333C10.0585 4.49599 10.1651 4.15463 10.072 3.79777C9.96925 3.40211 9.63565 3.17325 9.15272 3.09761L7.05418 2.78147L5.95642 0.504498C5.78575 0.182542 5.5181 -0.00752932 5.1845 0.000228676C4.85673 0.00992617 4.59683 0.209695 4.39319 0.568502L3.33034 2.79505L0.97773 3.12282C0.603407 3.18682 0.327998 3.38271 0.217447 3.70661C0.106895 4.03051 0.211629 4.35828 0.494795 4.69188L2.20349 6.43548L1.81947 8.84628C1.75935 9.26327 1.83111 9.59299 2.1104 9.79082C2.36059 9.96731 2.67479 9.96731 3.03748 9.84318L3.0976 9.81797L5.1942 8.69694Z" fill="#FBB723" />
              </g>
              <defs>
                <clipPath id="clip0_3693_14062">
                  <rect width="9.93023" height="9.93023" fill="white" transform="matrix(-1 0 0 1 10.105 0)" />
                </clipPath>
              </defs>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 11 10" fill="none">
              <g clip-path="url(#clip0_3693_14062)">
                <path d="M5.1942 8.69694L7.28498 9.81603L7.33347 9.83737C7.6884 9.97119 8.02393 9.94404 8.278 9.71712C8.51656 9.50377 8.60384 9.19733 8.55341 8.84628L8.2043 6.43355L9.78305 4.77333C10.0585 4.49599 10.1651 4.15463 10.072 3.79777C9.96925 3.40211 9.63565 3.17325 9.15272 3.09761L7.05418 2.78147L5.95642 0.504498C5.78575 0.182542 5.5181 -0.00752932 5.1845 0.000228676C4.85673 0.00992617 4.59683 0.209695 4.39319 0.568502L3.33034 2.79505L0.97773 3.12282C0.603407 3.18682 0.327998 3.38271 0.217447 3.70661C0.106895 4.03051 0.211629 4.35828 0.494795 4.69188L2.20349 6.43548L1.81947 8.84628C1.75935 9.26327 1.83111 9.59299 2.1104 9.79082C2.36059 9.96731 2.67479 9.96731 3.03748 9.84318L3.0976 9.81797L5.1942 8.69694Z" fill="#FBB723" />
              </g>
              <defs>
                <clipPath id="clip0_3693_14062">
                  <rect width="9.93023" height="9.93023" fill="white" transform="matrix(-1 0 0 1 10.105 0)" />
                </clipPath>
              </defs>
            </svg>
          </div>
                </div>
              
          <div className="date_of_review ">
          03/05/2024
          </div>
                </div>
            <div className="paraofreview" style={{marginTop:"8px"}}>“A friend recommended United ELDT, and it was truly a smart decision. Other companies offer courses at almost $200”</div>
             <img  src={review5} alt="reviewer"/>
             <div className="posted">
              Posted on<img src={google} alt="google"></img>
             </div>
            </div></div>
            <div className='Slider_cover '>

            <div className="cover_each_carousel">
                <div className="name_section">
                     <div className="nameofperson">
                     Gerson Escoto   <div className="startsreview">
            <svg xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 11 10" fill="none">
              <g clip-path="url(#clip0_3693_14062)">
                <path d="M5.1942 8.69694L7.28498 9.81603L7.33347 9.83737C7.6884 9.97119 8.02393 9.94404 8.278 9.71712C8.51656 9.50377 8.60384 9.19733 8.55341 8.84628L8.2043 6.43355L9.78305 4.77333C10.0585 4.49599 10.1651 4.15463 10.072 3.79777C9.96925 3.40211 9.63565 3.17325 9.15272 3.09761L7.05418 2.78147L5.95642 0.504498C5.78575 0.182542 5.5181 -0.00752932 5.1845 0.000228676C4.85673 0.00992617 4.59683 0.209695 4.39319 0.568502L3.33034 2.79505L0.97773 3.12282C0.603407 3.18682 0.327998 3.38271 0.217447 3.70661C0.106895 4.03051 0.211629 4.35828 0.494795 4.69188L2.20349 6.43548L1.81947 8.84628C1.75935 9.26327 1.83111 9.59299 2.1104 9.79082C2.36059 9.96731 2.67479 9.96731 3.03748 9.84318L3.0976 9.81797L5.1942 8.69694Z" fill="#FBB723" />
              </g>
              <defs>
                <clipPath id="clip0_3693_14062">
                  <rect width="9.93023" height="9.93023" fill="white" transform="matrix(-1 0 0 1 10.105 0)" />
                </clipPath>
              </defs>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 11 10" fill="none">
              <g clip-path="url(#clip0_3693_14062)">
                <path d="M5.1942 8.69694L7.28498 9.81603L7.33347 9.83737C7.6884 9.97119 8.02393 9.94404 8.278 9.71712C8.51656 9.50377 8.60384 9.19733 8.55341 8.84628L8.2043 6.43355L9.78305 4.77333C10.0585 4.49599 10.1651 4.15463 10.072 3.79777C9.96925 3.40211 9.63565 3.17325 9.15272 3.09761L7.05418 2.78147L5.95642 0.504498C5.78575 0.182542 5.5181 -0.00752932 5.1845 0.000228676C4.85673 0.00992617 4.59683 0.209695 4.39319 0.568502L3.33034 2.79505L0.97773 3.12282C0.603407 3.18682 0.327998 3.38271 0.217447 3.70661C0.106895 4.03051 0.211629 4.35828 0.494795 4.69188L2.20349 6.43548L1.81947 8.84628C1.75935 9.26327 1.83111 9.59299 2.1104 9.79082C2.36059 9.96731 2.67479 9.96731 3.03748 9.84318L3.0976 9.81797L5.1942 8.69694Z" fill="#FBB723" />
              </g>
              <defs>
                <clipPath id="clip0_3693_14062">
                  <rect width="9.93023" height="9.93023" fill="white" transform="matrix(-1 0 0 1 10.105 0)" />
                </clipPath>
              </defs>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 11 10" fill="none">
              <g clip-path="url(#clip0_3693_14062)">
                <path d="M5.1942 8.69694L7.28498 9.81603L7.33347 9.83737C7.6884 9.97119 8.02393 9.94404 8.278 9.71712C8.51656 9.50377 8.60384 9.19733 8.55341 8.84628L8.2043 6.43355L9.78305 4.77333C10.0585 4.49599 10.1651 4.15463 10.072 3.79777C9.96925 3.40211 9.63565 3.17325 9.15272 3.09761L7.05418 2.78147L5.95642 0.504498C5.78575 0.182542 5.5181 -0.00752932 5.1845 0.000228676C4.85673 0.00992617 4.59683 0.209695 4.39319 0.568502L3.33034 2.79505L0.97773 3.12282C0.603407 3.18682 0.327998 3.38271 0.217447 3.70661C0.106895 4.03051 0.211629 4.35828 0.494795 4.69188L2.20349 6.43548L1.81947 8.84628C1.75935 9.26327 1.83111 9.59299 2.1104 9.79082C2.36059 9.96731 2.67479 9.96731 3.03748 9.84318L3.0976 9.81797L5.1942 8.69694Z" fill="#FBB723" />
              </g>
              <defs>
                <clipPath id="clip0_3693_14062">
                  <rect width="9.93023" height="9.93023" fill="white" transform="matrix(-1 0 0 1 10.105 0)" />
                </clipPath>
              </defs>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 11 10" fill="none">
              <g clip-path="url(#clip0_3693_14062)">
                <path d="M5.1942 8.69694L7.28498 9.81603L7.33347 9.83737C7.6884 9.97119 8.02393 9.94404 8.278 9.71712C8.51656 9.50377 8.60384 9.19733 8.55341 8.84628L8.2043 6.43355L9.78305 4.77333C10.0585 4.49599 10.1651 4.15463 10.072 3.79777C9.96925 3.40211 9.63565 3.17325 9.15272 3.09761L7.05418 2.78147L5.95642 0.504498C5.78575 0.182542 5.5181 -0.00752932 5.1845 0.000228676C4.85673 0.00992617 4.59683 0.209695 4.39319 0.568502L3.33034 2.79505L0.97773 3.12282C0.603407 3.18682 0.327998 3.38271 0.217447 3.70661C0.106895 4.03051 0.211629 4.35828 0.494795 4.69188L2.20349 6.43548L1.81947 8.84628C1.75935 9.26327 1.83111 9.59299 2.1104 9.79082C2.36059 9.96731 2.67479 9.96731 3.03748 9.84318L3.0976 9.81797L5.1942 8.69694Z" fill="#FBB723" />
              </g>
              <defs>
                <clipPath id="clip0_3693_14062">
                  <rect width="9.93023" height="9.93023" fill="white" transform="matrix(-1 0 0 1 10.105 0)" />
                </clipPath>
              </defs>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 11 10" fill="none">
              <g clip-path="url(#clip0_3693_14062)">
                <path d="M5.1942 8.69694L7.28498 9.81603L7.33347 9.83737C7.6884 9.97119 8.02393 9.94404 8.278 9.71712C8.51656 9.50377 8.60384 9.19733 8.55341 8.84628L8.2043 6.43355L9.78305 4.77333C10.0585 4.49599 10.1651 4.15463 10.072 3.79777C9.96925 3.40211 9.63565 3.17325 9.15272 3.09761L7.05418 2.78147L5.95642 0.504498C5.78575 0.182542 5.5181 -0.00752932 5.1845 0.000228676C4.85673 0.00992617 4.59683 0.209695 4.39319 0.568502L3.33034 2.79505L0.97773 3.12282C0.603407 3.18682 0.327998 3.38271 0.217447 3.70661C0.106895 4.03051 0.211629 4.35828 0.494795 4.69188L2.20349 6.43548L1.81947 8.84628C1.75935 9.26327 1.83111 9.59299 2.1104 9.79082C2.36059 9.96731 2.67479 9.96731 3.03748 9.84318L3.0976 9.81797L5.1942 8.69694Z" fill="#FBB723" />
              </g>
              <defs>
                <clipPath id="clip0_3693_14062">
                  <rect width="9.93023" height="9.93023" fill="white" transform="matrix(-1 0 0 1 10.105 0)" />
                </clipPath>
              </defs>
            </svg>
          </div>
                </div>
              
               
          <div className="date_of_review ">
          03/09/2024
          </div>
                </div>
            <div className="paraofreview" style={{marginTop:"8px"}}>"Este curso fue el mejor que encontré. Pagué 30 dólares en un sitio web llamado ELDT.com, que tenía un curso que tomaba "</div>
             <img  src={review6} alt="reviewer"/>
             <div className="posted">
              Posted on<img src={google} alt="google"></img>
             </div>
            </div></div>
            <div className='Slider_cover '>

            <div className="cover_each_carousel ">
                <div className="name_section">
                     <div className="nameofperson">
                     Jose Menjivar  <div className="startsreview">
            <svg xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 11 10" fill="none">
              <g clip-path="url(#clip0_3693_14062)">
                <path d="M5.1942 8.69694L7.28498 9.81603L7.33347 9.83737C7.6884 9.97119 8.02393 9.94404 8.278 9.71712C8.51656 9.50377 8.60384 9.19733 8.55341 8.84628L8.2043 6.43355L9.78305 4.77333C10.0585 4.49599 10.1651 4.15463 10.072 3.79777C9.96925 3.40211 9.63565 3.17325 9.15272 3.09761L7.05418 2.78147L5.95642 0.504498C5.78575 0.182542 5.5181 -0.00752932 5.1845 0.000228676C4.85673 0.00992617 4.59683 0.209695 4.39319 0.568502L3.33034 2.79505L0.97773 3.12282C0.603407 3.18682 0.327998 3.38271 0.217447 3.70661C0.106895 4.03051 0.211629 4.35828 0.494795 4.69188L2.20349 6.43548L1.81947 8.84628C1.75935 9.26327 1.83111 9.59299 2.1104 9.79082C2.36059 9.96731 2.67479 9.96731 3.03748 9.84318L3.0976 9.81797L5.1942 8.69694Z" fill="#FBB723" />
              </g>
              <defs>
                <clipPath id="clip0_3693_14062">
                  <rect width="9.93023" height="9.93023" fill="white" transform="matrix(-1 0 0 1 10.105 0)" />
                </clipPath>
              </defs>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 11 10" fill="none">
              <g clip-path="url(#clip0_3693_14062)">
                <path d="M5.1942 8.69694L7.28498 9.81603L7.33347 9.83737C7.6884 9.97119 8.02393 9.94404 8.278 9.71712C8.51656 9.50377 8.60384 9.19733 8.55341 8.84628L8.2043 6.43355L9.78305 4.77333C10.0585 4.49599 10.1651 4.15463 10.072 3.79777C9.96925 3.40211 9.63565 3.17325 9.15272 3.09761L7.05418 2.78147L5.95642 0.504498C5.78575 0.182542 5.5181 -0.00752932 5.1845 0.000228676C4.85673 0.00992617 4.59683 0.209695 4.39319 0.568502L3.33034 2.79505L0.97773 3.12282C0.603407 3.18682 0.327998 3.38271 0.217447 3.70661C0.106895 4.03051 0.211629 4.35828 0.494795 4.69188L2.20349 6.43548L1.81947 8.84628C1.75935 9.26327 1.83111 9.59299 2.1104 9.79082C2.36059 9.96731 2.67479 9.96731 3.03748 9.84318L3.0976 9.81797L5.1942 8.69694Z" fill="#FBB723" />
              </g>
              <defs>
                <clipPath id="clip0_3693_14062">
                  <rect width="9.93023" height="9.93023" fill="white" transform="matrix(-1 0 0 1 10.105 0)" />
                </clipPath>
              </defs>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 11 10" fill="none">
              <g clip-path="url(#clip0_3693_14062)">
                <path d="M5.1942 8.69694L7.28498 9.81603L7.33347 9.83737C7.6884 9.97119 8.02393 9.94404 8.278 9.71712C8.51656 9.50377 8.60384 9.19733 8.55341 8.84628L8.2043 6.43355L9.78305 4.77333C10.0585 4.49599 10.1651 4.15463 10.072 3.79777C9.96925 3.40211 9.63565 3.17325 9.15272 3.09761L7.05418 2.78147L5.95642 0.504498C5.78575 0.182542 5.5181 -0.00752932 5.1845 0.000228676C4.85673 0.00992617 4.59683 0.209695 4.39319 0.568502L3.33034 2.79505L0.97773 3.12282C0.603407 3.18682 0.327998 3.38271 0.217447 3.70661C0.106895 4.03051 0.211629 4.35828 0.494795 4.69188L2.20349 6.43548L1.81947 8.84628C1.75935 9.26327 1.83111 9.59299 2.1104 9.79082C2.36059 9.96731 2.67479 9.96731 3.03748 9.84318L3.0976 9.81797L5.1942 8.69694Z" fill="#FBB723" />
              </g>
              <defs>
                <clipPath id="clip0_3693_14062">
                  <rect width="9.93023" height="9.93023" fill="white" transform="matrix(-1 0 0 1 10.105 0)" />
                </clipPath>
              </defs>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 11 10" fill="none">
              <g clip-path="url(#clip0_3693_14062)">
                <path d="M5.1942 8.69694L7.28498 9.81603L7.33347 9.83737C7.6884 9.97119 8.02393 9.94404 8.278 9.71712C8.51656 9.50377 8.60384 9.19733 8.55341 8.84628L8.2043 6.43355L9.78305 4.77333C10.0585 4.49599 10.1651 4.15463 10.072 3.79777C9.96925 3.40211 9.63565 3.17325 9.15272 3.09761L7.05418 2.78147L5.95642 0.504498C5.78575 0.182542 5.5181 -0.00752932 5.1845 0.000228676C4.85673 0.00992617 4.59683 0.209695 4.39319 0.568502L3.33034 2.79505L0.97773 3.12282C0.603407 3.18682 0.327998 3.38271 0.217447 3.70661C0.106895 4.03051 0.211629 4.35828 0.494795 4.69188L2.20349 6.43548L1.81947 8.84628C1.75935 9.26327 1.83111 9.59299 2.1104 9.79082C2.36059 9.96731 2.67479 9.96731 3.03748 9.84318L3.0976 9.81797L5.1942 8.69694Z" fill="#FBB723" />
              </g>
              <defs>
                <clipPath id="clip0_3693_14062">
                  <rect width="9.93023" height="9.93023" fill="white" transform="matrix(-1 0 0 1 10.105 0)" />
                </clipPath>
              </defs>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 11 10" fill="none">
              <g clip-path="url(#clip0_3693_14062)">
                <path d="M5.1942 8.69694L7.28498 9.81603L7.33347 9.83737C7.6884 9.97119 8.02393 9.94404 8.278 9.71712C8.51656 9.50377 8.60384 9.19733 8.55341 8.84628L8.2043 6.43355L9.78305 4.77333C10.0585 4.49599 10.1651 4.15463 10.072 3.79777C9.96925 3.40211 9.63565 3.17325 9.15272 3.09761L7.05418 2.78147L5.95642 0.504498C5.78575 0.182542 5.5181 -0.00752932 5.1845 0.000228676C4.85673 0.00992617 4.59683 0.209695 4.39319 0.568502L3.33034 2.79505L0.97773 3.12282C0.603407 3.18682 0.327998 3.38271 0.217447 3.70661C0.106895 4.03051 0.211629 4.35828 0.494795 4.69188L2.20349 6.43548L1.81947 8.84628C1.75935 9.26327 1.83111 9.59299 2.1104 9.79082C2.36059 9.96731 2.67479 9.96731 3.03748 9.84318L3.0976 9.81797L5.1942 8.69694Z" fill="#FBB723" />
              </g>
              <defs>
                <clipPath id="clip0_3693_14062">
                  <rect width="9.93023" height="9.93023" fill="white" transform="matrix(-1 0 0 1 10.105 0)" />
                </clipPath>
              </defs>
            </svg>
          </div>
                </div>
              
          <div className="date_of_review ">
          03/11/2024
          </div>
                </div>
            <div className="paraofreview" style={{marginTop:"8px"}}>"This United ELDT course saved me 3 weeks of mandatory classes at the school I was already attending. Thank you, "</div>
             <img  src={review7} alt="reviewer"/>
             <div className="posted">
              Posted on<img src={google} alt="google"></img>
             </div>
            </div>      </div>
       
            <div className='Slider_cover '>
        <div className="cover_each_carousel">
                <div className="name_section">
                     <div className="nameofperson">
                     Trudy <div className="startsreview">
            <svg xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 11 10" fill="none">
              <g clip-path="url(#clip0_3693_14062)">
                <path d="M5.1942 8.69694L7.28498 9.81603L7.33347 9.83737C7.6884 9.97119 8.02393 9.94404 8.278 9.71712C8.51656 9.50377 8.60384 9.19733 8.55341 8.84628L8.2043 6.43355L9.78305 4.77333C10.0585 4.49599 10.1651 4.15463 10.072 3.79777C9.96925 3.40211 9.63565 3.17325 9.15272 3.09761L7.05418 2.78147L5.95642 0.504498C5.78575 0.182542 5.5181 -0.00752932 5.1845 0.000228676C4.85673 0.00992617 4.59683 0.209695 4.39319 0.568502L3.33034 2.79505L0.97773 3.12282C0.603407 3.18682 0.327998 3.38271 0.217447 3.70661C0.106895 4.03051 0.211629 4.35828 0.494795 4.69188L2.20349 6.43548L1.81947 8.84628C1.75935 9.26327 1.83111 9.59299 2.1104 9.79082C2.36059 9.96731 2.67479 9.96731 3.03748 9.84318L3.0976 9.81797L5.1942 8.69694Z" fill="#FBB723" />
              </g>
              <defs>
                <clipPath id="clip0_3693_14062">
                  <rect width="9.93023" height="9.93023" fill="white" transform="matrix(-1 0 0 1 10.105 0)" />
                </clipPath>
              </defs>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 11 10" fill="none">
              <g clip-path="url(#clip0_3693_14062)">
                <path d="M5.1942 8.69694L7.28498 9.81603L7.33347 9.83737C7.6884 9.97119 8.02393 9.94404 8.278 9.71712C8.51656 9.50377 8.60384 9.19733 8.55341 8.84628L8.2043 6.43355L9.78305 4.77333C10.0585 4.49599 10.1651 4.15463 10.072 3.79777C9.96925 3.40211 9.63565 3.17325 9.15272 3.09761L7.05418 2.78147L5.95642 0.504498C5.78575 0.182542 5.5181 -0.00752932 5.1845 0.000228676C4.85673 0.00992617 4.59683 0.209695 4.39319 0.568502L3.33034 2.79505L0.97773 3.12282C0.603407 3.18682 0.327998 3.38271 0.217447 3.70661C0.106895 4.03051 0.211629 4.35828 0.494795 4.69188L2.20349 6.43548L1.81947 8.84628C1.75935 9.26327 1.83111 9.59299 2.1104 9.79082C2.36059 9.96731 2.67479 9.96731 3.03748 9.84318L3.0976 9.81797L5.1942 8.69694Z" fill="#FBB723" />
              </g>
              <defs>
                <clipPath id="clip0_3693_14062">
                  <rect width="9.93023" height="9.93023" fill="white" transform="matrix(-1 0 0 1 10.105 0)" />
                </clipPath>
              </defs>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 11 10" fill="none">
              <g clip-path="url(#clip0_3693_14062)">
                <path d="M5.1942 8.69694L7.28498 9.81603L7.33347 9.83737C7.6884 9.97119 8.02393 9.94404 8.278 9.71712C8.51656 9.50377 8.60384 9.19733 8.55341 8.84628L8.2043 6.43355L9.78305 4.77333C10.0585 4.49599 10.1651 4.15463 10.072 3.79777C9.96925 3.40211 9.63565 3.17325 9.15272 3.09761L7.05418 2.78147L5.95642 0.504498C5.78575 0.182542 5.5181 -0.00752932 5.1845 0.000228676C4.85673 0.00992617 4.59683 0.209695 4.39319 0.568502L3.33034 2.79505L0.97773 3.12282C0.603407 3.18682 0.327998 3.38271 0.217447 3.70661C0.106895 4.03051 0.211629 4.35828 0.494795 4.69188L2.20349 6.43548L1.81947 8.84628C1.75935 9.26327 1.83111 9.59299 2.1104 9.79082C2.36059 9.96731 2.67479 9.96731 3.03748 9.84318L3.0976 9.81797L5.1942 8.69694Z" fill="#FBB723" />
              </g>
              <defs>
                <clipPath id="clip0_3693_14062">
                  <rect width="9.93023" height="9.93023" fill="white" transform="matrix(-1 0 0 1 10.105 0)" />
                </clipPath>
              </defs>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 11 10" fill="none">
              <g clip-path="url(#clip0_3693_14062)">
                <path d="M5.1942 8.69694L7.28498 9.81603L7.33347 9.83737C7.6884 9.97119 8.02393 9.94404 8.278 9.71712C8.51656 9.50377 8.60384 9.19733 8.55341 8.84628L8.2043 6.43355L9.78305 4.77333C10.0585 4.49599 10.1651 4.15463 10.072 3.79777C9.96925 3.40211 9.63565 3.17325 9.15272 3.09761L7.05418 2.78147L5.95642 0.504498C5.78575 0.182542 5.5181 -0.00752932 5.1845 0.000228676C4.85673 0.00992617 4.59683 0.209695 4.39319 0.568502L3.33034 2.79505L0.97773 3.12282C0.603407 3.18682 0.327998 3.38271 0.217447 3.70661C0.106895 4.03051 0.211629 4.35828 0.494795 4.69188L2.20349 6.43548L1.81947 8.84628C1.75935 9.26327 1.83111 9.59299 2.1104 9.79082C2.36059 9.96731 2.67479 9.96731 3.03748 9.84318L3.0976 9.81797L5.1942 8.69694Z" fill="#FBB723" />
              </g>
              <defs>
                <clipPath id="clip0_3693_14062">
                  <rect width="9.93023" height="9.93023" fill="white" transform="matrix(-1 0 0 1 10.105 0)" />
                </clipPath>
              </defs>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 11 10" fill="none">
              <g clip-path="url(#clip0_3693_14062)">
                <path d="M5.1942 8.69694L7.28498 9.81603L7.33347 9.83737C7.6884 9.97119 8.02393 9.94404 8.278 9.71712C8.51656 9.50377 8.60384 9.19733 8.55341 8.84628L8.2043 6.43355L9.78305 4.77333C10.0585 4.49599 10.1651 4.15463 10.072 3.79777C9.96925 3.40211 9.63565 3.17325 9.15272 3.09761L7.05418 2.78147L5.95642 0.504498C5.78575 0.182542 5.5181 -0.00752932 5.1845 0.000228676C4.85673 0.00992617 4.59683 0.209695 4.39319 0.568502L3.33034 2.79505L0.97773 3.12282C0.603407 3.18682 0.327998 3.38271 0.217447 3.70661C0.106895 4.03051 0.211629 4.35828 0.494795 4.69188L2.20349 6.43548L1.81947 8.84628C1.75935 9.26327 1.83111 9.59299 2.1104 9.79082C2.36059 9.96731 2.67479 9.96731 3.03748 9.84318L3.0976 9.81797L5.1942 8.69694Z" fill="#FBB723" />
              </g>
              <defs>
                <clipPath id="clip0_3693_14062">
                  <rect width="9.93023" height="9.93023" fill="white" transform="matrix(-1 0 0 1 10.105 0)" />
                </clipPath>
              </defs>
            </svg>
          </div>
                </div>
               
          <div className="date_of_review ">
          03/12/2024
          </div>
                </div>
            <div className="paraofreview" style={{marginTop:"8px"}}>“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do  ipsum dolor sit amet, consectetur adipiscing elit, sed do”</div>
             <img  src={review8} alt="reviewer" />
             <div className="posted">
              Posted on<img src={google} alt="google"></img>
             </div>
            </div></div>
            <div className='Slider_cover '>
            <div className="cover_each_carousel">
                <div className="name_section">
                     <div className="nameofperson">
                     Jefferson 
                </div>
                <div className="startsreview">
            <svg xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 11 10" fill="none">
              <g clip-path="url(#clip0_3693_14062)">
                <path d="M5.1942 8.69694L7.28498 9.81603L7.33347 9.83737C7.6884 9.97119 8.02393 9.94404 8.278 9.71712C8.51656 9.50377 8.60384 9.19733 8.55341 8.84628L8.2043 6.43355L9.78305 4.77333C10.0585 4.49599 10.1651 4.15463 10.072 3.79777C9.96925 3.40211 9.63565 3.17325 9.15272 3.09761L7.05418 2.78147L5.95642 0.504498C5.78575 0.182542 5.5181 -0.00752932 5.1845 0.000228676C4.85673 0.00992617 4.59683 0.209695 4.39319 0.568502L3.33034 2.79505L0.97773 3.12282C0.603407 3.18682 0.327998 3.38271 0.217447 3.70661C0.106895 4.03051 0.211629 4.35828 0.494795 4.69188L2.20349 6.43548L1.81947 8.84628C1.75935 9.26327 1.83111 9.59299 2.1104 9.79082C2.36059 9.96731 2.67479 9.96731 3.03748 9.84318L3.0976 9.81797L5.1942 8.69694Z" fill="#FBB723" />
              </g>
              <defs>
                <clipPath id="clip0_3693_14062">
                  <rect width="9.93023" height="9.93023" fill="white" transform="matrix(-1 0 0 1 10.105 0)" />
                </clipPath>
              </defs>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 11 10" fill="none">
              <g clip-path="url(#clip0_3693_14062)">
                <path d="M5.1942 8.69694L7.28498 9.81603L7.33347 9.83737C7.6884 9.97119 8.02393 9.94404 8.278 9.71712C8.51656 9.50377 8.60384 9.19733 8.55341 8.84628L8.2043 6.43355L9.78305 4.77333C10.0585 4.49599 10.1651 4.15463 10.072 3.79777C9.96925 3.40211 9.63565 3.17325 9.15272 3.09761L7.05418 2.78147L5.95642 0.504498C5.78575 0.182542 5.5181 -0.00752932 5.1845 0.000228676C4.85673 0.00992617 4.59683 0.209695 4.39319 0.568502L3.33034 2.79505L0.97773 3.12282C0.603407 3.18682 0.327998 3.38271 0.217447 3.70661C0.106895 4.03051 0.211629 4.35828 0.494795 4.69188L2.20349 6.43548L1.81947 8.84628C1.75935 9.26327 1.83111 9.59299 2.1104 9.79082C2.36059 9.96731 2.67479 9.96731 3.03748 9.84318L3.0976 9.81797L5.1942 8.69694Z" fill="#FBB723" />
              </g>
              <defs>
                <clipPath id="clip0_3693_14062">
                  <rect width="9.93023" height="9.93023" fill="white" transform="matrix(-1 0 0 1 10.105 0)" />
                </clipPath>
              </defs>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 11 10" fill="none">
              <g clip-path="url(#clip0_3693_14062)">
                <path d="M5.1942 8.69694L7.28498 9.81603L7.33347 9.83737C7.6884 9.97119 8.02393 9.94404 8.278 9.71712C8.51656 9.50377 8.60384 9.19733 8.55341 8.84628L8.2043 6.43355L9.78305 4.77333C10.0585 4.49599 10.1651 4.15463 10.072 3.79777C9.96925 3.40211 9.63565 3.17325 9.15272 3.09761L7.05418 2.78147L5.95642 0.504498C5.78575 0.182542 5.5181 -0.00752932 5.1845 0.000228676C4.85673 0.00992617 4.59683 0.209695 4.39319 0.568502L3.33034 2.79505L0.97773 3.12282C0.603407 3.18682 0.327998 3.38271 0.217447 3.70661C0.106895 4.03051 0.211629 4.35828 0.494795 4.69188L2.20349 6.43548L1.81947 8.84628C1.75935 9.26327 1.83111 9.59299 2.1104 9.79082C2.36059 9.96731 2.67479 9.96731 3.03748 9.84318L3.0976 9.81797L5.1942 8.69694Z" fill="#FBB723" />
              </g>
              <defs>
                <clipPath id="clip0_3693_14062">
                  <rect width="9.93023" height="9.93023" fill="white" transform="matrix(-1 0 0 1 10.105 0)" />
                </clipPath>
              </defs>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 11 10" fill="none">
              <g clip-path="url(#clip0_3693_14062)">
                <path d="M5.1942 8.69694L7.28498 9.81603L7.33347 9.83737C7.6884 9.97119 8.02393 9.94404 8.278 9.71712C8.51656 9.50377 8.60384 9.19733 8.55341 8.84628L8.2043 6.43355L9.78305 4.77333C10.0585 4.49599 10.1651 4.15463 10.072 3.79777C9.96925 3.40211 9.63565 3.17325 9.15272 3.09761L7.05418 2.78147L5.95642 0.504498C5.78575 0.182542 5.5181 -0.00752932 5.1845 0.000228676C4.85673 0.00992617 4.59683 0.209695 4.39319 0.568502L3.33034 2.79505L0.97773 3.12282C0.603407 3.18682 0.327998 3.38271 0.217447 3.70661C0.106895 4.03051 0.211629 4.35828 0.494795 4.69188L2.20349 6.43548L1.81947 8.84628C1.75935 9.26327 1.83111 9.59299 2.1104 9.79082C2.36059 9.96731 2.67479 9.96731 3.03748 9.84318L3.0976 9.81797L5.1942 8.69694Z" fill="#FBB723" />
              </g>
              <defs>
                <clipPath id="clip0_3693_14062">
                  <rect width="9.93023" height="9.93023" fill="white" transform="matrix(-1 0 0 1 10.105 0)" />
                </clipPath>
              </defs>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 11 10" fill="none">
              <g clip-path="url(#clip0_3693_14062)">
                <path d="M5.1942 8.69694L7.28498 9.81603L7.33347 9.83737C7.6884 9.97119 8.02393 9.94404 8.278 9.71712C8.51656 9.50377 8.60384 9.19733 8.55341 8.84628L8.2043 6.43355L9.78305 4.77333C10.0585 4.49599 10.1651 4.15463 10.072 3.79777C9.96925 3.40211 9.63565 3.17325 9.15272 3.09761L7.05418 2.78147L5.95642 0.504498C5.78575 0.182542 5.5181 -0.00752932 5.1845 0.000228676C4.85673 0.00992617 4.59683 0.209695 4.39319 0.568502L3.33034 2.79505L0.97773 3.12282C0.603407 3.18682 0.327998 3.38271 0.217447 3.70661C0.106895 4.03051 0.211629 4.35828 0.494795 4.69188L2.20349 6.43548L1.81947 8.84628C1.75935 9.26327 1.83111 9.59299 2.1104 9.79082C2.36059 9.96731 2.67479 9.96731 3.03748 9.84318L3.0976 9.81797L5.1942 8.69694Z" fill="#FBB723" />
              </g>
              <defs>
                <clipPath id="clip0_3693_14062">
                  <rect width="9.93023" height="9.93023" fill="white" transform="matrix(-1 0 0 1 10.105 0)" />
                </clipPath>
              </defs>
            </svg>
          </div>
          <div className="date_of_review ">
          10/02/2024
          </div>
                </div>
            <div className="paraofreview" style={{marginTop:"8px"}}>“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do  ipsum dolor sit amet, consectetur adipiscing elit, sed do”</div>
             <img  src={review1} alt="reviewer"/>
             <div className="posted">
              Posted on<img src={google} alt="google"></img>
             </div>
            </div></div>
            <div className='Slider_cover '>

            <div className="cover_each_carousel">
                <div className="name_section">
                     <div className="nameofperson">
                     Austin   <div className="startsreview">
            <svg xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 11 10" fill="none">
              <g clip-path="url(#clip0_3693_14062)">
                <path d="M5.1942 8.69694L7.28498 9.81603L7.33347 9.83737C7.6884 9.97119 8.02393 9.94404 8.278 9.71712C8.51656 9.50377 8.60384 9.19733 8.55341 8.84628L8.2043 6.43355L9.78305 4.77333C10.0585 4.49599 10.1651 4.15463 10.072 3.79777C9.96925 3.40211 9.63565 3.17325 9.15272 3.09761L7.05418 2.78147L5.95642 0.504498C5.78575 0.182542 5.5181 -0.00752932 5.1845 0.000228676C4.85673 0.00992617 4.59683 0.209695 4.39319 0.568502L3.33034 2.79505L0.97773 3.12282C0.603407 3.18682 0.327998 3.38271 0.217447 3.70661C0.106895 4.03051 0.211629 4.35828 0.494795 4.69188L2.20349 6.43548L1.81947 8.84628C1.75935 9.26327 1.83111 9.59299 2.1104 9.79082C2.36059 9.96731 2.67479 9.96731 3.03748 9.84318L3.0976 9.81797L5.1942 8.69694Z" fill="#FBB723" />
              </g>
              <defs>
                <clipPath id="clip0_3693_14062">
                  <rect width="9.93023" height="9.93023" fill="white" transform="matrix(-1 0 0 1 10.105 0)" />
                </clipPath>
              </defs>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 11 10" fill="none">
              <g clip-path="url(#clip0_3693_14062)">
                <path d="M5.1942 8.69694L7.28498 9.81603L7.33347 9.83737C7.6884 9.97119 8.02393 9.94404 8.278 9.71712C8.51656 9.50377 8.60384 9.19733 8.55341 8.84628L8.2043 6.43355L9.78305 4.77333C10.0585 4.49599 10.1651 4.15463 10.072 3.79777C9.96925 3.40211 9.63565 3.17325 9.15272 3.09761L7.05418 2.78147L5.95642 0.504498C5.78575 0.182542 5.5181 -0.00752932 5.1845 0.000228676C4.85673 0.00992617 4.59683 0.209695 4.39319 0.568502L3.33034 2.79505L0.97773 3.12282C0.603407 3.18682 0.327998 3.38271 0.217447 3.70661C0.106895 4.03051 0.211629 4.35828 0.494795 4.69188L2.20349 6.43548L1.81947 8.84628C1.75935 9.26327 1.83111 9.59299 2.1104 9.79082C2.36059 9.96731 2.67479 9.96731 3.03748 9.84318L3.0976 9.81797L5.1942 8.69694Z" fill="#FBB723" />
              </g>
              <defs>
                <clipPath id="clip0_3693_14062">
                  <rect width="9.93023" height="9.93023" fill="white" transform="matrix(-1 0 0 1 10.105 0)" />
                </clipPath>
              </defs>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 11 10" fill="none">
              <g clip-path="url(#clip0_3693_14062)">
                <path d="M5.1942 8.69694L7.28498 9.81603L7.33347 9.83737C7.6884 9.97119 8.02393 9.94404 8.278 9.71712C8.51656 9.50377 8.60384 9.19733 8.55341 8.84628L8.2043 6.43355L9.78305 4.77333C10.0585 4.49599 10.1651 4.15463 10.072 3.79777C9.96925 3.40211 9.63565 3.17325 9.15272 3.09761L7.05418 2.78147L5.95642 0.504498C5.78575 0.182542 5.5181 -0.00752932 5.1845 0.000228676C4.85673 0.00992617 4.59683 0.209695 4.39319 0.568502L3.33034 2.79505L0.97773 3.12282C0.603407 3.18682 0.327998 3.38271 0.217447 3.70661C0.106895 4.03051 0.211629 4.35828 0.494795 4.69188L2.20349 6.43548L1.81947 8.84628C1.75935 9.26327 1.83111 9.59299 2.1104 9.79082C2.36059 9.96731 2.67479 9.96731 3.03748 9.84318L3.0976 9.81797L5.1942 8.69694Z" fill="#FBB723" />
              </g>
              <defs>
                <clipPath id="clip0_3693_14062">
                  <rect width="9.93023" height="9.93023" fill="white" transform="matrix(-1 0 0 1 10.105 0)" />
                </clipPath>
              </defs>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 11 10" fill="none">
              <g clip-path="url(#clip0_3693_14062)">
                <path d="M5.1942 8.69694L7.28498 9.81603L7.33347 9.83737C7.6884 9.97119 8.02393 9.94404 8.278 9.71712C8.51656 9.50377 8.60384 9.19733 8.55341 8.84628L8.2043 6.43355L9.78305 4.77333C10.0585 4.49599 10.1651 4.15463 10.072 3.79777C9.96925 3.40211 9.63565 3.17325 9.15272 3.09761L7.05418 2.78147L5.95642 0.504498C5.78575 0.182542 5.5181 -0.00752932 5.1845 0.000228676C4.85673 0.00992617 4.59683 0.209695 4.39319 0.568502L3.33034 2.79505L0.97773 3.12282C0.603407 3.18682 0.327998 3.38271 0.217447 3.70661C0.106895 4.03051 0.211629 4.35828 0.494795 4.69188L2.20349 6.43548L1.81947 8.84628C1.75935 9.26327 1.83111 9.59299 2.1104 9.79082C2.36059 9.96731 2.67479 9.96731 3.03748 9.84318L3.0976 9.81797L5.1942 8.69694Z" fill="#FBB723" />
              </g>
              <defs>
                <clipPath id="clip0_3693_14062">
                  <rect width="9.93023" height="9.93023" fill="white" transform="matrix(-1 0 0 1 10.105 0)" />
                </clipPath>
              </defs>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 11 10" fill="none">
              <g clip-path="url(#clip0_3693_14062)">
                <path d="M5.1942 8.69694L7.28498 9.81603L7.33347 9.83737C7.6884 9.97119 8.02393 9.94404 8.278 9.71712C8.51656 9.50377 8.60384 9.19733 8.55341 8.84628L8.2043 6.43355L9.78305 4.77333C10.0585 4.49599 10.1651 4.15463 10.072 3.79777C9.96925 3.40211 9.63565 3.17325 9.15272 3.09761L7.05418 2.78147L5.95642 0.504498C5.78575 0.182542 5.5181 -0.00752932 5.1845 0.000228676C4.85673 0.00992617 4.59683 0.209695 4.39319 0.568502L3.33034 2.79505L0.97773 3.12282C0.603407 3.18682 0.327998 3.38271 0.217447 3.70661C0.106895 4.03051 0.211629 4.35828 0.494795 4.69188L2.20349 6.43548L1.81947 8.84628C1.75935 9.26327 1.83111 9.59299 2.1104 9.79082C2.36059 9.96731 2.67479 9.96731 3.03748 9.84318L3.0976 9.81797L5.1942 8.69694Z" fill="#FBB723" />
              </g>
              <defs>
                <clipPath id="clip0_3693_14062">
                  <rect width="9.93023" height="9.93023" fill="white" transform="matrix(-1 0 0 1 10.105 0)" />
                </clipPath>
              </defs>
            </svg>
          </div>
                </div>
              
          <div className="date_of_review ">
          03/05/2024
          </div>
                </div>
            <div className="paraofreview" style={{marginTop:"8px"}}>“A friend recommended United ELDT, and it was truly a smart decision. Other companies offer courses at almost $200”</div>
             <img  src={review5} alt="reviewer"/>
             <div className="posted">
              Posted on<img src={google} alt="google"></img>
             </div>
            </div></div>
            <div className='Slider_cover '>

            <div className="cover_each_carousel ">
                <div className="name_section">
                     <div className="nameofperson">
                     Jefferson  <div className="startsreview">
            <svg xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 11 10" fill="none">
              <g clip-path="url(#clip0_3693_14062)">
                <path d="M5.1942 8.69694L7.28498 9.81603L7.33347 9.83737C7.6884 9.97119 8.02393 9.94404 8.278 9.71712C8.51656 9.50377 8.60384 9.19733 8.55341 8.84628L8.2043 6.43355L9.78305 4.77333C10.0585 4.49599 10.1651 4.15463 10.072 3.79777C9.96925 3.40211 9.63565 3.17325 9.15272 3.09761L7.05418 2.78147L5.95642 0.504498C5.78575 0.182542 5.5181 -0.00752932 5.1845 0.000228676C4.85673 0.00992617 4.59683 0.209695 4.39319 0.568502L3.33034 2.79505L0.97773 3.12282C0.603407 3.18682 0.327998 3.38271 0.217447 3.70661C0.106895 4.03051 0.211629 4.35828 0.494795 4.69188L2.20349 6.43548L1.81947 8.84628C1.75935 9.26327 1.83111 9.59299 2.1104 9.79082C2.36059 9.96731 2.67479 9.96731 3.03748 9.84318L3.0976 9.81797L5.1942 8.69694Z" fill="#FBB723" />
              </g>
              <defs>
                <clipPath id="clip0_3693_14062">
                  <rect width="9.93023" height="9.93023" fill="white" transform="matrix(-1 0 0 1 10.105 0)" />
                </clipPath>
              </defs>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 11 10" fill="none">
              <g clip-path="url(#clip0_3693_14062)">
                <path d="M5.1942 8.69694L7.28498 9.81603L7.33347 9.83737C7.6884 9.97119 8.02393 9.94404 8.278 9.71712C8.51656 9.50377 8.60384 9.19733 8.55341 8.84628L8.2043 6.43355L9.78305 4.77333C10.0585 4.49599 10.1651 4.15463 10.072 3.79777C9.96925 3.40211 9.63565 3.17325 9.15272 3.09761L7.05418 2.78147L5.95642 0.504498C5.78575 0.182542 5.5181 -0.00752932 5.1845 0.000228676C4.85673 0.00992617 4.59683 0.209695 4.39319 0.568502L3.33034 2.79505L0.97773 3.12282C0.603407 3.18682 0.327998 3.38271 0.217447 3.70661C0.106895 4.03051 0.211629 4.35828 0.494795 4.69188L2.20349 6.43548L1.81947 8.84628C1.75935 9.26327 1.83111 9.59299 2.1104 9.79082C2.36059 9.96731 2.67479 9.96731 3.03748 9.84318L3.0976 9.81797L5.1942 8.69694Z" fill="#FBB723" />
              </g>
              <defs>
                <clipPath id="clip0_3693_14062">
                  <rect width="9.93023" height="9.93023" fill="white" transform="matrix(-1 0 0 1 10.105 0)" />
                </clipPath>
              </defs>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 11 10" fill="none">
              <g clip-path="url(#clip0_3693_14062)">
                <path d="M5.1942 8.69694L7.28498 9.81603L7.33347 9.83737C7.6884 9.97119 8.02393 9.94404 8.278 9.71712C8.51656 9.50377 8.60384 9.19733 8.55341 8.84628L8.2043 6.43355L9.78305 4.77333C10.0585 4.49599 10.1651 4.15463 10.072 3.79777C9.96925 3.40211 9.63565 3.17325 9.15272 3.09761L7.05418 2.78147L5.95642 0.504498C5.78575 0.182542 5.5181 -0.00752932 5.1845 0.000228676C4.85673 0.00992617 4.59683 0.209695 4.39319 0.568502L3.33034 2.79505L0.97773 3.12282C0.603407 3.18682 0.327998 3.38271 0.217447 3.70661C0.106895 4.03051 0.211629 4.35828 0.494795 4.69188L2.20349 6.43548L1.81947 8.84628C1.75935 9.26327 1.83111 9.59299 2.1104 9.79082C2.36059 9.96731 2.67479 9.96731 3.03748 9.84318L3.0976 9.81797L5.1942 8.69694Z" fill="#FBB723" />
              </g>
              <defs>
                <clipPath id="clip0_3693_14062">
                  <rect width="9.93023" height="9.93023" fill="white" transform="matrix(-1 0 0 1 10.105 0)" />
                </clipPath>
              </defs>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 11 10" fill="none">
              <g clip-path="url(#clip0_3693_14062)">
                <path d="M5.1942 8.69694L7.28498 9.81603L7.33347 9.83737C7.6884 9.97119 8.02393 9.94404 8.278 9.71712C8.51656 9.50377 8.60384 9.19733 8.55341 8.84628L8.2043 6.43355L9.78305 4.77333C10.0585 4.49599 10.1651 4.15463 10.072 3.79777C9.96925 3.40211 9.63565 3.17325 9.15272 3.09761L7.05418 2.78147L5.95642 0.504498C5.78575 0.182542 5.5181 -0.00752932 5.1845 0.000228676C4.85673 0.00992617 4.59683 0.209695 4.39319 0.568502L3.33034 2.79505L0.97773 3.12282C0.603407 3.18682 0.327998 3.38271 0.217447 3.70661C0.106895 4.03051 0.211629 4.35828 0.494795 4.69188L2.20349 6.43548L1.81947 8.84628C1.75935 9.26327 1.83111 9.59299 2.1104 9.79082C2.36059 9.96731 2.67479 9.96731 3.03748 9.84318L3.0976 9.81797L5.1942 8.69694Z" fill="#FBB723" />
              </g>
              <defs>
                <clipPath id="clip0_3693_14062">
                  <rect width="9.93023" height="9.93023" fill="white" transform="matrix(-1 0 0 1 10.105 0)" />
                </clipPath>
              </defs>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 11 10" fill="none">
              <g clip-path="url(#clip0_3693_14062)">
                <path d="M5.1942 8.69694L7.28498 9.81603L7.33347 9.83737C7.6884 9.97119 8.02393 9.94404 8.278 9.71712C8.51656 9.50377 8.60384 9.19733 8.55341 8.84628L8.2043 6.43355L9.78305 4.77333C10.0585 4.49599 10.1651 4.15463 10.072 3.79777C9.96925 3.40211 9.63565 3.17325 9.15272 3.09761L7.05418 2.78147L5.95642 0.504498C5.78575 0.182542 5.5181 -0.00752932 5.1845 0.000228676C4.85673 0.00992617 4.59683 0.209695 4.39319 0.568502L3.33034 2.79505L0.97773 3.12282C0.603407 3.18682 0.327998 3.38271 0.217447 3.70661C0.106895 4.03051 0.211629 4.35828 0.494795 4.69188L2.20349 6.43548L1.81947 8.84628C1.75935 9.26327 1.83111 9.59299 2.1104 9.79082C2.36059 9.96731 2.67479 9.96731 3.03748 9.84318L3.0976 9.81797L5.1942 8.69694Z" fill="#FBB723" />
              </g>
              <defs>
                <clipPath id="clip0_3693_14062">
                  <rect width="9.93023" height="9.93023" fill="white" transform="matrix(-1 0 0 1 10.105 0)" />
                </clipPath>
              </defs>
            </svg>
          </div>
                </div>
               
          <div className="date_of_review ">
          03/12/2024
          </div>
                </div>
            <div className="paraofreview" style={{marginTop:"8px"}}>"Este curso fue el mejor que encontré. Pagué 279 dólares en un sitio, lo peor, todo en inglés.” Thank YOU United ELDT
</div>
             <img  src={review0} alt="reviewer"/>
             <div className="posted">
              Posted on<img src={google} alt="google"></img>
             </div>
            </div>      
      </div>
      
      </Slider>
      </div>
      
     )
        } 
        </>
    )
}
export default ReviewSliderforhome