import React from 'react';
import './Loader.css'; // Import CSS for styling

const Loader = () => {
  return (
    <div className='logocon'>
      <div className='maincontainer'>
      <div class="custom-loader"></div>


    </div>
    
    </div>
  
  );
};

export default Loader;
