import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import PopularCourses from "../../components/Home/PopularCourses";
import ClassA from "./ClassA";
import TitleCarousel from "./TitleCaoursel";
import ReviewSlider from "./ReviewSlider";
import MobileHeader from "./MobileHeader";
import Navbar from "../../components/Navbar";

import ClassBSpanishSpanish from "./ClassBSpanish";
import Disclaimer from "../../components/Home/Disclaimer";
import Footer from "../../components/Footer";


function Homeofclassa() {
  const languageState = useSelector((state) => state.language);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div className="coverformobileversionofclassa">
      <br></br><br></br>

      <Navbar />
      {
        screenWidth <= 500 ? (<>
          <MobileHeader showCancelButton={true} />
          <div className="coverofrevi">

            <ReviewSlider />
          </div>


          <TitleCarousel />
          <div className="coverofdis">
            <Disclaimer />
          </div>

          <div className="mobile_cards">
            <PopularCourses language={languageState.language.value} showCancelButton={true} />
            <ClassA />
          </div>
          <Footer />
        </>) : (<>
          <ClassBSpanishSpanish />
          <Footer />



        </>)
      }




    </div>
  )
}
export default Homeofclassa;