import React, { useState, useEffect, useRef } from "react";
import Banner from "../components/Home/Banner";
import { useSelector } from "react-redux";
import Disclaimer from "../components/Home/Disclaimer";
import PopularCourses from "../components/Home/PopularCourses";
import HomeAboutUs from "../components/Home/HomeAboutUs";
import ReviewSliderforhome from "./Reviewsliderforhome";


export default function HomeMain() {
  const languageState = useSelector((state) => state.language);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    // Clean up the event listener
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (

    <>

      <Banner language={languageState.language.value} />
      <div >
          <PopularCourses id="targetSection" language={languageState.language.value} showCancelButton={true} />
      </div>


      <HomeAboutUs language={languageState.language.value} />
      {
  windowWidth > 500 ?(
      <Disclaimer language={languageState.language.value} />):(null)}
      <div className="homereview">
 
<ReviewSliderforhome/>

      </div>
<div className="coverofdisclaimer">
  {
  windowWidth > 500 ?(
     null):( <Disclaimer language={languageState.language.value} />)}
</div>

      {/* <NewsLetter language={languageState.language.value} /> */}
    </>

  );
}
