import React from "react";
import mainimage from "./Group 6804.png"
export default function RegisterForm() {


  return (
    <>
     <div className="main-image-container">
      <img src={mainimage} alt="login"/>
     </div>
    </>
  );
}
