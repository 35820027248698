import gsap from "gsap";
import * as React from "react";
const PopupSvgComponent = (props) => {
  const popupContainer = React.useRef(null);

  React.useEffect(() => {
    const popUpAnimation = gsap.timeline({ repeat: -1, repeatDelay: 1 });
    popUpAnimation
      .fromTo(
        "#complete-tick",
        { opacity: 0, y: 30, scale: 0 },
        { duration: 1, y: 0, opacity: 1, scale: 1, ease: "elastic.out(1,0.7)" }
      )
      .fromTo(
        "#tick",
        { strokeDasharray: 45, strokeDashoffset: 45 },
        {
          strokeDasharray: 45,
          strokeDashoffset: 0,
          duration: 0.3,
          ease: "none",
        },
        "<"
      )
      .fromTo(
        "#pop1",
        { opacity: 0, y: 30, scale: 0 },
        { duration: 1, y: 0, scale: 1, opacity: 1, ease: "elastic.out(1,0.7)" }
      )
      .fromTo(
        "#pop1s1",
        { strokeDasharray: "100% 100%" },
        { strokeDasharray: "0% 100%", duration: 1 },
        "<"
      )
      .fromTo(
        "#pop1s2",
        { strokeDasharray: "100% 100%", duration: 1 },
        { strokeDasharray: "0 100%" }
      )
      //   .to("#complete-tick", { y: -50 })
      //   .to("#pop1", { y: -50 }, "<")
      .fromTo(
        "#complete-tick",
        { opacity: 1, scale: 1 },
        { opacity: 0, scale: 0, duration: 1.3, ease: "elastic.out(1,0.7)" },
        "<"
      )
      .fromTo(
        "#popup-svg",
        { y: 0 },
        { y: -50, duration: 1.3, ease: "elastic.out(1,0.7)" },
        "<"
      )
      .fromTo(
        "#pop2",
        { delay: 1.5, opacity: 0, y: 30, scale: 0 },
        { duration: 1, y: 0, scale: 1, opacity: 1, ease: "elastic.out(1,0.7)" }
      )
      .fromTo(
        "#pop2s1",
        { strokeDasharray: "100% 100%" },
        { strokeDasharray: "0% 100%", duration: 1 },
        "<"
      )
      .fromTo(
        "#pop2s2",
        { strokeDasharray: "100% 100%" },
        { strokeDasharray: "0% 100%", duration: 1 }
      )
      .fromTo(
        "#pop3",
        { delay: 1.5, opacity: 0, y: 30, scale: 0 },
        { duration: 1, y: 0, scale: 1, opacity: 1, ease: "elastic.out(1,0.7)" }
      )
      .fromTo(
        "#pop3s1",
        { strokeDasharray: "100% 100%" },
        { strokeDasharray: "0% 100%", duration: 1 },
        "<"
      )
      .to(["#pop1"], {
        delay: 1.5,
        y: "-50px",
        duration: 0.3,
      })
      .to(["#pop1"], {
        opacity: 0,
        duration: 0.3,
      })
      .to(
        ["#pop2"],
        {
          y: "-130px",
          duration: 0.3,
        },
        "<"
      )
      .to(["#pop2"], {
        opacity: 0,
        duration: 0.3,
      })
      .to(
        ["#pop3"],
        {
          y: "-210px",
          duration: 0.3,
        },
        "<"
      )
      .to(["#pop3"], {
        opacity: 0,
        duration: 0.3,
      });

    return () => {
      popUpAnimation.kill();
    };
  }, []);

  return (
    <div className="svg-pop-container">
      <svg
        ref={popupContainer}
        id="popup-svg"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 242.34 310.48"
        {...props}
      >
        <style>
          {`
          .popup-1{fill:#28d182;}.popup-2,.popup-5{fill:none;}.popup-2{stroke:#fff;stroke-linecap:round;stroke-linejoin:round;stroke-width:7px;}.popup-3{fill:#eae8e9;}.popup-4{fill:#4d4d4d;}.popup-5{stroke:#eae8e9;stroke-miterlimit:10;stroke-width:22px;}
        `}
        </style>

        <g id="Layer_2" data-name="Layer 2">
          <g id="Layer_1-2" data-name="Layer 1">
            <g id="complete-tick">
              <path
                className="popup-1"
                d="M121.08,0a33,33,0,1,0,33,33A33,33,0,0,0,121.08,0Z"
              />
              <path
                id="tick"
                class="popup-2"
                d="M 105.83, 33.61 116, 43.78 136.35, 23.43"
                fill="none"
              />
            </g>
            <g id="pop1">
              <rect
                className="popup-3"
                y={92.5}
                width={242.34}
                height={69.29}
                rx={11.58}
              />
              <path
                className="popup-4"
                d="M29,114.67a4.64,4.64,0,0,1-4.86,3.62c-3.65,0-5.49-2.77-5.49-6.23s1.85-6.31,5.56-6.31c2.87,0,4.44,1.64,4.8,3.68H27.34a3.09,3.09,0,0,0-3.25-2.33c-2.69,0-3.79,2.45-3.79,4.92s1,4.92,3.86,4.92a3.14,3.14,0,0,0,3.17-2.27Z"
              />
              <path
                className="popup-4"
                d="M39.07,113.67c0,2.56-1.47,4.67-4.19,4.67-2.55,0-4.1-2-4.1-4.64S32.29,109,35,109C37.45,109,39.07,110.87,39.07,113.67Zm-6.7,0c0,2,1,3.38,2.57,3.38s2.54-1.35,2.54-3.37-.91-3.38-2.57-3.38S32.37,111.65,32.37,113.68Z"
              />
              <path
                className="popup-4"
                d="M41.42,111.53c0-.77,0-1.57,0-2.3h1.48c0,.27.06.93.06,1.37A2.75,2.75,0,0,1,45.54,109a2.44,2.44,0,0,1,2.46,1.6,3,3,0,0,1,2.83-1.6c1.45,0,2.81.86,2.81,3.27v5.84H52.13v-5.66c0-1-.37-2.12-1.76-2.12s-2.08,1.1-2.08,2.67v5.11H46.78v-5.61c0-1.14-.32-2.16-1.72-2.16S43,111.51,43,113.21v4.92H41.42Z"
              />
              <path
                className="popup-4"
                d="M58.16,117v4.6H56.63V111.41c0-.73,0-1.48,0-2.18H58.1a12.58,12.58,0,0,1,.06,1.4A3.08,3.08,0,0,1,61.07,109c2,0,3.55,1.73,3.55,4.44,0,3.17-1.72,4.88-3.87,4.88A2.7,2.7,0,0,1,58.16,117ZM63,113.52c0-1.89-.86-3.18-2.38-3.18-1.85,0-2.57,1.16-2.57,3.33s.59,3.38,2.45,3.38C62.17,117.05,63,115.71,63,113.52Z"
              />
              <path className="popup-4" d="M67,118.13V105.25h1.54v12.88Z" />
              <path
                className="popup-4"
                d="M72.42,114c0,1.81.92,3.1,2.43,3.1A2.06,2.06,0,0,0,77,115.68h1.54a3.61,3.61,0,0,1-3.74,2.66c-2.8,0-3.94-2.27-3.94-4.56,0-2.65,1.34-4.76,4-4.76,2.87,0,3.81,2.31,3.81,4.2,0,.28,0,.5,0,.74Zm4.73-1.08c0-1.46-.75-2.64-2.27-2.64s-2.26,1.09-2.41,2.64Z"
              />
              <path
                className="popup-4"
                d="M80,109.23h1.54v-2.62h1.54v2.62h2v1.26h-2v5.21c0,.86.21,1.31,1,1.31a3,3,0,0,0,.78-.08v1.16a4.19,4.19,0,0,1-1.33.17c-1.38,0-2-.72-2-2.2v-5.57H80Z"
              />
              <path
                className="popup-4"
                d="M87.86,114c0,1.81.93,3.1,2.44,3.1a2.07,2.07,0,0,0,2.15-1.38H94a3.61,3.61,0,0,1-3.74,2.66c-2.8,0-3.94-2.27-3.94-4.56,0-2.65,1.33-4.76,4-4.76,2.87,0,3.81,2.31,3.81,4.2,0,.28,0,.5,0,.74Zm4.74-1.08c0-1.46-.75-2.64-2.27-2.64s-2.26,1.09-2.41,2.64Z"
              />
              <path
                className="popup-4"
                d="M101.73,109.23c1.44,4.27,2.23,6.63,2.46,7.53h0c.29-1,.92-3,2.38-7.53h1.55l-3.2,9.31c-.91,2.61-1.58,3.27-3.4,3.27a8.49,8.49,0,0,1-1-.06v-1.34a5.58,5.58,0,0,0,.73,0c1.13,0,1.56-.5,2.08-2l-3.33-9.25Z"
              />
              <path
                className="popup-4"
                d="M117.66,113.67c0,2.56-1.47,4.67-4.19,4.67-2.55,0-4.09-2-4.09-4.64s1.51-4.68,4.19-4.68C116,109,117.66,110.87,117.66,113.67Zm-6.7,0c0,2,1,3.38,2.57,3.38s2.55-1.35,2.55-3.37-.91-3.38-2.58-3.38S111,111.65,111,113.68Z"
              />
              <path
                className="popup-4"
                d="M127.16,115.54c0,.87,0,2.07,0,2.59h-1.5c0-.23-.05-.77-.06-1.33a2.64,2.64,0,0,1-2.69,1.54c-1.5,0-2.94-.74-2.94-3.25v-5.86h1.52v5.53c0,1.17.37,2.24,1.85,2.24s2.26-.9,2.26-3.13v-4.64h1.54Z"
              />
              <path
                className="popup-4"
                d="M130.15,111.65c0-1.24,0-1.94,0-2.42h1.5c0,.25.05,1,.05,1.82a3.17,3.17,0,0,1,3-2v1.54c-1.82,0-3,1-3,3.25v4.32h-1.53Z"
              />
              <path
                className="popup-4"
                d="M149.42,112.43h-6.07v4.32H150l-.21,1.38h-8.05V105.92h8v1.39h-6.36V111h6.07Z"
              />
              <path
                className="popup-4"
                d="M152.58,105.92h1.62v10.83h6.36l-.22,1.38h-7.76Z"
              />
              <path
                className="popup-4"
                d="M162.67,105.92H167c3.43,0,5.76,2.34,5.76,6s-2.27,6.23-5.81,6.23h-4.31Zm1.62,10.84h2.56c2.87,0,4.24-2.06,4.24-4.82,0-2.41-1.25-4.64-4.23-4.64h-2.57Z"
              />
              <path
                className="popup-4"
                d="M177.87,107.31h-3.94v-1.39h9.52v1.39H179.5v10.82h-1.63Z"
              />
              <path
                className="popup-4"
                d="M200,114.67a4.65,4.65,0,0,1-4.86,3.62c-3.65,0-5.49-2.77-5.49-6.23s1.84-6.31,5.55-6.31c2.88,0,4.44,1.64,4.8,3.68h-1.62a3.09,3.09,0,0,0-3.25-2.33c-2.69,0-3.79,2.45-3.79,4.92s1.05,4.92,3.86,4.92a3.13,3.13,0,0,0,3.17-2.27Z"
              />
              <path
                className="popup-4"
                d="M202.75,105.92h4.36c3.43,0,5.75,2.34,5.75,6s-2.26,6.23-5.8,6.23h-4.31Zm1.62,10.84h2.56c2.87,0,4.24-2.06,4.24-4.82,0-2.41-1.25-4.64-4.24-4.64h-2.56Z"
              />
              <path
                className="popup-4"
                d="M215.76,105.92h1.61v10.83h6.37l-.22,1.38h-7.76Z"
              />
              <path
                className="popup-4"
                d="M61.89,136.48h1.54v-2.63H65v2.63h2v1.25H65v5.21c0,.87.21,1.31,1,1.31a3.59,3.59,0,0,0,.78-.07v1.15a3.92,3.92,0,0,1-1.33.17c-1.39,0-2-.72-2-2.2v-5.57H61.89Z"
              />
              <path
                className="popup-4"
                d="M70.45,132.49v5.3a3,3,0,0,1,2.78-1.52c1.42,0,3,.78,3,3.43v5.68H74.7V140c0-1.51-.65-2.35-1.94-2.35-1.6,0-2.31,1.06-2.31,2.91v4.87H68.91V132.49Z"
              />
              <path
                className="popup-4"
                d="M80.12,141.21c0,1.81.92,3.09,2.43,3.09a2,2,0,0,0,2.15-1.37h1.55a3.61,3.61,0,0,1-3.74,2.66c-2.8,0-3.94-2.28-3.94-4.57,0-2.64,1.33-4.75,4-4.75,2.87,0,3.81,2.3,3.81,4.2a5.86,5.86,0,0,1,0,.74Zm4.74-1.09c0-1.46-.76-2.64-2.28-2.64s-2.25,1.09-2.41,2.64Z"
              />
              <path
                className="popup-4"
                d="M96.49,140.91c0,2.56-1.47,4.68-4.19,4.68-2.55,0-4.09-2-4.09-4.65s1.51-4.67,4.19-4.67C94.87,136.27,96.49,138.12,96.49,140.91Zm-6.7,0c0,1.95,1,3.37,2.57,3.37s2.55-1.35,2.55-3.37-.92-3.38-2.58-3.38S89.79,138.9,89.79,140.93Z"
              />
              <path
                className="popup-4"
                d="M98.85,138.9c0-1.25,0-2,0-2.42h1.5c0,.25,0,1,0,1.81a3.15,3.15,0,0,1,3-2v1.53c-1.83,0-3,1-3,3.26v4.32H98.85Z"
              />
              <path
                className="popup-4"
                d="M106.43,136.48c1.44,4.26,2.23,6.62,2.47,7.53h0c.29-1,.93-3,2.38-7.53h1.55l-3.2,9.31c-.9,2.6-1.57,3.26-3.39,3.26a8.51,8.51,0,0,1-1-.06v-1.34a5.87,5.87,0,0,0,.74,0c1.12,0,1.55-.5,2.08-2l-3.34-9.24Z"
              />
              <path
                className="popup-4"
                d="M120.24,136.48c1.1,4.17,1.73,6.56,1.88,7.47h0c.16-.82.79-3,2-7.47h1.5c1.39,5,1.81,6.5,2,7.3h0c.23-.95.58-2.29,2-7.3h1.56l-2.74,8.9h-1.67c-.86-3.25-1.72-6.25-1.9-7.22h0c-.17,1-1,3.68-2,7.22h-1.72l-2.58-8.9Z"
              />
              <path
                className="popup-4"
                d="M133.24,132.49h1.53v1.92h-1.53Zm0,4h1.53v8.9h-1.53Z"
              />
              <path
                className="popup-4"
                d="M136.69,136.48h1.54v-2.63h1.54v2.63h2v1.25h-2v5.21c0,.87.21,1.31,1,1.31a3.54,3.54,0,0,0,.78-.07v1.15a3.92,3.92,0,0,1-1.33.17c-1.39,0-2-.72-2-2.2v-5.57h-1.54Z"
              />
              <path
                className="popup-4"
                d="M145.25,132.49v5.3a3,3,0,0,1,2.78-1.52c1.42,0,3,.78,3,3.43v5.68H149.5V140c0-1.51-.64-2.35-1.94-2.35-1.6,0-2.31,1.06-2.31,2.91v4.87h-1.53V132.49Z"
              />
              <path
                className="popup-4"
                d="M165.94,142.78c0,.87,0,2.07,0,2.6h-1.49a13.12,13.12,0,0,1-.06-1.34,2.64,2.64,0,0,1-2.69,1.55c-1.5,0-2.94-.75-2.94-3.26v-5.85h1.52V142c0,1.17.37,2.25,1.84,2.25,1.64,0,2.27-.91,2.27-3.13v-4.64h1.54Z"
              />
              <path
                className="popup-4"
                d="M169.55,142.83a2,2,0,0,0,2.18,1.55c1.3,0,1.82-.57,1.82-1.38s-.41-1.21-2.11-1.63c-2.54-.62-3.07-1.39-3.07-2.6s.92-2.5,3.21-2.5,3.24,1.33,3.35,2.57h-1.48a1.76,1.76,0,0,0-1.94-1.38c-1.24,0-1.61.6-1.61,1.18s.36,1,2,1.39c2.7.64,3.24,1.54,3.24,2.86,0,1.56-1.23,2.7-3.45,2.7s-3.43-1.17-3.66-2.76Z"
              />
              <path className="popup-4" d="M177.36,145.38v-2.6H179v2.6Z" />
              <line
                id="pop1s1"
                className="popup-5"
                x1={229.99}
                y1={112.03}
                x2={15.4}
                y2={112.03}
              />
              <line
                id="pop1s2"
                className="popup-5"
                x1={185.76}
                y1={139.68}
                x2={54.77}
                y2={139.68}
              />
            </g>
            <g id="pop2">
              <rect
                className="popup-3"
                y={180.06}
                width={242.34}
                height={69.29}
                rx={11.58}
              />
              <path
                className="popup-4"
                d="M41.61,202l-1.31,3.66H38.69L43,193.48h2l4.53,12.21H47.84L46.48,202Zm4.46-1.39c-1.17-3.21-1.81-4.91-2.07-5.84h0c-.3,1-1,3.1-1.95,5.84Z"
              />
              <path
                className="popup-4"
                d="M58.59,203a3.52,3.52,0,0,1-3.67,2.93c-2.45,0-4-1.76-4-4.61,0-2.51,1.42-4.71,4.08-4.71a3.32,3.32,0,0,1,3.56,3H57.07A2,2,0,0,0,55,197.87c-1.58,0-2.47,1.35-2.47,3.37s.87,3.38,2.41,3.38A2.1,2.1,0,0,0,57.06,203Z"
              />
              <path
                className="popup-4"
                d="M67.91,203a3.52,3.52,0,0,1-3.67,2.93c-2.45,0-4-1.76-4-4.61,0-2.51,1.42-4.71,4.08-4.71a3.32,3.32,0,0,1,3.56,3H66.39a2,2,0,0,0-2.07-1.69c-1.58,0-2.47,1.35-2.47,3.37s.87,3.38,2.41,3.38A2.12,2.12,0,0,0,66.39,203Z"
              />
              <path
                className="popup-4"
                d="M71.13,201.52c0,1.81.92,3.1,2.44,3.1a2,2,0,0,0,2.14-1.38h1.55a3.61,3.61,0,0,1-3.74,2.66c-2.8,0-3.94-2.27-3.94-4.56,0-2.65,1.33-4.76,4-4.76,2.87,0,3.81,2.31,3.81,4.2a5.78,5.78,0,0,1,0,.74Zm4.74-1.08c0-1.46-.76-2.64-2.28-2.64s-2.25,1.09-2.41,2.64Z"
              />
              <path className="popup-4" d="M79.8,205.69V192.81h1.53v12.88Z" />
              <path
                className="popup-4"
                d="M85.23,201.52c0,1.81.92,3.1,2.43,3.1a2.06,2.06,0,0,0,2.15-1.38h1.55a3.62,3.62,0,0,1-3.75,2.66c-2.79,0-3.93-2.27-3.93-4.56,0-2.65,1.33-4.76,4-4.76,2.87,0,3.81,2.31,3.81,4.2,0,.28,0,.5,0,.74ZM90,200.44c0-1.46-.76-2.64-2.28-2.64s-2.25,1.09-2.41,2.64Z"
              />
              <path
                className="popup-4"
                d="M93.86,199.21c0-1.24,0-1.94,0-2.42h1.51c0,.25,0,1,0,1.82a3.17,3.17,0,0,1,3-2v1.54c-1.82,0-3,1-3,3.25v4.32H93.86Z"
              />
              <path
                className="popup-4"
                d="M106.72,203.75a14.24,14.24,0,0,0,.12,1.94h-1.47a4.83,4.83,0,0,1-.14-1.16,2.79,2.79,0,0,1-2.75,1.37,2.68,2.68,0,0,1-3-2.72c0-2,1.56-2.89,4.2-2.89h1.52v-.76c0-.78-.25-1.74-1.92-1.74-1.48,0-1.74.74-1.9,1.43H99.91c.11-1.23.87-2.64,3.43-2.64,2.17,0,3.38.9,3.38,2.92Zm-1.5-2.4h-1.44c-1.77,0-2.73.49-2.73,1.75a1.57,1.57,0,0,0,1.74,1.6c2.14,0,2.43-1.44,2.43-3.06Z"
              />
              <path
                className="popup-4"
                d="M108.46,196.79H110v-2.62h1.54v2.62h2v1.26h-2v5.21c0,.86.21,1.31,1,1.31a3,3,0,0,0,.78-.08v1.16a4.19,4.19,0,0,1-1.33.17c-1.38,0-2-.72-2-2.2v-5.57h-1.54Z"
              />
              <path
                className="popup-4"
                d="M116.35,201.52c0,1.81.93,3.1,2.44,3.1a2,2,0,0,0,2.14-1.38h1.55a3.61,3.61,0,0,1-3.74,2.66c-2.8,0-3.94-2.27-3.94-4.56,0-2.65,1.33-4.76,4-4.76,2.87,0,3.81,2.31,3.81,4.2,0,.28,0,.5,0,.74Zm4.74-1.08c0-1.46-.75-2.64-2.27-2.64s-2.26,1.09-2.41,2.64Z"
              />
              <path
                className="popup-4"
                d="M130.22,196.79c1.44,4.26,2.23,6.63,2.46,7.53h0c.29-1,.92-3,2.38-7.53h1.55l-3.2,9.31c-.91,2.61-1.58,3.26-3.4,3.26a8.19,8.19,0,0,1-.95-.05V208a5.58,5.58,0,0,0,.73.05c1.13,0,1.56-.5,2.08-2l-3.33-9.25Z"
              />
              <path
                className="popup-4"
                d="M146.15,201.23c0,2.56-1.47,4.67-4.19,4.67-2.55,0-4.09-2-4.09-4.64s1.51-4.68,4.19-4.68C144.53,196.58,146.15,198.43,146.15,201.23Zm-6.7,0c0,1.95,1,3.38,2.57,3.38s2.55-1.35,2.55-3.37-.91-3.38-2.58-3.38S139.45,199.21,139.45,201.24Z"
              />
              <path
                className="popup-4"
                d="M155.65,203.1c0,.87,0,2.07,0,2.59h-1.5c0-.23,0-.77-.06-1.33a2.64,2.64,0,0,1-2.69,1.54c-1.5,0-2.94-.74-2.94-3.25v-5.86H150v5.53c0,1.17.37,2.24,1.84,2.24,1.64,0,2.27-.9,2.27-3.13v-4.64h1.54Z"
              />
              <path
                className="popup-4"
                d="M158.64,199.21c0-1.24,0-1.94,0-2.42h1.51c0,.25,0,1,0,1.82a3.17,3.17,0,0,1,3-2v1.54c-1.83,0-3,1-3,3.25v4.32h-1.53Z"
              />
              <path
                className="popup-4"
                d="M179.92,202.23a4.63,4.63,0,0,1-4.86,3.62c-3.65,0-5.49-2.78-5.49-6.23s1.85-6.31,5.56-6.31c2.88,0,4.44,1.64,4.8,3.68h-1.62a3.09,3.09,0,0,0-3.25-2.33c-2.69,0-3.79,2.45-3.79,4.92s1,4.92,3.86,4.92a3.14,3.14,0,0,0,3.17-2.27Z"
              />
              <path
                className="popup-4"
                d="M182.66,193.48H187c3.43,0,5.75,2.34,5.75,6s-2.26,6.23-5.81,6.23h-4.31Zm1.62,10.84h2.56c2.88,0,4.24-2.06,4.24-4.82,0-2.41-1.24-4.64-4.23-4.64h-2.57Z"
              />
              <path
                className="popup-4"
                d="M195.67,193.48h1.62v10.83h6.37l-.23,1.38h-7.76Z"
              />
              <path
                className="popup-4"
                d="M88.8,224h1.54v-2.63h1.54V224h2v1.25h-2v5.21c0,.87.21,1.31,1,1.31a3.54,3.54,0,0,0,.78-.07v1.15a3.92,3.92,0,0,1-1.33.17c-1.39,0-2-.72-2-2.2v-5.57H88.8Z"
              />
              <path
                className="popup-4"
                d="M95.75,226.46c0-1.25,0-2,0-2.42h1.5c0,.25.05,1,.05,1.81a3.15,3.15,0,0,1,3-2v1.53c-1.83,0-3,1-3,3.26v4.32H95.75Z"
              />
              <path
                className="popup-4"
                d="M108.61,231a12.48,12.48,0,0,0,.13,1.94h-1.47a4.79,4.79,0,0,1-.15-1.17,2.79,2.79,0,0,1-2.75,1.38,2.69,2.69,0,0,1-3-2.72c0-2,1.56-2.9,4.21-2.9h1.51v-.76c0-.78-.25-1.74-1.91-1.74-1.49,0-1.74.74-1.9,1.43H101.8c.12-1.22.88-2.64,3.44-2.63,2.17,0,3.37.89,3.37,2.91Zm-1.5-2.4h-1.44c-1.77,0-2.72.49-2.72,1.74a1.57,1.57,0,0,0,1.73,1.6c2.15,0,2.43-1.44,2.43-3.06Z"
              />
              <path
                className="popup-4"
                d="M111.62,220.05h1.54V222h-1.54Zm0,4h1.54v8.9h-1.54Z"
              />
              <path
                className="popup-4"
                d="M116.14,226.28c0-.77,0-1.55,0-2.24h1.5c0,.26.06,1.2.06,1.46a2.8,2.8,0,0,1,2.8-1.67c1.67,0,3,1,3,3.38v5.73h-1.53v-5.53c0-1.34-.53-2.25-1.89-2.25-1.68,0-2.35,1.28-2.35,3.07v4.71h-1.54Z"
              />
              <path
                className="popup-4"
                d="M126.42,220.05H128V222h-1.54Zm0,4H128v8.9h-1.54Z"
              />
              <path
                className="popup-4"
                d="M130.94,226.28c0-.77,0-1.55,0-2.24h1.5c0,.26.06,1.2.06,1.46a2.8,2.8,0,0,1,2.8-1.67c1.67,0,3,1,3,3.38v5.73h-1.53v-5.53c0-1.34-.53-2.25-1.89-2.25-1.68,0-2.35,1.28-2.35,3.07v4.71h-1.54Z"
              />
              <path
                className="popup-4"
                d="M148.55,232.13c0,2.86-1.17,4.48-4.08,4.48-2.7,0-3.53-1.51-3.69-2.72h1.57a2,2,0,0,0,2.19,1.49c2,0,2.52-1.28,2.52-3.33v-.72a2.72,2.72,0,0,1-2.78,1.56c-2.19,0-3.69-1.78-3.69-4.39,0-2.91,1.7-4.67,3.85-4.67,1.69,0,2.42.8,2.62,1.39,0-.41.05-1,.07-1.18h1.45c0,.51,0,1.71,0,2.57Zm-6.36-3.7c0,1.88,1,3.15,2.43,3.15,1.92,0,2.48-1.29,2.48-3.26s-.53-3.19-2.43-3.19C143,225.13,142.19,226.51,142.19,228.43Z"
              />
              <path className="popup-4" d="M151,232.94v-2.6h1.61v2.6Z" />
              <line
                id="pop2s1"
                className="popup-5"
                x1={212.34}
                y1={202.18}
                x2={29.99}
                y2={202.18}
              />
              <line
                id="pop2s2"
                className="popup-5"
                x1={158.62}
                y1={228.6}
                x2={79.97}
                y2={228.6}
              />
            </g>
            <g id="pop3">
              <rect
                className="popup-3"
                y={268.43}
                width={242.34}
                height={42.05}
                rx={11.58}
              />
              <path
                className="popup-4"
                d="M25.53,290.4l-1.31,3.66H22.61L27,281.85h2l4.54,12.21H31.76l-1.35-3.66ZM30,289c-1.16-3.2-1.8-4.91-2.07-5.84h0c-.3,1-1,3.1-2,5.84Z"
              />
              <path
                className="popup-4"
                d="M35.33,285.17c1.2,3.63,2.08,6.35,2.35,7.46h0c.22-1,1.16-3.65,2.5-7.46h1.56l-3.27,8.89h-1.7l-3.1-8.89Z"
              />
              <path
                className="popup-4"
                d="M50.06,292.12a14.24,14.24,0,0,0,.12,1.94H48.71a5.15,5.15,0,0,1-.15-1.16,2.78,2.78,0,0,1-2.74,1.37,2.69,2.69,0,0,1-3-2.72c0-2,1.56-2.89,4.21-2.89h1.51v-.76c0-.78-.25-1.74-1.91-1.74-1.49,0-1.74.74-1.9,1.43H43.24c.12-1.22.88-2.64,3.44-2.64,2.17,0,3.38.9,3.38,2.92Zm-1.5-2.39H47.12c-1.78,0-2.73.49-2.73,1.74a1.57,1.57,0,0,0,1.73,1.6c2.15,0,2.44-1.44,2.44-3.06Z"
              />
              <path
                className="popup-4"
                d="M53.07,281.18H54.6v1.92H53.07Zm0,4H54.6v8.89H53.07Z"
              />
              <path className="popup-4" d="M57.58,294.06V281.18h1.53v12.88Z" />
              <path
                className="popup-4"
                d="M68.61,292.12a14.24,14.24,0,0,0,.12,1.94H67.26a4.75,4.75,0,0,1-.14-1.16,2.79,2.79,0,0,1-2.75,1.37,2.68,2.68,0,0,1-3-2.72c0-2,1.56-2.89,4.2-2.89H67.1v-.76c0-.78-.25-1.74-1.92-1.74-1.48,0-1.73.74-1.89,1.43H61.8c.12-1.22.88-2.64,3.43-2.64,2.18,0,3.38.9,3.38,2.92Zm-1.5-2.39H65.67c-1.77,0-2.72.49-2.72,1.74a1.56,1.56,0,0,0,1.73,1.6c2.14,0,2.43-1.44,2.43-3.06Z"
              />
              <path
                className="popup-4"
                d="M71.62,281.18h1.54v5.25A2.93,2.93,0,0,1,76,285c2.23,0,3.64,1.84,3.64,4.48s-1.36,4.84-3.88,4.84a2.59,2.59,0,0,1-2.61-1.38c0,.45,0,.93-.06,1.17H71.6c0-1,0-2,0-3Zm6.43,8.28c0-1.92-.87-3.2-2.41-3.2-1.89,0-2.56,1.27-2.56,3.38,0,1.86.59,3.34,2.48,3.34C77.13,293,78.05,291.63,78.05,289.46Z"
              />
              <path className="popup-4" d="M82,294.06V281.18h1.53v12.88Z" />
              <path
                className="popup-4"
                d="M87.43,289.89c0,1.81.92,3.1,2.43,3.1A2.06,2.06,0,0,0,92,291.61h1.55a3.62,3.62,0,0,1-3.75,2.66c-2.79,0-3.94-2.27-3.94-4.56,0-2.65,1.34-4.76,4-4.76,2.87,0,3.81,2.31,3.81,4.21,0,.27,0,.49,0,.73Zm4.73-1.08c0-1.46-.75-2.64-2.27-2.64s-2.25,1.09-2.41,2.64Z"
              />
              <path
                className="popup-4"
                d="M100.76,281.18h1.54v1.92h-1.54Zm0,4h1.54v8.89h-1.54Z"
              />
              <path
                className="popup-4"
                d="M105.28,287.41c0-.77,0-1.55,0-2.24h1.5c0,.26.06,1.19.06,1.46a2.79,2.79,0,0,1,2.8-1.68c1.66,0,3,1,3,3.39v5.72h-1.54v-5.52c0-1.35-.52-2.25-1.88-2.25-1.68,0-2.35,1.27-2.35,3.06v4.71h-1.54Z"
              />
              <path
                className="popup-4"
                d="M119.84,290.8a3.12,3.12,0,0,1,2.09-3,2.59,2.59,0,0,1-1.6-2.45c0-1.78,1.26-3.08,3.63-3.08,2.18,0,3.51,1.27,3.51,3.06a2.45,2.45,0,0,1-1.61,2.4,3.06,3.06,0,0,1,2.06,3c0,2.21-1.71,3.53-4.12,3.53C121.57,294.27,119.84,292.94,119.84,290.8Zm6.5-.07c0-1.47-.94-2.23-2.46-2.23a2.15,2.15,0,0,0-2.46,2.23,2.24,2.24,0,0,0,2.47,2.27A2.18,2.18,0,0,0,126.34,290.73Zm-4.49-5.34c0,1,.63,1.8,2.09,1.8s2-.66,2-1.75a1.81,1.81,0,0,0-2-1.86C122.45,283.58,121.85,284.32,121.85,285.39Z"
              />
              <path className="popup-4" d="M135.17,294.06V281.18h1.54v12.88Z" />
              <path
                className="popup-4"
                d="M146.2,292.12a12.38,12.38,0,0,0,.13,1.94h-1.47a4.62,4.62,0,0,1-.15-1.16,2.79,2.79,0,0,1-2.75,1.37,2.69,2.69,0,0,1-3-2.72c0-2,1.56-2.89,4.21-2.89h1.51v-.76c0-.78-.25-1.74-1.91-1.74-1.49,0-1.74.74-1.9,1.43h-1.49c.12-1.22.88-2.64,3.44-2.64,2.17,0,3.37.9,3.37,2.92Zm-1.5-2.39h-1.44c-1.77,0-2.72.49-2.72,1.74a1.57,1.57,0,0,0,1.73,1.6c2.15,0,2.43-1.44,2.43-3.06Z"
              />
              <path
                className="popup-4"
                d="M149.22,287.41c0-.77,0-1.55,0-2.24h1.5c0,.26.06,1.19.06,1.46a2.79,2.79,0,0,1,2.8-1.68c1.66,0,3,1,3,3.39v5.72H155v-5.52c0-1.35-.52-2.25-1.88-2.25-1.68,0-2.35,1.27-2.35,3.06v4.71h-1.54Z"
              />
              <path
                className="popup-4"
                d="M166.83,293.26c0,2.85-1.18,4.48-4.08,4.48-2.7,0-3.53-1.51-3.69-2.72h1.57a2,2,0,0,0,2.19,1.49c2,0,2.52-1.28,2.52-3.33v-.72a2.72,2.72,0,0,1-2.78,1.56c-2.19,0-3.69-1.79-3.69-4.39,0-2.91,1.69-4.68,3.85-4.68,1.69,0,2.42.8,2.62,1.39,0-.4,0-1,.07-1.17h1.45c0,.51,0,1.71,0,2.56Zm-6.36-3.7c0,1.88,1,3.15,2.43,3.15,1.92,0,2.48-1.29,2.48-3.26s-.53-3.19-2.43-3.19C161.31,286.26,160.47,287.63,160.47,289.56Z"
              />
              <path
                className="popup-4"
                d="M177,291.47c0,.87,0,2.07,0,2.59h-1.49a12.73,12.73,0,0,1-.06-1.33,2.65,2.65,0,0,1-2.69,1.54c-1.5,0-2.94-.74-2.94-3.25v-5.85h1.52v5.52c0,1.17.37,2.25,1.84,2.25,1.64,0,2.27-.91,2.27-3.13v-4.64H177Z"
              />
              <path
                className="popup-4"
                d="M186.56,292.12a12.38,12.38,0,0,0,.13,1.94h-1.47a4.62,4.62,0,0,1-.15-1.16,2.79,2.79,0,0,1-2.75,1.37,2.69,2.69,0,0,1-3-2.72c0-2,1.56-2.89,4.2-2.89h1.52v-.76c0-.78-.25-1.74-1.91-1.74-1.49,0-1.74.74-1.9,1.43h-1.49c.12-1.22.88-2.64,3.43-2.64,2.18,0,3.38.9,3.38,2.92Zm-1.5-2.39h-1.44c-1.77,0-2.72.49-2.72,1.74a1.56,1.56,0,0,0,1.73,1.6c2.14,0,2.43-1.44,2.43-3.06Z"
              />
              <path
                className="popup-4"
                d="M196.9,293.26c0,2.85-1.17,4.48-4.08,4.48-2.7,0-3.53-1.51-3.69-2.72h1.57a2,2,0,0,0,2.19,1.49c2,0,2.52-1.28,2.52-3.33v-.72a2.72,2.72,0,0,1-2.78,1.56c-2.19,0-3.68-1.79-3.68-4.39,0-2.91,1.69-4.68,3.84-4.68,1.69,0,2.42.8,2.62,1.39,0-.4.05-1,.07-1.17h1.45c0,.51,0,1.71,0,2.56Zm-6.36-3.7c0,1.88,1,3.15,2.43,3.15,1.92,0,2.48-1.29,2.48-3.26s-.53-3.19-2.43-3.19C191.38,286.26,190.54,287.63,190.54,289.56Z"
              />
              <path
                className="popup-4"
                d="M200.82,289.89c0,1.81.92,3.1,2.43,3.1a2.07,2.07,0,0,0,2.15-1.38h1.54a3.61,3.61,0,0,1-3.74,2.66c-2.8,0-3.94-2.27-3.94-4.56,0-2.65,1.33-4.76,4-4.76,2.87,0,3.81,2.31,3.81,4.21,0,.27,0,.49,0,.73Zm4.73-1.08c0-1.46-.75-2.64-2.27-2.64s-2.26,1.09-2.41,2.64Z"
              />
              <path
                className="popup-4"
                d="M210.31,291.52a2,2,0,0,0,2.18,1.54c1.31,0,1.83-.56,1.83-1.37s-.41-1.22-2.11-1.63c-2.54-.62-3.08-1.4-3.08-2.6s.93-2.51,3.22-2.51,3.23,1.33,3.35,2.57h-1.49a1.75,1.75,0,0,0-1.93-1.37c-1.25,0-1.62.6-1.62,1.18s.37,1,2,1.38c2.7.64,3.24,1.54,3.24,2.87,0,1.56-1.24,2.69-3.45,2.69s-3.44-1.16-3.67-2.75Z"
              />
              <path className="popup-4" d="M218.13,294.06v-2.59h1.61v2.59Z" />
              <line
                id="pop3s1"
                className="popup-5"
                x1={223.74}
                y1={290.75}
                x2={18.6}
                y2={290.75}
              />
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
};
export default PopupSvgComponent;
