import "./Slider.css"
import googlereview from "../../pages/Images/Google review.svg"
import Review1 from "./SNGLE/lasy.png"
import Review2 from "./SNGLE/2ndreview.png"
import Review3 from "./SNGLE/Green Map.png"
import Review4 from "./SNGLE/schoole.png"
import Review5 from "./SNGLE/Mario.png"
import Review6 from "./SNGLE/IMG_3399.jpg"
import Review7 from "./SNGLE/7thimage.png"
import Review8 from "./SNGLE/fat.png"
import Review9 from "./SNGLE/IMG_2293.jpg"
import { Carousel } from "antd"

function SliderPage (){
    return(
        <div className="baccov">
            
          <Carousel>
            
           
       <div className="slidercoverdiv">
<div className="googlereviewdiv">
    <div>
           <img src={googlereview} style={{width:"91px"}} alt="google review"/>
 
    </div>
    <div>
    <div className="startsreview">
              <svg xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 11 10" fill="none">
                <g clip-path="url(#clip0_3693_14062)">
                  <path d="M5.1942 8.69694L7.28498 9.81603L7.33347 9.83737C7.6884 9.97119 8.02393 9.94404 8.278 9.71712C8.51656 9.50377 8.60384 9.19733 8.55341 8.84628L8.2043 6.43355L9.78305 4.77333C10.0585 4.49599 10.1651 4.15463 10.072 3.79777C9.96925 3.40211 9.63565 3.17325 9.15272 3.09761L7.05418 2.78147L5.95642 0.504498C5.78575 0.182542 5.5181 -0.00752932 5.1845 0.000228676C4.85673 0.00992617 4.59683 0.209695 4.39319 0.568502L3.33034 2.79505L0.97773 3.12282C0.603407 3.18682 0.327998 3.38271 0.217447 3.70661C0.106895 4.03051 0.211629 4.35828 0.494795 4.69188L2.20349 6.43548L1.81947 8.84628C1.75935 9.26327 1.83111 9.59299 2.1104 9.79082C2.36059 9.96731 2.67479 9.96731 3.03748 9.84318L3.0976 9.81797L5.1942 8.69694Z" fill="#FBB723" />
                </g>
                <defs>
                  <clipPath id="clip0_3693_14062">
                    <rect width="9.93023" height="9.93023" fill="white" transform="matrix(-1 0 0 1 10.105 0)" />
                  </clipPath>
                </defs>
              </svg>
              <svg xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 11 10" fill="none">
                <g clip-path="url(#clip0_3693_14062)">
                  <path d="M5.1942 8.69694L7.28498 9.81603L7.33347 9.83737C7.6884 9.97119 8.02393 9.94404 8.278 9.71712C8.51656 9.50377 8.60384 9.19733 8.55341 8.84628L8.2043 6.43355L9.78305 4.77333C10.0585 4.49599 10.1651 4.15463 10.072 3.79777C9.96925 3.40211 9.63565 3.17325 9.15272 3.09761L7.05418 2.78147L5.95642 0.504498C5.78575 0.182542 5.5181 -0.00752932 5.1845 0.000228676C4.85673 0.00992617 4.59683 0.209695 4.39319 0.568502L3.33034 2.79505L0.97773 3.12282C0.603407 3.18682 0.327998 3.38271 0.217447 3.70661C0.106895 4.03051 0.211629 4.35828 0.494795 4.69188L2.20349 6.43548L1.81947 8.84628C1.75935 9.26327 1.83111 9.59299 2.1104 9.79082C2.36059 9.96731 2.67479 9.96731 3.03748 9.84318L3.0976 9.81797L5.1942 8.69694Z" fill="#FBB723" />
                </g>
                <defs>
                  <clipPath id="clip0_3693_14062">
                    <rect width="9.93023" height="9.93023" fill="white" transform="matrix(-1 0 0 1 10.105 0)" />
                  </clipPath>
                </defs>
              </svg>
              <svg xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 11 10" fill="none">
                <g clip-path="url(#clip0_3693_14062)">
                  <path d="M5.1942 8.69694L7.28498 9.81603L7.33347 9.83737C7.6884 9.97119 8.02393 9.94404 8.278 9.71712C8.51656 9.50377 8.60384 9.19733 8.55341 8.84628L8.2043 6.43355L9.78305 4.77333C10.0585 4.49599 10.1651 4.15463 10.072 3.79777C9.96925 3.40211 9.63565 3.17325 9.15272 3.09761L7.05418 2.78147L5.95642 0.504498C5.78575 0.182542 5.5181 -0.00752932 5.1845 0.000228676C4.85673 0.00992617 4.59683 0.209695 4.39319 0.568502L3.33034 2.79505L0.97773 3.12282C0.603407 3.18682 0.327998 3.38271 0.217447 3.70661C0.106895 4.03051 0.211629 4.35828 0.494795 4.69188L2.20349 6.43548L1.81947 8.84628C1.75935 9.26327 1.83111 9.59299 2.1104 9.79082C2.36059 9.96731 2.67479 9.96731 3.03748 9.84318L3.0976 9.81797L5.1942 8.69694Z" fill="#FBB723" />
                </g>
                <defs>
                  <clipPath id="clip0_3693_14062">
                    <rect width="9.93023" height="9.93023" fill="white" transform="matrix(-1 0 0 1 10.105 0)" />
                  </clipPath>
                </defs>
              </svg>
              <svg xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 11 10" fill="none">
                <g clip-path="url(#clip0_3693_14062)">
                  <path d="M5.1942 8.69694L7.28498 9.81603L7.33347 9.83737C7.6884 9.97119 8.02393 9.94404 8.278 9.71712C8.51656 9.50377 8.60384 9.19733 8.55341 8.84628L8.2043 6.43355L9.78305 4.77333C10.0585 4.49599 10.1651 4.15463 10.072 3.79777C9.96925 3.40211 9.63565 3.17325 9.15272 3.09761L7.05418 2.78147L5.95642 0.504498C5.78575 0.182542 5.5181 -0.00752932 5.1845 0.000228676C4.85673 0.00992617 4.59683 0.209695 4.39319 0.568502L3.33034 2.79505L0.97773 3.12282C0.603407 3.18682 0.327998 3.38271 0.217447 3.70661C0.106895 4.03051 0.211629 4.35828 0.494795 4.69188L2.20349 6.43548L1.81947 8.84628C1.75935 9.26327 1.83111 9.59299 2.1104 9.79082C2.36059 9.96731 2.67479 9.96731 3.03748 9.84318L3.0976 9.81797L5.1942 8.69694Z" fill="#FBB723" />
                </g>
                <defs>
                  <clipPath id="clip0_3693_14062">
                    <rect width="9.93023" height="9.93023" fill="white" transform="matrix(-1 0 0 1 10.105 0)" />
                  </clipPath>
                </defs>
              </svg>
              <svg xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 11 10" fill="none">
                <g clip-path="url(#clip0_3693_14062)">
                  <path d="M5.1942 8.69694L7.28498 9.81603L7.33347 9.83737C7.6884 9.97119 8.02393 9.94404 8.278 9.71712C8.51656 9.50377 8.60384 9.19733 8.55341 8.84628L8.2043 6.43355L9.78305 4.77333C10.0585 4.49599 10.1651 4.15463 10.072 3.79777C9.96925 3.40211 9.63565 3.17325 9.15272 3.09761L7.05418 2.78147L5.95642 0.504498C5.78575 0.182542 5.5181 -0.00752932 5.1845 0.000228676C4.85673 0.00992617 4.59683 0.209695 4.39319 0.568502L3.33034 2.79505L0.97773 3.12282C0.603407 3.18682 0.327998 3.38271 0.217447 3.70661C0.106895 4.03051 0.211629 4.35828 0.494795 4.69188L2.20349 6.43548L1.81947 8.84628C1.75935 9.26327 1.83111 9.59299 2.1104 9.79082C2.36059 9.96731 2.67479 9.96731 3.03748 9.84318L3.0976 9.81797L5.1942 8.69694Z" fill="#FBB723" />
                </g>
                <defs>
                  <clipPath id="clip0_3693_14062">
                    <rect width="9.93023" height="9.93023" fill="white" transform="matrix(-1 0 0 1 10.105 0)" />
                  </clipPath>
                </defs>
              </svg>
            </div>
            <div className="reviewcount">
              7,458 reviews
            </div>
    </div>
</div>
<div className="slidercontent">


<div className="name_section">
                     <div className="nameofperson slidername">
                     Trudy  
                     <div className="startsreview justify-content-start mt-2">
            <svg xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 11 10" fill="none">
              <g clip-path="url(#clip0_3693_14062)">
                <path d="M5.1942 8.69694L7.28498 9.81603L7.33347 9.83737C7.6884 9.97119 8.02393 9.94404 8.278 9.71712C8.51656 9.50377 8.60384 9.19733 8.55341 8.84628L8.2043 6.43355L9.78305 4.77333C10.0585 4.49599 10.1651 4.15463 10.072 3.79777C9.96925 3.40211 9.63565 3.17325 9.15272 3.09761L7.05418 2.78147L5.95642 0.504498C5.78575 0.182542 5.5181 -0.00752932 5.1845 0.000228676C4.85673 0.00992617 4.59683 0.209695 4.39319 0.568502L3.33034 2.79505L0.97773 3.12282C0.603407 3.18682 0.327998 3.38271 0.217447 3.70661C0.106895 4.03051 0.211629 4.35828 0.494795 4.69188L2.20349 6.43548L1.81947 8.84628C1.75935 9.26327 1.83111 9.59299 2.1104 9.79082C2.36059 9.96731 2.67479 9.96731 3.03748 9.84318L3.0976 9.81797L5.1942 8.69694Z" fill="#FBB723" />
              </g>
              <defs>
                <clipPath id="clip0_3693_14062">
                  <rect width="9.93023" height="9.93023" fill="white" transform="matrix(-1 0 0 1 10.105 0)" />
                </clipPath>
              </defs>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 11 10" fill="none">
              <g clip-path="url(#clip0_3693_14062)">
                <path d="M5.1942 8.69694L7.28498 9.81603L7.33347 9.83737C7.6884 9.97119 8.02393 9.94404 8.278 9.71712C8.51656 9.50377 8.60384 9.19733 8.55341 8.84628L8.2043 6.43355L9.78305 4.77333C10.0585 4.49599 10.1651 4.15463 10.072 3.79777C9.96925 3.40211 9.63565 3.17325 9.15272 3.09761L7.05418 2.78147L5.95642 0.504498C5.78575 0.182542 5.5181 -0.00752932 5.1845 0.000228676C4.85673 0.00992617 4.59683 0.209695 4.39319 0.568502L3.33034 2.79505L0.97773 3.12282C0.603407 3.18682 0.327998 3.38271 0.217447 3.70661C0.106895 4.03051 0.211629 4.35828 0.494795 4.69188L2.20349 6.43548L1.81947 8.84628C1.75935 9.26327 1.83111 9.59299 2.1104 9.79082C2.36059 9.96731 2.67479 9.96731 3.03748 9.84318L3.0976 9.81797L5.1942 8.69694Z" fill="#FBB723" />
              </g>
              <defs>
                <clipPath id="clip0_3693_14062">
                  <rect width="9.93023" height="9.93023" fill="white" transform="matrix(-1 0 0 1 10.105 0)" />
                </clipPath>
              </defs>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 11 10" fill="none">
              <g clip-path="url(#clip0_3693_14062)">
                <path d="M5.1942 8.69694L7.28498 9.81603L7.33347 9.83737C7.6884 9.97119 8.02393 9.94404 8.278 9.71712C8.51656 9.50377 8.60384 9.19733 8.55341 8.84628L8.2043 6.43355L9.78305 4.77333C10.0585 4.49599 10.1651 4.15463 10.072 3.79777C9.96925 3.40211 9.63565 3.17325 9.15272 3.09761L7.05418 2.78147L5.95642 0.504498C5.78575 0.182542 5.5181 -0.00752932 5.1845 0.000228676C4.85673 0.00992617 4.59683 0.209695 4.39319 0.568502L3.33034 2.79505L0.97773 3.12282C0.603407 3.18682 0.327998 3.38271 0.217447 3.70661C0.106895 4.03051 0.211629 4.35828 0.494795 4.69188L2.20349 6.43548L1.81947 8.84628C1.75935 9.26327 1.83111 9.59299 2.1104 9.79082C2.36059 9.96731 2.67479 9.96731 3.03748 9.84318L3.0976 9.81797L5.1942 8.69694Z" fill="#FBB723" />
              </g>
              <defs>
                <clipPath id="clip0_3693_14062">
                  <rect width="9.93023" height="9.93023" fill="white" transform="matrix(-1 0 0 1 10.105 0)" />
                </clipPath>
              </defs>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 11 10" fill="none">
              <g clip-path="url(#clip0_3693_14062)">
                <path d="M5.1942 8.69694L7.28498 9.81603L7.33347 9.83737C7.6884 9.97119 8.02393 9.94404 8.278 9.71712C8.51656 9.50377 8.60384 9.19733 8.55341 8.84628L8.2043 6.43355L9.78305 4.77333C10.0585 4.49599 10.1651 4.15463 10.072 3.79777C9.96925 3.40211 9.63565 3.17325 9.15272 3.09761L7.05418 2.78147L5.95642 0.504498C5.78575 0.182542 5.5181 -0.00752932 5.1845 0.000228676C4.85673 0.00992617 4.59683 0.209695 4.39319 0.568502L3.33034 2.79505L0.97773 3.12282C0.603407 3.18682 0.327998 3.38271 0.217447 3.70661C0.106895 4.03051 0.211629 4.35828 0.494795 4.69188L2.20349 6.43548L1.81947 8.84628C1.75935 9.26327 1.83111 9.59299 2.1104 9.79082C2.36059 9.96731 2.67479 9.96731 3.03748 9.84318L3.0976 9.81797L5.1942 8.69694Z" fill="#FBB723" />
              </g>
              <defs>
                <clipPath id="clip0_3693_14062">
                  <rect width="9.93023" height="9.93023" fill="white" transform="matrix(-1 0 0 1 10.105 0)" />
                </clipPath>
              </defs>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 11 10" fill="none">
              <g clip-path="url(#clip0_3693_14062)">
                <path d="M5.1942 8.69694L7.28498 9.81603L7.33347 9.83737C7.6884 9.97119 8.02393 9.94404 8.278 9.71712C8.51656 9.50377 8.60384 9.19733 8.55341 8.84628L8.2043 6.43355L9.78305 4.77333C10.0585 4.49599 10.1651 4.15463 10.072 3.79777C9.96925 3.40211 9.63565 3.17325 9.15272 3.09761L7.05418 2.78147L5.95642 0.504498C5.78575 0.182542 5.5181 -0.00752932 5.1845 0.000228676C4.85673 0.00992617 4.59683 0.209695 4.39319 0.568502L3.33034 2.79505L0.97773 3.12282C0.603407 3.18682 0.327998 3.38271 0.217447 3.70661C0.106895 4.03051 0.211629 4.35828 0.494795 4.69188L2.20349 6.43548L1.81947 8.84628C1.75935 9.26327 1.83111 9.59299 2.1104 9.79082C2.36059 9.96731 2.67479 9.96731 3.03748 9.84318L3.0976 9.81797L5.1942 8.69694Z" fill="#FBB723" />
              </g>
              <defs>
                <clipPath id="clip0_3693_14062">
                  <rect width="9.93023" height="9.93023" fill="white" transform="matrix(-1 0 0 1 10.105 0)" />
                </clipPath>
              </defs>
            </svg>
          </div>
                </div>
              
          <div className="date_of_review ">
          03/12/2024
          </div>
                </div>
                <div className="reviewtext">
                "This United ELDT course saved me 3 weeks of mandatory classes at the school I was already attending. 
              Thank you,
            United ELDT."
                </div>
                <div className="imageof_review">
                <img className="reviewforimage" src={Review1}  alt="reviewimage"/>
                </div>
                </div>   
                <div className="slidercontent">


<div className="name_section">
                     <div className="nameofperson slidername">
                     Jose Menjivar  
                     <div className="startsreview justify-content-start mt-2">
            <svg xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 11 10" fill="none">
              <g clip-path="url(#clip0_3693_14062)">
                <path d="M5.1942 8.69694L7.28498 9.81603L7.33347 9.83737C7.6884 9.97119 8.02393 9.94404 8.278 9.71712C8.51656 9.50377 8.60384 9.19733 8.55341 8.84628L8.2043 6.43355L9.78305 4.77333C10.0585 4.49599 10.1651 4.15463 10.072 3.79777C9.96925 3.40211 9.63565 3.17325 9.15272 3.09761L7.05418 2.78147L5.95642 0.504498C5.78575 0.182542 5.5181 -0.00752932 5.1845 0.000228676C4.85673 0.00992617 4.59683 0.209695 4.39319 0.568502L3.33034 2.79505L0.97773 3.12282C0.603407 3.18682 0.327998 3.38271 0.217447 3.70661C0.106895 4.03051 0.211629 4.35828 0.494795 4.69188L2.20349 6.43548L1.81947 8.84628C1.75935 9.26327 1.83111 9.59299 2.1104 9.79082C2.36059 9.96731 2.67479 9.96731 3.03748 9.84318L3.0976 9.81797L5.1942 8.69694Z" fill="#FBB723" />
              </g>
              <defs>
                <clipPath id="clip0_3693_14062">
                  <rect width="9.93023" height="9.93023" fill="white" transform="matrix(-1 0 0 1 10.105 0)" />
                </clipPath>
              </defs>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 11 10" fill="none">
              <g clip-path="url(#clip0_3693_14062)">
                <path d="M5.1942 8.69694L7.28498 9.81603L7.33347 9.83737C7.6884 9.97119 8.02393 9.94404 8.278 9.71712C8.51656 9.50377 8.60384 9.19733 8.55341 8.84628L8.2043 6.43355L9.78305 4.77333C10.0585 4.49599 10.1651 4.15463 10.072 3.79777C9.96925 3.40211 9.63565 3.17325 9.15272 3.09761L7.05418 2.78147L5.95642 0.504498C5.78575 0.182542 5.5181 -0.00752932 5.1845 0.000228676C4.85673 0.00992617 4.59683 0.209695 4.39319 0.568502L3.33034 2.79505L0.97773 3.12282C0.603407 3.18682 0.327998 3.38271 0.217447 3.70661C0.106895 4.03051 0.211629 4.35828 0.494795 4.69188L2.20349 6.43548L1.81947 8.84628C1.75935 9.26327 1.83111 9.59299 2.1104 9.79082C2.36059 9.96731 2.67479 9.96731 3.03748 9.84318L3.0976 9.81797L5.1942 8.69694Z" fill="#FBB723" />
              </g>
              <defs>
                <clipPath id="clip0_3693_14062">
                  <rect width="9.93023" height="9.93023" fill="white" transform="matrix(-1 0 0 1 10.105 0)" />
                </clipPath>
              </defs>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 11 10" fill="none">
              <g clip-path="url(#clip0_3693_14062)">
                <path d="M5.1942 8.69694L7.28498 9.81603L7.33347 9.83737C7.6884 9.97119 8.02393 9.94404 8.278 9.71712C8.51656 9.50377 8.60384 9.19733 8.55341 8.84628L8.2043 6.43355L9.78305 4.77333C10.0585 4.49599 10.1651 4.15463 10.072 3.79777C9.96925 3.40211 9.63565 3.17325 9.15272 3.09761L7.05418 2.78147L5.95642 0.504498C5.78575 0.182542 5.5181 -0.00752932 5.1845 0.000228676C4.85673 0.00992617 4.59683 0.209695 4.39319 0.568502L3.33034 2.79505L0.97773 3.12282C0.603407 3.18682 0.327998 3.38271 0.217447 3.70661C0.106895 4.03051 0.211629 4.35828 0.494795 4.69188L2.20349 6.43548L1.81947 8.84628C1.75935 9.26327 1.83111 9.59299 2.1104 9.79082C2.36059 9.96731 2.67479 9.96731 3.03748 9.84318L3.0976 9.81797L5.1942 8.69694Z" fill="#FBB723" />
              </g>
              <defs>
                <clipPath id="clip0_3693_14062">
                  <rect width="9.93023" height="9.93023" fill="white" transform="matrix(-1 0 0 1 10.105 0)" />
                </clipPath>
              </defs>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 11 10" fill="none">
              <g clip-path="url(#clip0_3693_14062)">
                <path d="M5.1942 8.69694L7.28498 9.81603L7.33347 9.83737C7.6884 9.97119 8.02393 9.94404 8.278 9.71712C8.51656 9.50377 8.60384 9.19733 8.55341 8.84628L8.2043 6.43355L9.78305 4.77333C10.0585 4.49599 10.1651 4.15463 10.072 3.79777C9.96925 3.40211 9.63565 3.17325 9.15272 3.09761L7.05418 2.78147L5.95642 0.504498C5.78575 0.182542 5.5181 -0.00752932 5.1845 0.000228676C4.85673 0.00992617 4.59683 0.209695 4.39319 0.568502L3.33034 2.79505L0.97773 3.12282C0.603407 3.18682 0.327998 3.38271 0.217447 3.70661C0.106895 4.03051 0.211629 4.35828 0.494795 4.69188L2.20349 6.43548L1.81947 8.84628C1.75935 9.26327 1.83111 9.59299 2.1104 9.79082C2.36059 9.96731 2.67479 9.96731 3.03748 9.84318L3.0976 9.81797L5.1942 8.69694Z" fill="#FBB723" />
              </g>
              <defs>
                <clipPath id="clip0_3693_14062">
                  <rect width="9.93023" height="9.93023" fill="white" transform="matrix(-1 0 0 1 10.105 0)" />
                </clipPath>
              </defs>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 11 10" fill="none">
              <g clip-path="url(#clip0_3693_14062)">
                <path d="M5.1942 8.69694L7.28498 9.81603L7.33347 9.83737C7.6884 9.97119 8.02393 9.94404 8.278 9.71712C8.51656 9.50377 8.60384 9.19733 8.55341 8.84628L8.2043 6.43355L9.78305 4.77333C10.0585 4.49599 10.1651 4.15463 10.072 3.79777C9.96925 3.40211 9.63565 3.17325 9.15272 3.09761L7.05418 2.78147L5.95642 0.504498C5.78575 0.182542 5.5181 -0.00752932 5.1845 0.000228676C4.85673 0.00992617 4.59683 0.209695 4.39319 0.568502L3.33034 2.79505L0.97773 3.12282C0.603407 3.18682 0.327998 3.38271 0.217447 3.70661C0.106895 4.03051 0.211629 4.35828 0.494795 4.69188L2.20349 6.43548L1.81947 8.84628C1.75935 9.26327 1.83111 9.59299 2.1104 9.79082C2.36059 9.96731 2.67479 9.96731 3.03748 9.84318L3.0976 9.81797L5.1942 8.69694Z" fill="#FBB723" />
              </g>
              <defs>
                <clipPath id="clip0_3693_14062">
                  <rect width="9.93023" height="9.93023" fill="white" transform="matrix(-1 0 0 1 10.105 0)" />
                </clipPath>
              </defs>
            </svg>
          </div>
                </div>
              
          <div className="date_of_review ">
          03/11/2024
          </div>
                </div>
                <div className="reviewtext">
                "I took 2 days to finish the mandatory classes with UnitedELDT.com, very easy and straightforward.
                <br></br> Very happy."
                </div>
                <div className="imageof_review">
                <img className="reviewforimage" src={Review2}  alt="reviewimage"/>
                </div>
                </div>    
                <div className="slidercontent">


<div className="name_section">
                     <div className="nameofperson slidername">
                     Gerson Escoto   
                                       <div className="startsreview justify-content-start mt-2">
            <svg xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 11 10" fill="none">
              <g clip-path="url(#clip0_3693_14062)">
                <path d="M5.1942 8.69694L7.28498 9.81603L7.33347 9.83737C7.6884 9.97119 8.02393 9.94404 8.278 9.71712C8.51656 9.50377 8.60384 9.19733 8.55341 8.84628L8.2043 6.43355L9.78305 4.77333C10.0585 4.49599 10.1651 4.15463 10.072 3.79777C9.96925 3.40211 9.63565 3.17325 9.15272 3.09761L7.05418 2.78147L5.95642 0.504498C5.78575 0.182542 5.5181 -0.00752932 5.1845 0.000228676C4.85673 0.00992617 4.59683 0.209695 4.39319 0.568502L3.33034 2.79505L0.97773 3.12282C0.603407 3.18682 0.327998 3.38271 0.217447 3.70661C0.106895 4.03051 0.211629 4.35828 0.494795 4.69188L2.20349 6.43548L1.81947 8.84628C1.75935 9.26327 1.83111 9.59299 2.1104 9.79082C2.36059 9.96731 2.67479 9.96731 3.03748 9.84318L3.0976 9.81797L5.1942 8.69694Z" fill="#FBB723" />
              </g>
              <defs>
                <clipPath id="clip0_3693_14062">
                  <rect width="9.93023" height="9.93023" fill="white" transform="matrix(-1 0 0 1 10.105 0)" />
                </clipPath>
              </defs>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 11 10" fill="none">
              <g clip-path="url(#clip0_3693_14062)">
                <path d="M5.1942 8.69694L7.28498 9.81603L7.33347 9.83737C7.6884 9.97119 8.02393 9.94404 8.278 9.71712C8.51656 9.50377 8.60384 9.19733 8.55341 8.84628L8.2043 6.43355L9.78305 4.77333C10.0585 4.49599 10.1651 4.15463 10.072 3.79777C9.96925 3.40211 9.63565 3.17325 9.15272 3.09761L7.05418 2.78147L5.95642 0.504498C5.78575 0.182542 5.5181 -0.00752932 5.1845 0.000228676C4.85673 0.00992617 4.59683 0.209695 4.39319 0.568502L3.33034 2.79505L0.97773 3.12282C0.603407 3.18682 0.327998 3.38271 0.217447 3.70661C0.106895 4.03051 0.211629 4.35828 0.494795 4.69188L2.20349 6.43548L1.81947 8.84628C1.75935 9.26327 1.83111 9.59299 2.1104 9.79082C2.36059 9.96731 2.67479 9.96731 3.03748 9.84318L3.0976 9.81797L5.1942 8.69694Z" fill="#FBB723" />
              </g>
              <defs>
                <clipPath id="clip0_3693_14062">
                  <rect width="9.93023" height="9.93023" fill="white" transform="matrix(-1 0 0 1 10.105 0)" />
                </clipPath>
              </defs>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 11 10" fill="none">
              <g clip-path="url(#clip0_3693_14062)">
                <path d="M5.1942 8.69694L7.28498 9.81603L7.33347 9.83737C7.6884 9.97119 8.02393 9.94404 8.278 9.71712C8.51656 9.50377 8.60384 9.19733 8.55341 8.84628L8.2043 6.43355L9.78305 4.77333C10.0585 4.49599 10.1651 4.15463 10.072 3.79777C9.96925 3.40211 9.63565 3.17325 9.15272 3.09761L7.05418 2.78147L5.95642 0.504498C5.78575 0.182542 5.5181 -0.00752932 5.1845 0.000228676C4.85673 0.00992617 4.59683 0.209695 4.39319 0.568502L3.33034 2.79505L0.97773 3.12282C0.603407 3.18682 0.327998 3.38271 0.217447 3.70661C0.106895 4.03051 0.211629 4.35828 0.494795 4.69188L2.20349 6.43548L1.81947 8.84628C1.75935 9.26327 1.83111 9.59299 2.1104 9.79082C2.36059 9.96731 2.67479 9.96731 3.03748 9.84318L3.0976 9.81797L5.1942 8.69694Z" fill="#FBB723" />
              </g>
              <defs>
                <clipPath id="clip0_3693_14062">
                  <rect width="9.93023" height="9.93023" fill="white" transform="matrix(-1 0 0 1 10.105 0)" />
                </clipPath>
              </defs>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 11 10" fill="none">
              <g clip-path="url(#clip0_3693_14062)">
                <path d="M5.1942 8.69694L7.28498 9.81603L7.33347 9.83737C7.6884 9.97119 8.02393 9.94404 8.278 9.71712C8.51656 9.50377 8.60384 9.19733 8.55341 8.84628L8.2043 6.43355L9.78305 4.77333C10.0585 4.49599 10.1651 4.15463 10.072 3.79777C9.96925 3.40211 9.63565 3.17325 9.15272 3.09761L7.05418 2.78147L5.95642 0.504498C5.78575 0.182542 5.5181 -0.00752932 5.1845 0.000228676C4.85673 0.00992617 4.59683 0.209695 4.39319 0.568502L3.33034 2.79505L0.97773 3.12282C0.603407 3.18682 0.327998 3.38271 0.217447 3.70661C0.106895 4.03051 0.211629 4.35828 0.494795 4.69188L2.20349 6.43548L1.81947 8.84628C1.75935 9.26327 1.83111 9.59299 2.1104 9.79082C2.36059 9.96731 2.67479 9.96731 3.03748 9.84318L3.0976 9.81797L5.1942 8.69694Z" fill="#FBB723" />
              </g>
              <defs>
                <clipPath id="clip0_3693_14062">
                  <rect width="9.93023" height="9.93023" fill="white" transform="matrix(-1 0 0 1 10.105 0)" />
                </clipPath>
              </defs>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 11 10" fill="none">
              <g clip-path="url(#clip0_3693_14062)">
                <path d="M5.1942 8.69694L7.28498 9.81603L7.33347 9.83737C7.6884 9.97119 8.02393 9.94404 8.278 9.71712C8.51656 9.50377 8.60384 9.19733 8.55341 8.84628L8.2043 6.43355L9.78305 4.77333C10.0585 4.49599 10.1651 4.15463 10.072 3.79777C9.96925 3.40211 9.63565 3.17325 9.15272 3.09761L7.05418 2.78147L5.95642 0.504498C5.78575 0.182542 5.5181 -0.00752932 5.1845 0.000228676C4.85673 0.00992617 4.59683 0.209695 4.39319 0.568502L3.33034 2.79505L0.97773 3.12282C0.603407 3.18682 0.327998 3.38271 0.217447 3.70661C0.106895 4.03051 0.211629 4.35828 0.494795 4.69188L2.20349 6.43548L1.81947 8.84628C1.75935 9.26327 1.83111 9.59299 2.1104 9.79082C2.36059 9.96731 2.67479 9.96731 3.03748 9.84318L3.0976 9.81797L5.1942 8.69694Z" fill="#FBB723" />
              </g>
              <defs>
                <clipPath id="clip0_3693_14062">
                  <rect width="9.93023" height="9.93023" fill="white" transform="matrix(-1 0 0 1 10.105 0)" />
                </clipPath>
              </defs>
            </svg>
          </div>
                </div>
              
          <div className="date_of_review ">
          03/09/2024
          </div>
                </div>
                <div className="reviewtext">
                "Este curso fue el mejor que encontré. Pagué 30 dólares en un sitio web llamado ELDT.com, que tenía un curso que tomaba semanas para completarse y lo peor, todo en inglés. 
Recomiendo UnitedELDT."
                </div>
                <div className="imageof_review">
                <img className="reviewforimage" src={Review3}  alt="reviewimage"/>
                </div>
                </div> 
       </div>
       <div className="slidercoverdiv">
<div className="googlereviewdiv">
    <div>
           <img src={googlereview} style={{width:"91px"}} alt="google review"/>
 
    </div>
    <div>
    <div className="startsreview">
              <svg xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 11 10" fill="none">
                <g clip-path="url(#clip0_3693_14062)">
                  <path d="M5.1942 8.69694L7.28498 9.81603L7.33347 9.83737C7.6884 9.97119 8.02393 9.94404 8.278 9.71712C8.51656 9.50377 8.60384 9.19733 8.55341 8.84628L8.2043 6.43355L9.78305 4.77333C10.0585 4.49599 10.1651 4.15463 10.072 3.79777C9.96925 3.40211 9.63565 3.17325 9.15272 3.09761L7.05418 2.78147L5.95642 0.504498C5.78575 0.182542 5.5181 -0.00752932 5.1845 0.000228676C4.85673 0.00992617 4.59683 0.209695 4.39319 0.568502L3.33034 2.79505L0.97773 3.12282C0.603407 3.18682 0.327998 3.38271 0.217447 3.70661C0.106895 4.03051 0.211629 4.35828 0.494795 4.69188L2.20349 6.43548L1.81947 8.84628C1.75935 9.26327 1.83111 9.59299 2.1104 9.79082C2.36059 9.96731 2.67479 9.96731 3.03748 9.84318L3.0976 9.81797L5.1942 8.69694Z" fill="#FBB723" />
                </g>
                <defs>
                  <clipPath id="clip0_3693_14062">
                    <rect width="9.93023" height="9.93023" fill="white" transform="matrix(-1 0 0 1 10.105 0)" />
                  </clipPath>
                </defs>
              </svg>
              <svg xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 11 10" fill="none">
                <g clip-path="url(#clip0_3693_14062)">
                  <path d="M5.1942 8.69694L7.28498 9.81603L7.33347 9.83737C7.6884 9.97119 8.02393 9.94404 8.278 9.71712C8.51656 9.50377 8.60384 9.19733 8.55341 8.84628L8.2043 6.43355L9.78305 4.77333C10.0585 4.49599 10.1651 4.15463 10.072 3.79777C9.96925 3.40211 9.63565 3.17325 9.15272 3.09761L7.05418 2.78147L5.95642 0.504498C5.78575 0.182542 5.5181 -0.00752932 5.1845 0.000228676C4.85673 0.00992617 4.59683 0.209695 4.39319 0.568502L3.33034 2.79505L0.97773 3.12282C0.603407 3.18682 0.327998 3.38271 0.217447 3.70661C0.106895 4.03051 0.211629 4.35828 0.494795 4.69188L2.20349 6.43548L1.81947 8.84628C1.75935 9.26327 1.83111 9.59299 2.1104 9.79082C2.36059 9.96731 2.67479 9.96731 3.03748 9.84318L3.0976 9.81797L5.1942 8.69694Z" fill="#FBB723" />
                </g>
                <defs>
                  <clipPath id="clip0_3693_14062">
                    <rect width="9.93023" height="9.93023" fill="white" transform="matrix(-1 0 0 1 10.105 0)" />
                  </clipPath>
                </defs>
              </svg>
              <svg xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 11 10" fill="none">
                <g clip-path="url(#clip0_3693_14062)">
                  <path d="M5.1942 8.69694L7.28498 9.81603L7.33347 9.83737C7.6884 9.97119 8.02393 9.94404 8.278 9.71712C8.51656 9.50377 8.60384 9.19733 8.55341 8.84628L8.2043 6.43355L9.78305 4.77333C10.0585 4.49599 10.1651 4.15463 10.072 3.79777C9.96925 3.40211 9.63565 3.17325 9.15272 3.09761L7.05418 2.78147L5.95642 0.504498C5.78575 0.182542 5.5181 -0.00752932 5.1845 0.000228676C4.85673 0.00992617 4.59683 0.209695 4.39319 0.568502L3.33034 2.79505L0.97773 3.12282C0.603407 3.18682 0.327998 3.38271 0.217447 3.70661C0.106895 4.03051 0.211629 4.35828 0.494795 4.69188L2.20349 6.43548L1.81947 8.84628C1.75935 9.26327 1.83111 9.59299 2.1104 9.79082C2.36059 9.96731 2.67479 9.96731 3.03748 9.84318L3.0976 9.81797L5.1942 8.69694Z" fill="#FBB723" />
                </g>
                <defs>
                  <clipPath id="clip0_3693_14062">
                    <rect width="9.93023" height="9.93023" fill="white" transform="matrix(-1 0 0 1 10.105 0)" />
                  </clipPath>
                </defs>
              </svg>
              <svg xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 11 10" fill="none">
                <g clip-path="url(#clip0_3693_14062)">
                  <path d="M5.1942 8.69694L7.28498 9.81603L7.33347 9.83737C7.6884 9.97119 8.02393 9.94404 8.278 9.71712C8.51656 9.50377 8.60384 9.19733 8.55341 8.84628L8.2043 6.43355L9.78305 4.77333C10.0585 4.49599 10.1651 4.15463 10.072 3.79777C9.96925 3.40211 9.63565 3.17325 9.15272 3.09761L7.05418 2.78147L5.95642 0.504498C5.78575 0.182542 5.5181 -0.00752932 5.1845 0.000228676C4.85673 0.00992617 4.59683 0.209695 4.39319 0.568502L3.33034 2.79505L0.97773 3.12282C0.603407 3.18682 0.327998 3.38271 0.217447 3.70661C0.106895 4.03051 0.211629 4.35828 0.494795 4.69188L2.20349 6.43548L1.81947 8.84628C1.75935 9.26327 1.83111 9.59299 2.1104 9.79082C2.36059 9.96731 2.67479 9.96731 3.03748 9.84318L3.0976 9.81797L5.1942 8.69694Z" fill="#FBB723" />
                </g>
                <defs>
                  <clipPath id="clip0_3693_14062">
                    <rect width="9.93023" height="9.93023" fill="white" transform="matrix(-1 0 0 1 10.105 0)" />
                  </clipPath>
                </defs>
              </svg>
              <svg xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 11 10" fill="none">
                <g clip-path="url(#clip0_3693_14062)">
                  <path d="M5.1942 8.69694L7.28498 9.81603L7.33347 9.83737C7.6884 9.97119 8.02393 9.94404 8.278 9.71712C8.51656 9.50377 8.60384 9.19733 8.55341 8.84628L8.2043 6.43355L9.78305 4.77333C10.0585 4.49599 10.1651 4.15463 10.072 3.79777C9.96925 3.40211 9.63565 3.17325 9.15272 3.09761L7.05418 2.78147L5.95642 0.504498C5.78575 0.182542 5.5181 -0.00752932 5.1845 0.000228676C4.85673 0.00992617 4.59683 0.209695 4.39319 0.568502L3.33034 2.79505L0.97773 3.12282C0.603407 3.18682 0.327998 3.38271 0.217447 3.70661C0.106895 4.03051 0.211629 4.35828 0.494795 4.69188L2.20349 6.43548L1.81947 8.84628C1.75935 9.26327 1.83111 9.59299 2.1104 9.79082C2.36059 9.96731 2.67479 9.96731 3.03748 9.84318L3.0976 9.81797L5.1942 8.69694Z" fill="#FBB723" />
                </g>
                <defs>
                  <clipPath id="clip0_3693_14062">
                    <rect width="9.93023" height="9.93023" fill="white" transform="matrix(-1 0 0 1 10.105 0)" />
                  </clipPath>
                </defs>
              </svg>
            </div>
            <div className="reviewcount">
              7,458 reviews
            </div>
    </div>
</div>
<div className="slidercontent">


<div className="name_section">
                     <div className="nameofperson slidername">
                     Adane Zegeye  
                     <div className="startsreview justify-content-start mt-2">
            <svg xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 11 10" fill="none">
              <g clip-path="url(#clip0_3693_14062)">
                <path d="M5.1942 8.69694L7.28498 9.81603L7.33347 9.83737C7.6884 9.97119 8.02393 9.94404 8.278 9.71712C8.51656 9.50377 8.60384 9.19733 8.55341 8.84628L8.2043 6.43355L9.78305 4.77333C10.0585 4.49599 10.1651 4.15463 10.072 3.79777C9.96925 3.40211 9.63565 3.17325 9.15272 3.09761L7.05418 2.78147L5.95642 0.504498C5.78575 0.182542 5.5181 -0.00752932 5.1845 0.000228676C4.85673 0.00992617 4.59683 0.209695 4.39319 0.568502L3.33034 2.79505L0.97773 3.12282C0.603407 3.18682 0.327998 3.38271 0.217447 3.70661C0.106895 4.03051 0.211629 4.35828 0.494795 4.69188L2.20349 6.43548L1.81947 8.84628C1.75935 9.26327 1.83111 9.59299 2.1104 9.79082C2.36059 9.96731 2.67479 9.96731 3.03748 9.84318L3.0976 9.81797L5.1942 8.69694Z" fill="#FBB723" />
              </g>
              <defs>
                <clipPath id="clip0_3693_14062">
                  <rect width="9.93023" height="9.93023" fill="white" transform="matrix(-1 0 0 1 10.105 0)" />
                </clipPath>
              </defs>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 11 10" fill="none">
              <g clip-path="url(#clip0_3693_14062)">
                <path d="M5.1942 8.69694L7.28498 9.81603L7.33347 9.83737C7.6884 9.97119 8.02393 9.94404 8.278 9.71712C8.51656 9.50377 8.60384 9.19733 8.55341 8.84628L8.2043 6.43355L9.78305 4.77333C10.0585 4.49599 10.1651 4.15463 10.072 3.79777C9.96925 3.40211 9.63565 3.17325 9.15272 3.09761L7.05418 2.78147L5.95642 0.504498C5.78575 0.182542 5.5181 -0.00752932 5.1845 0.000228676C4.85673 0.00992617 4.59683 0.209695 4.39319 0.568502L3.33034 2.79505L0.97773 3.12282C0.603407 3.18682 0.327998 3.38271 0.217447 3.70661C0.106895 4.03051 0.211629 4.35828 0.494795 4.69188L2.20349 6.43548L1.81947 8.84628C1.75935 9.26327 1.83111 9.59299 2.1104 9.79082C2.36059 9.96731 2.67479 9.96731 3.03748 9.84318L3.0976 9.81797L5.1942 8.69694Z" fill="#FBB723" />
              </g>
              <defs>
                <clipPath id="clip0_3693_14062">
                  <rect width="9.93023" height="9.93023" fill="white" transform="matrix(-1 0 0 1 10.105 0)" />
                </clipPath>
              </defs>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 11 10" fill="none">
              <g clip-path="url(#clip0_3693_14062)">
                <path d="M5.1942 8.69694L7.28498 9.81603L7.33347 9.83737C7.6884 9.97119 8.02393 9.94404 8.278 9.71712C8.51656 9.50377 8.60384 9.19733 8.55341 8.84628L8.2043 6.43355L9.78305 4.77333C10.0585 4.49599 10.1651 4.15463 10.072 3.79777C9.96925 3.40211 9.63565 3.17325 9.15272 3.09761L7.05418 2.78147L5.95642 0.504498C5.78575 0.182542 5.5181 -0.00752932 5.1845 0.000228676C4.85673 0.00992617 4.59683 0.209695 4.39319 0.568502L3.33034 2.79505L0.97773 3.12282C0.603407 3.18682 0.327998 3.38271 0.217447 3.70661C0.106895 4.03051 0.211629 4.35828 0.494795 4.69188L2.20349 6.43548L1.81947 8.84628C1.75935 9.26327 1.83111 9.59299 2.1104 9.79082C2.36059 9.96731 2.67479 9.96731 3.03748 9.84318L3.0976 9.81797L5.1942 8.69694Z" fill="#FBB723" />
              </g>
              <defs>
                <clipPath id="clip0_3693_14062">
                  <rect width="9.93023" height="9.93023" fill="white" transform="matrix(-1 0 0 1 10.105 0)" />
                </clipPath>
              </defs>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 11 10" fill="none">
              <g clip-path="url(#clip0_3693_14062)">
                <path d="M5.1942 8.69694L7.28498 9.81603L7.33347 9.83737C7.6884 9.97119 8.02393 9.94404 8.278 9.71712C8.51656 9.50377 8.60384 9.19733 8.55341 8.84628L8.2043 6.43355L9.78305 4.77333C10.0585 4.49599 10.1651 4.15463 10.072 3.79777C9.96925 3.40211 9.63565 3.17325 9.15272 3.09761L7.05418 2.78147L5.95642 0.504498C5.78575 0.182542 5.5181 -0.00752932 5.1845 0.000228676C4.85673 0.00992617 4.59683 0.209695 4.39319 0.568502L3.33034 2.79505L0.97773 3.12282C0.603407 3.18682 0.327998 3.38271 0.217447 3.70661C0.106895 4.03051 0.211629 4.35828 0.494795 4.69188L2.20349 6.43548L1.81947 8.84628C1.75935 9.26327 1.83111 9.59299 2.1104 9.79082C2.36059 9.96731 2.67479 9.96731 3.03748 9.84318L3.0976 9.81797L5.1942 8.69694Z" fill="#FBB723" />
              </g>
              <defs>
                <clipPath id="clip0_3693_14062">
                  <rect width="9.93023" height="9.93023" fill="white" transform="matrix(-1 0 0 1 10.105 0)" />
                </clipPath>
              </defs>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 11 10" fill="none">
              <g clip-path="url(#clip0_3693_14062)">
                <path d="M5.1942 8.69694L7.28498 9.81603L7.33347 9.83737C7.6884 9.97119 8.02393 9.94404 8.278 9.71712C8.51656 9.50377 8.60384 9.19733 8.55341 8.84628L8.2043 6.43355L9.78305 4.77333C10.0585 4.49599 10.1651 4.15463 10.072 3.79777C9.96925 3.40211 9.63565 3.17325 9.15272 3.09761L7.05418 2.78147L5.95642 0.504498C5.78575 0.182542 5.5181 -0.00752932 5.1845 0.000228676C4.85673 0.00992617 4.59683 0.209695 4.39319 0.568502L3.33034 2.79505L0.97773 3.12282C0.603407 3.18682 0.327998 3.38271 0.217447 3.70661C0.106895 4.03051 0.211629 4.35828 0.494795 4.69188L2.20349 6.43548L1.81947 8.84628C1.75935 9.26327 1.83111 9.59299 2.1104 9.79082C2.36059 9.96731 2.67479 9.96731 3.03748 9.84318L3.0976 9.81797L5.1942 8.69694Z" fill="#FBB723" />
              </g>
              <defs>
                <clipPath id="clip0_3693_14062">
                  <rect width="9.93023" height="9.93023" fill="white" transform="matrix(-1 0 0 1 10.105 0)" />
                </clipPath>
              </defs>
            </svg>
          </div>
                </div>
              
          <div className="date_of_review ">
          03/12/2024
          </div>
                </div>
                <div className="reviewtext">
                “Thank to UnitedELDT.com I was able to go to my CDL driving school with my permit and theory course completed. This helped me get my CDL in less than 2 weeks”                </div>
                <div className="imageof_review">
                <img className="reviewforimage" src={Review4}   alt="reviewimage"/>
                </div>
                </div>   
                <div className="slidercontent">


<div className="name_section">
                     <div className="nameofperson slidername">
                     Mario Salustino  
                     <div className="startsreview justify-content-start mt-2">
            <svg xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 11 10" fill="none">
              <g clip-path="url(#clip0_3693_14062)">
                <path d="M5.1942 8.69694L7.28498 9.81603L7.33347 9.83737C7.6884 9.97119 8.02393 9.94404 8.278 9.71712C8.51656 9.50377 8.60384 9.19733 8.55341 8.84628L8.2043 6.43355L9.78305 4.77333C10.0585 4.49599 10.1651 4.15463 10.072 3.79777C9.96925 3.40211 9.63565 3.17325 9.15272 3.09761L7.05418 2.78147L5.95642 0.504498C5.78575 0.182542 5.5181 -0.00752932 5.1845 0.000228676C4.85673 0.00992617 4.59683 0.209695 4.39319 0.568502L3.33034 2.79505L0.97773 3.12282C0.603407 3.18682 0.327998 3.38271 0.217447 3.70661C0.106895 4.03051 0.211629 4.35828 0.494795 4.69188L2.20349 6.43548L1.81947 8.84628C1.75935 9.26327 1.83111 9.59299 2.1104 9.79082C2.36059 9.96731 2.67479 9.96731 3.03748 9.84318L3.0976 9.81797L5.1942 8.69694Z" fill="#FBB723" />
              </g>
              <defs>
                <clipPath id="clip0_3693_14062">
                  <rect width="9.93023" height="9.93023" fill="white" transform="matrix(-1 0 0 1 10.105 0)" />
                </clipPath>
              </defs>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 11 10" fill="none">
              <g clip-path="url(#clip0_3693_14062)">
                <path d="M5.1942 8.69694L7.28498 9.81603L7.33347 9.83737C7.6884 9.97119 8.02393 9.94404 8.278 9.71712C8.51656 9.50377 8.60384 9.19733 8.55341 8.84628L8.2043 6.43355L9.78305 4.77333C10.0585 4.49599 10.1651 4.15463 10.072 3.79777C9.96925 3.40211 9.63565 3.17325 9.15272 3.09761L7.05418 2.78147L5.95642 0.504498C5.78575 0.182542 5.5181 -0.00752932 5.1845 0.000228676C4.85673 0.00992617 4.59683 0.209695 4.39319 0.568502L3.33034 2.79505L0.97773 3.12282C0.603407 3.18682 0.327998 3.38271 0.217447 3.70661C0.106895 4.03051 0.211629 4.35828 0.494795 4.69188L2.20349 6.43548L1.81947 8.84628C1.75935 9.26327 1.83111 9.59299 2.1104 9.79082C2.36059 9.96731 2.67479 9.96731 3.03748 9.84318L3.0976 9.81797L5.1942 8.69694Z" fill="#FBB723" />
              </g>
              <defs>
                <clipPath id="clip0_3693_14062">
                  <rect width="9.93023" height="9.93023" fill="white" transform="matrix(-1 0 0 1 10.105 0)" />
                </clipPath>
              </defs>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 11 10" fill="none">
              <g clip-path="url(#clip0_3693_14062)">
                <path d="M5.1942 8.69694L7.28498 9.81603L7.33347 9.83737C7.6884 9.97119 8.02393 9.94404 8.278 9.71712C8.51656 9.50377 8.60384 9.19733 8.55341 8.84628L8.2043 6.43355L9.78305 4.77333C10.0585 4.49599 10.1651 4.15463 10.072 3.79777C9.96925 3.40211 9.63565 3.17325 9.15272 3.09761L7.05418 2.78147L5.95642 0.504498C5.78575 0.182542 5.5181 -0.00752932 5.1845 0.000228676C4.85673 0.00992617 4.59683 0.209695 4.39319 0.568502L3.33034 2.79505L0.97773 3.12282C0.603407 3.18682 0.327998 3.38271 0.217447 3.70661C0.106895 4.03051 0.211629 4.35828 0.494795 4.69188L2.20349 6.43548L1.81947 8.84628C1.75935 9.26327 1.83111 9.59299 2.1104 9.79082C2.36059 9.96731 2.67479 9.96731 3.03748 9.84318L3.0976 9.81797L5.1942 8.69694Z" fill="#FBB723" />
              </g>
              <defs>
                <clipPath id="clip0_3693_14062">
                  <rect width="9.93023" height="9.93023" fill="white" transform="matrix(-1 0 0 1 10.105 0)" />
                </clipPath>
              </defs>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 11 10" fill="none">
              <g clip-path="url(#clip0_3693_14062)">
                <path d="M5.1942 8.69694L7.28498 9.81603L7.33347 9.83737C7.6884 9.97119 8.02393 9.94404 8.278 9.71712C8.51656 9.50377 8.60384 9.19733 8.55341 8.84628L8.2043 6.43355L9.78305 4.77333C10.0585 4.49599 10.1651 4.15463 10.072 3.79777C9.96925 3.40211 9.63565 3.17325 9.15272 3.09761L7.05418 2.78147L5.95642 0.504498C5.78575 0.182542 5.5181 -0.00752932 5.1845 0.000228676C4.85673 0.00992617 4.59683 0.209695 4.39319 0.568502L3.33034 2.79505L0.97773 3.12282C0.603407 3.18682 0.327998 3.38271 0.217447 3.70661C0.106895 4.03051 0.211629 4.35828 0.494795 4.69188L2.20349 6.43548L1.81947 8.84628C1.75935 9.26327 1.83111 9.59299 2.1104 9.79082C2.36059 9.96731 2.67479 9.96731 3.03748 9.84318L3.0976 9.81797L5.1942 8.69694Z" fill="#FBB723" />
              </g>
              <defs>
                <clipPath id="clip0_3693_14062">
                  <rect width="9.93023" height="9.93023" fill="white" transform="matrix(-1 0 0 1 10.105 0)" />
                </clipPath>
              </defs>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 11 10" fill="none">
              <g clip-path="url(#clip0_3693_14062)">
                <path d="M5.1942 8.69694L7.28498 9.81603L7.33347 9.83737C7.6884 9.97119 8.02393 9.94404 8.278 9.71712C8.51656 9.50377 8.60384 9.19733 8.55341 8.84628L8.2043 6.43355L9.78305 4.77333C10.0585 4.49599 10.1651 4.15463 10.072 3.79777C9.96925 3.40211 9.63565 3.17325 9.15272 3.09761L7.05418 2.78147L5.95642 0.504498C5.78575 0.182542 5.5181 -0.00752932 5.1845 0.000228676C4.85673 0.00992617 4.59683 0.209695 4.39319 0.568502L3.33034 2.79505L0.97773 3.12282C0.603407 3.18682 0.327998 3.38271 0.217447 3.70661C0.106895 4.03051 0.211629 4.35828 0.494795 4.69188L2.20349 6.43548L1.81947 8.84628C1.75935 9.26327 1.83111 9.59299 2.1104 9.79082C2.36059 9.96731 2.67479 9.96731 3.03748 9.84318L3.0976 9.81797L5.1942 8.69694Z" fill="#FBB723" />
              </g>
              <defs>
                <clipPath id="clip0_3693_14062">
                  <rect width="9.93023" height="9.93023" fill="white" transform="matrix(-1 0 0 1 10.105 0)" />
                </clipPath>
              </defs>
            </svg>
          </div>
                </div>
              
          <div className="date_of_review ">
          03/13/2024
          </div>
                </div>
                <div className="reviewtext">
                “Este foi o único site que oferece o ELDT 100% em português. Eu SUPER RECOMENDO para todos os brasileiros que, assim como eu, não falam nada em inglês. Obrigado, United ELDT!”
                </div>
                <div className="imageof_review">
                <img className="reviewforimage" src={Review5}  alt="reviewimage"/>
                </div>
                </div>    
                <div className="slidercontent">


<div className="name_section">
                     <div className="nameofperson slidername">
                     Jimmie Lee Robertson  
                                         <div className="startsreview justify-content-start mt-2">
            <svg xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 11 10" fill="none">
              <g clip-path="url(#clip0_3693_14062)">
                <path d="M5.1942 8.69694L7.28498 9.81603L7.33347 9.83737C7.6884 9.97119 8.02393 9.94404 8.278 9.71712C8.51656 9.50377 8.60384 9.19733 8.55341 8.84628L8.2043 6.43355L9.78305 4.77333C10.0585 4.49599 10.1651 4.15463 10.072 3.79777C9.96925 3.40211 9.63565 3.17325 9.15272 3.09761L7.05418 2.78147L5.95642 0.504498C5.78575 0.182542 5.5181 -0.00752932 5.1845 0.000228676C4.85673 0.00992617 4.59683 0.209695 4.39319 0.568502L3.33034 2.79505L0.97773 3.12282C0.603407 3.18682 0.327998 3.38271 0.217447 3.70661C0.106895 4.03051 0.211629 4.35828 0.494795 4.69188L2.20349 6.43548L1.81947 8.84628C1.75935 9.26327 1.83111 9.59299 2.1104 9.79082C2.36059 9.96731 2.67479 9.96731 3.03748 9.84318L3.0976 9.81797L5.1942 8.69694Z" fill="#FBB723" />
              </g>
              <defs>
                <clipPath id="clip0_3693_14062">
                  <rect width="9.93023" height="9.93023" fill="white" transform="matrix(-1 0 0 1 10.105 0)" />
                </clipPath>
              </defs>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 11 10" fill="none">
              <g clip-path="url(#clip0_3693_14062)">
                <path d="M5.1942 8.69694L7.28498 9.81603L7.33347 9.83737C7.6884 9.97119 8.02393 9.94404 8.278 9.71712C8.51656 9.50377 8.60384 9.19733 8.55341 8.84628L8.2043 6.43355L9.78305 4.77333C10.0585 4.49599 10.1651 4.15463 10.072 3.79777C9.96925 3.40211 9.63565 3.17325 9.15272 3.09761L7.05418 2.78147L5.95642 0.504498C5.78575 0.182542 5.5181 -0.00752932 5.1845 0.000228676C4.85673 0.00992617 4.59683 0.209695 4.39319 0.568502L3.33034 2.79505L0.97773 3.12282C0.603407 3.18682 0.327998 3.38271 0.217447 3.70661C0.106895 4.03051 0.211629 4.35828 0.494795 4.69188L2.20349 6.43548L1.81947 8.84628C1.75935 9.26327 1.83111 9.59299 2.1104 9.79082C2.36059 9.96731 2.67479 9.96731 3.03748 9.84318L3.0976 9.81797L5.1942 8.69694Z" fill="#FBB723" />
              </g>
              <defs>
                <clipPath id="clip0_3693_14062">
                  <rect width="9.93023" height="9.93023" fill="white" transform="matrix(-1 0 0 1 10.105 0)" />
                </clipPath>
              </defs>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 11 10" fill="none">
              <g clip-path="url(#clip0_3693_14062)">
                <path d="M5.1942 8.69694L7.28498 9.81603L7.33347 9.83737C7.6884 9.97119 8.02393 9.94404 8.278 9.71712C8.51656 9.50377 8.60384 9.19733 8.55341 8.84628L8.2043 6.43355L9.78305 4.77333C10.0585 4.49599 10.1651 4.15463 10.072 3.79777C9.96925 3.40211 9.63565 3.17325 9.15272 3.09761L7.05418 2.78147L5.95642 0.504498C5.78575 0.182542 5.5181 -0.00752932 5.1845 0.000228676C4.85673 0.00992617 4.59683 0.209695 4.39319 0.568502L3.33034 2.79505L0.97773 3.12282C0.603407 3.18682 0.327998 3.38271 0.217447 3.70661C0.106895 4.03051 0.211629 4.35828 0.494795 4.69188L2.20349 6.43548L1.81947 8.84628C1.75935 9.26327 1.83111 9.59299 2.1104 9.79082C2.36059 9.96731 2.67479 9.96731 3.03748 9.84318L3.0976 9.81797L5.1942 8.69694Z" fill="#FBB723" />
              </g>
              <defs>
                <clipPath id="clip0_3693_14062">
                  <rect width="9.93023" height="9.93023" fill="white" transform="matrix(-1 0 0 1 10.105 0)" />
                </clipPath>
              </defs>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 11 10" fill="none">
              <g clip-path="url(#clip0_3693_14062)">
                <path d="M5.1942 8.69694L7.28498 9.81603L7.33347 9.83737C7.6884 9.97119 8.02393 9.94404 8.278 9.71712C8.51656 9.50377 8.60384 9.19733 8.55341 8.84628L8.2043 6.43355L9.78305 4.77333C10.0585 4.49599 10.1651 4.15463 10.072 3.79777C9.96925 3.40211 9.63565 3.17325 9.15272 3.09761L7.05418 2.78147L5.95642 0.504498C5.78575 0.182542 5.5181 -0.00752932 5.1845 0.000228676C4.85673 0.00992617 4.59683 0.209695 4.39319 0.568502L3.33034 2.79505L0.97773 3.12282C0.603407 3.18682 0.327998 3.38271 0.217447 3.70661C0.106895 4.03051 0.211629 4.35828 0.494795 4.69188L2.20349 6.43548L1.81947 8.84628C1.75935 9.26327 1.83111 9.59299 2.1104 9.79082C2.36059 9.96731 2.67479 9.96731 3.03748 9.84318L3.0976 9.81797L5.1942 8.69694Z" fill="#FBB723" />
              </g>
              <defs>
                <clipPath id="clip0_3693_14062">
                  <rect width="9.93023" height="9.93023" fill="white" transform="matrix(-1 0 0 1 10.105 0)" />
                </clipPath>
              </defs>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 11 10" fill="none">
              <g clip-path="url(#clip0_3693_14062)">
                <path d="M5.1942 8.69694L7.28498 9.81603L7.33347 9.83737C7.6884 9.97119 8.02393 9.94404 8.278 9.71712C8.51656 9.50377 8.60384 9.19733 8.55341 8.84628L8.2043 6.43355L9.78305 4.77333C10.0585 4.49599 10.1651 4.15463 10.072 3.79777C9.96925 3.40211 9.63565 3.17325 9.15272 3.09761L7.05418 2.78147L5.95642 0.504498C5.78575 0.182542 5.5181 -0.00752932 5.1845 0.000228676C4.85673 0.00992617 4.59683 0.209695 4.39319 0.568502L3.33034 2.79505L0.97773 3.12282C0.603407 3.18682 0.327998 3.38271 0.217447 3.70661C0.106895 4.03051 0.211629 4.35828 0.494795 4.69188L2.20349 6.43548L1.81947 8.84628C1.75935 9.26327 1.83111 9.59299 2.1104 9.79082C2.36059 9.96731 2.67479 9.96731 3.03748 9.84318L3.0976 9.81797L5.1942 8.69694Z" fill="#FBB723" />
              </g>
              <defs>
                <clipPath id="clip0_3693_14062">
                  <rect width="9.93023" height="9.93023" fill="white" transform="matrix(-1 0 0 1 10.105 0)" />
                </clipPath>
              </defs>
            </svg>
          </div>
                </div>
              
          <div className="date_of_review ">
          03/09/2024
          </div>
                </div>
                <div className="reviewtext">
                "Logré tomar todas mis clases al 100% en español con UnitedELDT. En la escuela donde estaba, solo ofrecían las clases obligatorias en inglés. Lo recomiendo para todos los hispanos."                </div>
                <div className="imageof_review">
                <img className="reviewforimage"  style={{borderRadius:"12px",height:"300px"}} src={Review9}  alt="reviewimage"/>
                </div>
                </div> 
       </div>
       <div className="slidercoverdiv">
<div className="googlereviewdiv">
    <div>
           <img src={googlereview} style={{width:"91px"}} alt="google review"/>
 
    </div>
    <div>
    <div className="startsreview">
              <svg xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 11 10" fill="none">
                <g clip-path="url(#clip0_3693_14062)">
                  <path d="M5.1942 8.69694L7.28498 9.81603L7.33347 9.83737C7.6884 9.97119 8.02393 9.94404 8.278 9.71712C8.51656 9.50377 8.60384 9.19733 8.55341 8.84628L8.2043 6.43355L9.78305 4.77333C10.0585 4.49599 10.1651 4.15463 10.072 3.79777C9.96925 3.40211 9.63565 3.17325 9.15272 3.09761L7.05418 2.78147L5.95642 0.504498C5.78575 0.182542 5.5181 -0.00752932 5.1845 0.000228676C4.85673 0.00992617 4.59683 0.209695 4.39319 0.568502L3.33034 2.79505L0.97773 3.12282C0.603407 3.18682 0.327998 3.38271 0.217447 3.70661C0.106895 4.03051 0.211629 4.35828 0.494795 4.69188L2.20349 6.43548L1.81947 8.84628C1.75935 9.26327 1.83111 9.59299 2.1104 9.79082C2.36059 9.96731 2.67479 9.96731 3.03748 9.84318L3.0976 9.81797L5.1942 8.69694Z" fill="#FBB723" />
                </g>
                <defs>
                  <clipPath id="clip0_3693_14062">
                    <rect width="9.93023" height="9.93023" fill="white" transform="matrix(-1 0 0 1 10.105 0)" />
                  </clipPath>
                </defs>
              </svg>
              <svg xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 11 10" fill="none">
                <g clip-path="url(#clip0_3693_14062)">
                  <path d="M5.1942 8.69694L7.28498 9.81603L7.33347 9.83737C7.6884 9.97119 8.02393 9.94404 8.278 9.71712C8.51656 9.50377 8.60384 9.19733 8.55341 8.84628L8.2043 6.43355L9.78305 4.77333C10.0585 4.49599 10.1651 4.15463 10.072 3.79777C9.96925 3.40211 9.63565 3.17325 9.15272 3.09761L7.05418 2.78147L5.95642 0.504498C5.78575 0.182542 5.5181 -0.00752932 5.1845 0.000228676C4.85673 0.00992617 4.59683 0.209695 4.39319 0.568502L3.33034 2.79505L0.97773 3.12282C0.603407 3.18682 0.327998 3.38271 0.217447 3.70661C0.106895 4.03051 0.211629 4.35828 0.494795 4.69188L2.20349 6.43548L1.81947 8.84628C1.75935 9.26327 1.83111 9.59299 2.1104 9.79082C2.36059 9.96731 2.67479 9.96731 3.03748 9.84318L3.0976 9.81797L5.1942 8.69694Z" fill="#FBB723" />
                </g>
                <defs>
                  <clipPath id="clip0_3693_14062">
                    <rect width="9.93023" height="9.93023" fill="white" transform="matrix(-1 0 0 1 10.105 0)" />
                  </clipPath>
                </defs>
              </svg>
              <svg xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 11 10" fill="none">
                <g clip-path="url(#clip0_3693_14062)">
                  <path d="M5.1942 8.69694L7.28498 9.81603L7.33347 9.83737C7.6884 9.97119 8.02393 9.94404 8.278 9.71712C8.51656 9.50377 8.60384 9.19733 8.55341 8.84628L8.2043 6.43355L9.78305 4.77333C10.0585 4.49599 10.1651 4.15463 10.072 3.79777C9.96925 3.40211 9.63565 3.17325 9.15272 3.09761L7.05418 2.78147L5.95642 0.504498C5.78575 0.182542 5.5181 -0.00752932 5.1845 0.000228676C4.85673 0.00992617 4.59683 0.209695 4.39319 0.568502L3.33034 2.79505L0.97773 3.12282C0.603407 3.18682 0.327998 3.38271 0.217447 3.70661C0.106895 4.03051 0.211629 4.35828 0.494795 4.69188L2.20349 6.43548L1.81947 8.84628C1.75935 9.26327 1.83111 9.59299 2.1104 9.79082C2.36059 9.96731 2.67479 9.96731 3.03748 9.84318L3.0976 9.81797L5.1942 8.69694Z" fill="#FBB723" />
                </g>
                <defs>
                  <clipPath id="clip0_3693_14062">
                    <rect width="9.93023" height="9.93023" fill="white" transform="matrix(-1 0 0 1 10.105 0)" />
                  </clipPath>
                </defs>
              </svg>
              <svg xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 11 10" fill="none">
                <g clip-path="url(#clip0_3693_14062)">
                  <path d="M5.1942 8.69694L7.28498 9.81603L7.33347 9.83737C7.6884 9.97119 8.02393 9.94404 8.278 9.71712C8.51656 9.50377 8.60384 9.19733 8.55341 8.84628L8.2043 6.43355L9.78305 4.77333C10.0585 4.49599 10.1651 4.15463 10.072 3.79777C9.96925 3.40211 9.63565 3.17325 9.15272 3.09761L7.05418 2.78147L5.95642 0.504498C5.78575 0.182542 5.5181 -0.00752932 5.1845 0.000228676C4.85673 0.00992617 4.59683 0.209695 4.39319 0.568502L3.33034 2.79505L0.97773 3.12282C0.603407 3.18682 0.327998 3.38271 0.217447 3.70661C0.106895 4.03051 0.211629 4.35828 0.494795 4.69188L2.20349 6.43548L1.81947 8.84628C1.75935 9.26327 1.83111 9.59299 2.1104 9.79082C2.36059 9.96731 2.67479 9.96731 3.03748 9.84318L3.0976 9.81797L5.1942 8.69694Z" fill="#FBB723" />
                </g>
                <defs>
                  <clipPath id="clip0_3693_14062">
                    <rect width="9.93023" height="9.93023" fill="white" transform="matrix(-1 0 0 1 10.105 0)" />
                  </clipPath>
                </defs>
              </svg>
              <svg xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 11 10" fill="none">
                <g clip-path="url(#clip0_3693_14062)">
                  <path d="M5.1942 8.69694L7.28498 9.81603L7.33347 9.83737C7.6884 9.97119 8.02393 9.94404 8.278 9.71712C8.51656 9.50377 8.60384 9.19733 8.55341 8.84628L8.2043 6.43355L9.78305 4.77333C10.0585 4.49599 10.1651 4.15463 10.072 3.79777C9.96925 3.40211 9.63565 3.17325 9.15272 3.09761L7.05418 2.78147L5.95642 0.504498C5.78575 0.182542 5.5181 -0.00752932 5.1845 0.000228676C4.85673 0.00992617 4.59683 0.209695 4.39319 0.568502L3.33034 2.79505L0.97773 3.12282C0.603407 3.18682 0.327998 3.38271 0.217447 3.70661C0.106895 4.03051 0.211629 4.35828 0.494795 4.69188L2.20349 6.43548L1.81947 8.84628C1.75935 9.26327 1.83111 9.59299 2.1104 9.79082C2.36059 9.96731 2.67479 9.96731 3.03748 9.84318L3.0976 9.81797L5.1942 8.69694Z" fill="#FBB723" />
                </g>
                <defs>
                  <clipPath id="clip0_3693_14062">
                    <rect width="9.93023" height="9.93023" fill="white" transform="matrix(-1 0 0 1 10.105 0)" />
                  </clipPath>
                </defs>
              </svg>
            </div>
            <div className="reviewcount">
              7,458 reviews
            </div>
    </div>
</div>
<div className="slidercontent">


<div className="name_section">
                     <div className="nameofperson slidername">
                     Austin Martinez 
                     <div className="startsreview justify-content-start mt-2">
            <svg xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 11 10" fill="none">
              <g clip-path="url(#clip0_3693_14062)">
                <path d="M5.1942 8.69694L7.28498 9.81603L7.33347 9.83737C7.6884 9.97119 8.02393 9.94404 8.278 9.71712C8.51656 9.50377 8.60384 9.19733 8.55341 8.84628L8.2043 6.43355L9.78305 4.77333C10.0585 4.49599 10.1651 4.15463 10.072 3.79777C9.96925 3.40211 9.63565 3.17325 9.15272 3.09761L7.05418 2.78147L5.95642 0.504498C5.78575 0.182542 5.5181 -0.00752932 5.1845 0.000228676C4.85673 0.00992617 4.59683 0.209695 4.39319 0.568502L3.33034 2.79505L0.97773 3.12282C0.603407 3.18682 0.327998 3.38271 0.217447 3.70661C0.106895 4.03051 0.211629 4.35828 0.494795 4.69188L2.20349 6.43548L1.81947 8.84628C1.75935 9.26327 1.83111 9.59299 2.1104 9.79082C2.36059 9.96731 2.67479 9.96731 3.03748 9.84318L3.0976 9.81797L5.1942 8.69694Z" fill="#FBB723" />
              </g>
              <defs>
                <clipPath id="clip0_3693_14062">
                  <rect width="9.93023" height="9.93023" fill="white" transform="matrix(-1 0 0 1 10.105 0)" />
                </clipPath>
              </defs>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 11 10" fill="none">
              <g clip-path="url(#clip0_3693_14062)">
                <path d="M5.1942 8.69694L7.28498 9.81603L7.33347 9.83737C7.6884 9.97119 8.02393 9.94404 8.278 9.71712C8.51656 9.50377 8.60384 9.19733 8.55341 8.84628L8.2043 6.43355L9.78305 4.77333C10.0585 4.49599 10.1651 4.15463 10.072 3.79777C9.96925 3.40211 9.63565 3.17325 9.15272 3.09761L7.05418 2.78147L5.95642 0.504498C5.78575 0.182542 5.5181 -0.00752932 5.1845 0.000228676C4.85673 0.00992617 4.59683 0.209695 4.39319 0.568502L3.33034 2.79505L0.97773 3.12282C0.603407 3.18682 0.327998 3.38271 0.217447 3.70661C0.106895 4.03051 0.211629 4.35828 0.494795 4.69188L2.20349 6.43548L1.81947 8.84628C1.75935 9.26327 1.83111 9.59299 2.1104 9.79082C2.36059 9.96731 2.67479 9.96731 3.03748 9.84318L3.0976 9.81797L5.1942 8.69694Z" fill="#FBB723" />
              </g>
              <defs>
                <clipPath id="clip0_3693_14062">
                  <rect width="9.93023" height="9.93023" fill="white" transform="matrix(-1 0 0 1 10.105 0)" />
                </clipPath>
              </defs>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 11 10" fill="none">
              <g clip-path="url(#clip0_3693_14062)">
                <path d="M5.1942 8.69694L7.28498 9.81603L7.33347 9.83737C7.6884 9.97119 8.02393 9.94404 8.278 9.71712C8.51656 9.50377 8.60384 9.19733 8.55341 8.84628L8.2043 6.43355L9.78305 4.77333C10.0585 4.49599 10.1651 4.15463 10.072 3.79777C9.96925 3.40211 9.63565 3.17325 9.15272 3.09761L7.05418 2.78147L5.95642 0.504498C5.78575 0.182542 5.5181 -0.00752932 5.1845 0.000228676C4.85673 0.00992617 4.59683 0.209695 4.39319 0.568502L3.33034 2.79505L0.97773 3.12282C0.603407 3.18682 0.327998 3.38271 0.217447 3.70661C0.106895 4.03051 0.211629 4.35828 0.494795 4.69188L2.20349 6.43548L1.81947 8.84628C1.75935 9.26327 1.83111 9.59299 2.1104 9.79082C2.36059 9.96731 2.67479 9.96731 3.03748 9.84318L3.0976 9.81797L5.1942 8.69694Z" fill="#FBB723" />
              </g>
              <defs>
                <clipPath id="clip0_3693_14062">
                  <rect width="9.93023" height="9.93023" fill="white" transform="matrix(-1 0 0 1 10.105 0)" />
                </clipPath>
              </defs>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 11 10" fill="none">
              <g clip-path="url(#clip0_3693_14062)">
                <path d="M5.1942 8.69694L7.28498 9.81603L7.33347 9.83737C7.6884 9.97119 8.02393 9.94404 8.278 9.71712C8.51656 9.50377 8.60384 9.19733 8.55341 8.84628L8.2043 6.43355L9.78305 4.77333C10.0585 4.49599 10.1651 4.15463 10.072 3.79777C9.96925 3.40211 9.63565 3.17325 9.15272 3.09761L7.05418 2.78147L5.95642 0.504498C5.78575 0.182542 5.5181 -0.00752932 5.1845 0.000228676C4.85673 0.00992617 4.59683 0.209695 4.39319 0.568502L3.33034 2.79505L0.97773 3.12282C0.603407 3.18682 0.327998 3.38271 0.217447 3.70661C0.106895 4.03051 0.211629 4.35828 0.494795 4.69188L2.20349 6.43548L1.81947 8.84628C1.75935 9.26327 1.83111 9.59299 2.1104 9.79082C2.36059 9.96731 2.67479 9.96731 3.03748 9.84318L3.0976 9.81797L5.1942 8.69694Z" fill="#FBB723" />
              </g>
              <defs>
                <clipPath id="clip0_3693_14062">
                  <rect width="9.93023" height="9.93023" fill="white" transform="matrix(-1 0 0 1 10.105 0)" />
                </clipPath>
              </defs>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 11 10" fill="none">
              <g clip-path="url(#clip0_3693_14062)">
                <path d="M5.1942 8.69694L7.28498 9.81603L7.33347 9.83737C7.6884 9.97119 8.02393 9.94404 8.278 9.71712C8.51656 9.50377 8.60384 9.19733 8.55341 8.84628L8.2043 6.43355L9.78305 4.77333C10.0585 4.49599 10.1651 4.15463 10.072 3.79777C9.96925 3.40211 9.63565 3.17325 9.15272 3.09761L7.05418 2.78147L5.95642 0.504498C5.78575 0.182542 5.5181 -0.00752932 5.1845 0.000228676C4.85673 0.00992617 4.59683 0.209695 4.39319 0.568502L3.33034 2.79505L0.97773 3.12282C0.603407 3.18682 0.327998 3.38271 0.217447 3.70661C0.106895 4.03051 0.211629 4.35828 0.494795 4.69188L2.20349 6.43548L1.81947 8.84628C1.75935 9.26327 1.83111 9.59299 2.1104 9.79082C2.36059 9.96731 2.67479 9.96731 3.03748 9.84318L3.0976 9.81797L5.1942 8.69694Z" fill="#FBB723" />
              </g>
              <defs>
                <clipPath id="clip0_3693_14062">
                  <rect width="9.93023" height="9.93023" fill="white" transform="matrix(-1 0 0 1 10.105 0)" />
                </clipPath>
              </defs>
            </svg>
          </div>
                </div>
              
          <div className="date_of_review ">
          03/05/2024
          </div>
                </div>
                <div className="reviewtext">
                “A friend recommended United ELDT, and it was truly a smart decision. Other companies offer courses at almost $200, but I paid $50 for this 100% Spanish course. ”            
                </div>
                <div className="imageof_review">
                <img className="reviewforimage" src={Review7}  alt="reviewimage"/>
                </div>
                </div>   
                <div className="slidercontent">


<div className="name_section">
                     <div className="nameofperson slidername">
                     Edwin Hernandez
                     <div className="startsreview justify-content-start mt-2">
            <svg xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 11 10" fill="none">
              <g clip-path="url(#clip0_3693_14062)">
                <path d="M5.1942 8.69694L7.28498 9.81603L7.33347 9.83737C7.6884 9.97119 8.02393 9.94404 8.278 9.71712C8.51656 9.50377 8.60384 9.19733 8.55341 8.84628L8.2043 6.43355L9.78305 4.77333C10.0585 4.49599 10.1651 4.15463 10.072 3.79777C9.96925 3.40211 9.63565 3.17325 9.15272 3.09761L7.05418 2.78147L5.95642 0.504498C5.78575 0.182542 5.5181 -0.00752932 5.1845 0.000228676C4.85673 0.00992617 4.59683 0.209695 4.39319 0.568502L3.33034 2.79505L0.97773 3.12282C0.603407 3.18682 0.327998 3.38271 0.217447 3.70661C0.106895 4.03051 0.211629 4.35828 0.494795 4.69188L2.20349 6.43548L1.81947 8.84628C1.75935 9.26327 1.83111 9.59299 2.1104 9.79082C2.36059 9.96731 2.67479 9.96731 3.03748 9.84318L3.0976 9.81797L5.1942 8.69694Z" fill="#FBB723" />
              </g>
              <defs>
                <clipPath id="clip0_3693_14062">
                  <rect width="9.93023" height="9.93023" fill="white" transform="matrix(-1 0 0 1 10.105 0)" />
                </clipPath>
              </defs>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 11 10" fill="none">
              <g clip-path="url(#clip0_3693_14062)">
                <path d="M5.1942 8.69694L7.28498 9.81603L7.33347 9.83737C7.6884 9.97119 8.02393 9.94404 8.278 9.71712C8.51656 9.50377 8.60384 9.19733 8.55341 8.84628L8.2043 6.43355L9.78305 4.77333C10.0585 4.49599 10.1651 4.15463 10.072 3.79777C9.96925 3.40211 9.63565 3.17325 9.15272 3.09761L7.05418 2.78147L5.95642 0.504498C5.78575 0.182542 5.5181 -0.00752932 5.1845 0.000228676C4.85673 0.00992617 4.59683 0.209695 4.39319 0.568502L3.33034 2.79505L0.97773 3.12282C0.603407 3.18682 0.327998 3.38271 0.217447 3.70661C0.106895 4.03051 0.211629 4.35828 0.494795 4.69188L2.20349 6.43548L1.81947 8.84628C1.75935 9.26327 1.83111 9.59299 2.1104 9.79082C2.36059 9.96731 2.67479 9.96731 3.03748 9.84318L3.0976 9.81797L5.1942 8.69694Z" fill="#FBB723" />
              </g>
              <defs>
                <clipPath id="clip0_3693_14062">
                  <rect width="9.93023" height="9.93023" fill="white" transform="matrix(-1 0 0 1 10.105 0)" />
                </clipPath>
              </defs>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 11 10" fill="none">
              <g clip-path="url(#clip0_3693_14062)">
                <path d="M5.1942 8.69694L7.28498 9.81603L7.33347 9.83737C7.6884 9.97119 8.02393 9.94404 8.278 9.71712C8.51656 9.50377 8.60384 9.19733 8.55341 8.84628L8.2043 6.43355L9.78305 4.77333C10.0585 4.49599 10.1651 4.15463 10.072 3.79777C9.96925 3.40211 9.63565 3.17325 9.15272 3.09761L7.05418 2.78147L5.95642 0.504498C5.78575 0.182542 5.5181 -0.00752932 5.1845 0.000228676C4.85673 0.00992617 4.59683 0.209695 4.39319 0.568502L3.33034 2.79505L0.97773 3.12282C0.603407 3.18682 0.327998 3.38271 0.217447 3.70661C0.106895 4.03051 0.211629 4.35828 0.494795 4.69188L2.20349 6.43548L1.81947 8.84628C1.75935 9.26327 1.83111 9.59299 2.1104 9.79082C2.36059 9.96731 2.67479 9.96731 3.03748 9.84318L3.0976 9.81797L5.1942 8.69694Z" fill="#FBB723" />
              </g>
              <defs>
                <clipPath id="clip0_3693_14062">
                  <rect width="9.93023" height="9.93023" fill="white" transform="matrix(-1 0 0 1 10.105 0)" />
                </clipPath>
              </defs>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 11 10" fill="none">
              <g clip-path="url(#clip0_3693_14062)">
                <path d="M5.1942 8.69694L7.28498 9.81603L7.33347 9.83737C7.6884 9.97119 8.02393 9.94404 8.278 9.71712C8.51656 9.50377 8.60384 9.19733 8.55341 8.84628L8.2043 6.43355L9.78305 4.77333C10.0585 4.49599 10.1651 4.15463 10.072 3.79777C9.96925 3.40211 9.63565 3.17325 9.15272 3.09761L7.05418 2.78147L5.95642 0.504498C5.78575 0.182542 5.5181 -0.00752932 5.1845 0.000228676C4.85673 0.00992617 4.59683 0.209695 4.39319 0.568502L3.33034 2.79505L0.97773 3.12282C0.603407 3.18682 0.327998 3.38271 0.217447 3.70661C0.106895 4.03051 0.211629 4.35828 0.494795 4.69188L2.20349 6.43548L1.81947 8.84628C1.75935 9.26327 1.83111 9.59299 2.1104 9.79082C2.36059 9.96731 2.67479 9.96731 3.03748 9.84318L3.0976 9.81797L5.1942 8.69694Z" fill="#FBB723" />
              </g>
              <defs>
                <clipPath id="clip0_3693_14062">
                  <rect width="9.93023" height="9.93023" fill="white" transform="matrix(-1 0 0 1 10.105 0)" />
                </clipPath>
              </defs>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 11 10" fill="none">
              <g clip-path="url(#clip0_3693_14062)">
                <path d="M5.1942 8.69694L7.28498 9.81603L7.33347 9.83737C7.6884 9.97119 8.02393 9.94404 8.278 9.71712C8.51656 9.50377 8.60384 9.19733 8.55341 8.84628L8.2043 6.43355L9.78305 4.77333C10.0585 4.49599 10.1651 4.15463 10.072 3.79777C9.96925 3.40211 9.63565 3.17325 9.15272 3.09761L7.05418 2.78147L5.95642 0.504498C5.78575 0.182542 5.5181 -0.00752932 5.1845 0.000228676C4.85673 0.00992617 4.59683 0.209695 4.39319 0.568502L3.33034 2.79505L0.97773 3.12282C0.603407 3.18682 0.327998 3.38271 0.217447 3.70661C0.106895 4.03051 0.211629 4.35828 0.494795 4.69188L2.20349 6.43548L1.81947 8.84628C1.75935 9.26327 1.83111 9.59299 2.1104 9.79082C2.36059 9.96731 2.67479 9.96731 3.03748 9.84318L3.0976 9.81797L5.1942 8.69694Z" fill="#FBB723" />
              </g>
              <defs>
                <clipPath id="clip0_3693_14062">
                  <rect width="9.93023" height="9.93023" fill="white" transform="matrix(-1 0 0 1 10.105 0)" />
                </clipPath>
              </defs>
            </svg>
          </div>
                </div>
              
          <div className="date_of_review ">
          03/05/2024
          </div>
                </div>
                <div className="reviewtext">
                "Pagar $50 dólares por este curso en español fue la mejor decisión que tomé. Encontré cursos que cobraban $280 y llevaban toda una vida para completar. Lo recomiendo con los ojos cerrados."
              </div>
                <div className="imageof_review">
                <img className="reviewforimage" src={Review8}  alt="reviewimage"/>
                </div>
                </div>    
                <div className="slidercontent">


<div className="name_section">
                     <div className="nameofperson slidername">
                    Fernando Rodriguez  
                                      <div className="startsreview justify-content-start mt-2">
            <svg xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 11 10" fill="none">
              <g clip-path="url(#clip0_3693_14062)">
                <path d="M5.1942 8.69694L7.28498 9.81603L7.33347 9.83737C7.6884 9.97119 8.02393 9.94404 8.278 9.71712C8.51656 9.50377 8.60384 9.19733 8.55341 8.84628L8.2043 6.43355L9.78305 4.77333C10.0585 4.49599 10.1651 4.15463 10.072 3.79777C9.96925 3.40211 9.63565 3.17325 9.15272 3.09761L7.05418 2.78147L5.95642 0.504498C5.78575 0.182542 5.5181 -0.00752932 5.1845 0.000228676C4.85673 0.00992617 4.59683 0.209695 4.39319 0.568502L3.33034 2.79505L0.97773 3.12282C0.603407 3.18682 0.327998 3.38271 0.217447 3.70661C0.106895 4.03051 0.211629 4.35828 0.494795 4.69188L2.20349 6.43548L1.81947 8.84628C1.75935 9.26327 1.83111 9.59299 2.1104 9.79082C2.36059 9.96731 2.67479 9.96731 3.03748 9.84318L3.0976 9.81797L5.1942 8.69694Z" fill="#FBB723" />
              </g>
              <defs>
                <clipPath id="clip0_3693_14062">
                  <rect width="9.93023" height="9.93023" fill="white" transform="matrix(-1 0 0 1 10.105 0)" />
                </clipPath>
              </defs>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 11 10" fill="none">
              <g clip-path="url(#clip0_3693_14062)">
                <path d="M5.1942 8.69694L7.28498 9.81603L7.33347 9.83737C7.6884 9.97119 8.02393 9.94404 8.278 9.71712C8.51656 9.50377 8.60384 9.19733 8.55341 8.84628L8.2043 6.43355L9.78305 4.77333C10.0585 4.49599 10.1651 4.15463 10.072 3.79777C9.96925 3.40211 9.63565 3.17325 9.15272 3.09761L7.05418 2.78147L5.95642 0.504498C5.78575 0.182542 5.5181 -0.00752932 5.1845 0.000228676C4.85673 0.00992617 4.59683 0.209695 4.39319 0.568502L3.33034 2.79505L0.97773 3.12282C0.603407 3.18682 0.327998 3.38271 0.217447 3.70661C0.106895 4.03051 0.211629 4.35828 0.494795 4.69188L2.20349 6.43548L1.81947 8.84628C1.75935 9.26327 1.83111 9.59299 2.1104 9.79082C2.36059 9.96731 2.67479 9.96731 3.03748 9.84318L3.0976 9.81797L5.1942 8.69694Z" fill="#FBB723" />
              </g>
              <defs>
                <clipPath id="clip0_3693_14062">
                  <rect width="9.93023" height="9.93023" fill="white" transform="matrix(-1 0 0 1 10.105 0)" />
                </clipPath>
              </defs>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 11 10" fill="none">
              <g clip-path="url(#clip0_3693_14062)">
                <path d="M5.1942 8.69694L7.28498 9.81603L7.33347 9.83737C7.6884 9.97119 8.02393 9.94404 8.278 9.71712C8.51656 9.50377 8.60384 9.19733 8.55341 8.84628L8.2043 6.43355L9.78305 4.77333C10.0585 4.49599 10.1651 4.15463 10.072 3.79777C9.96925 3.40211 9.63565 3.17325 9.15272 3.09761L7.05418 2.78147L5.95642 0.504498C5.78575 0.182542 5.5181 -0.00752932 5.1845 0.000228676C4.85673 0.00992617 4.59683 0.209695 4.39319 0.568502L3.33034 2.79505L0.97773 3.12282C0.603407 3.18682 0.327998 3.38271 0.217447 3.70661C0.106895 4.03051 0.211629 4.35828 0.494795 4.69188L2.20349 6.43548L1.81947 8.84628C1.75935 9.26327 1.83111 9.59299 2.1104 9.79082C2.36059 9.96731 2.67479 9.96731 3.03748 9.84318L3.0976 9.81797L5.1942 8.69694Z" fill="#FBB723" />
              </g>
              <defs>
                <clipPath id="clip0_3693_14062">
                  <rect width="9.93023" height="9.93023" fill="white" transform="matrix(-1 0 0 1 10.105 0)" />
                </clipPath>
              </defs>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 11 10" fill="none">
              <g clip-path="url(#clip0_3693_14062)">
                <path d="M5.1942 8.69694L7.28498 9.81603L7.33347 9.83737C7.6884 9.97119 8.02393 9.94404 8.278 9.71712C8.51656 9.50377 8.60384 9.19733 8.55341 8.84628L8.2043 6.43355L9.78305 4.77333C10.0585 4.49599 10.1651 4.15463 10.072 3.79777C9.96925 3.40211 9.63565 3.17325 9.15272 3.09761L7.05418 2.78147L5.95642 0.504498C5.78575 0.182542 5.5181 -0.00752932 5.1845 0.000228676C4.85673 0.00992617 4.59683 0.209695 4.39319 0.568502L3.33034 2.79505L0.97773 3.12282C0.603407 3.18682 0.327998 3.38271 0.217447 3.70661C0.106895 4.03051 0.211629 4.35828 0.494795 4.69188L2.20349 6.43548L1.81947 8.84628C1.75935 9.26327 1.83111 9.59299 2.1104 9.79082C2.36059 9.96731 2.67479 9.96731 3.03748 9.84318L3.0976 9.81797L5.1942 8.69694Z" fill="#FBB723" />
              </g>
              <defs>
                <clipPath id="clip0_3693_14062">
                  <rect width="9.93023" height="9.93023" fill="white" transform="matrix(-1 0 0 1 10.105 0)" />
                </clipPath>
              </defs>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" width="11" height="10" viewBox="0 0 11 10" fill="none">
              <g clip-path="url(#clip0_3693_14062)">
                <path d="M5.1942 8.69694L7.28498 9.81603L7.33347 9.83737C7.6884 9.97119 8.02393 9.94404 8.278 9.71712C8.51656 9.50377 8.60384 9.19733 8.55341 8.84628L8.2043 6.43355L9.78305 4.77333C10.0585 4.49599 10.1651 4.15463 10.072 3.79777C9.96925 3.40211 9.63565 3.17325 9.15272 3.09761L7.05418 2.78147L5.95642 0.504498C5.78575 0.182542 5.5181 -0.00752932 5.1845 0.000228676C4.85673 0.00992617 4.59683 0.209695 4.39319 0.568502L3.33034 2.79505L0.97773 3.12282C0.603407 3.18682 0.327998 3.38271 0.217447 3.70661C0.106895 4.03051 0.211629 4.35828 0.494795 4.69188L2.20349 6.43548L1.81947 8.84628C1.75935 9.26327 1.83111 9.59299 2.1104 9.79082C2.36059 9.96731 2.67479 9.96731 3.03748 9.84318L3.0976 9.81797L5.1942 8.69694Z" fill="#FBB723" />
              </g>
              <defs>
                <clipPath id="clip0_3693_14062">
                  <rect width="9.93023" height="9.93023" fill="white" transform="matrix(-1 0 0 1 10.105 0)" />
                </clipPath>
              </defs>
            </svg>
          </div>
                </div>
              
          <div className="date_of_review ">
          03/08/2024
          </div>
                </div>
                <div className="reviewtext">
                “Arriving at school with ELDT in hand is the best way to save time that would be lost inside the classroom. I'm already working at Amazon!”
                </div>
                <div className="imageof_review">
                <img className="reviewforimage" style={{borderRadius:"12px"}} src={Review6}  alt="reviewimage"/>
                </div>
                </div> 
       </div>
       </Carousel> 
     
        </div>
    )
}
export default SliderPage